<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessage" title="Start background job">
      <template v-slot:modal-body>
        <label v-if="!loading" style="font-weight: normal">
          Start background job for calculating report <strong>{{ reportCode }}</strong>?
        </label>
       <Loader v-if="loading"/>

      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="confirm"
        >
          Confirm
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<style lang="stylus" scoped>
.modal-dialog {
  max-width: 1200px;
}
</style>


<script>
import settings from '@/settings.js';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import Loader from '@/components/Loader';

export default {
  props: ['reportCode', 'month', 'hospitalId'],
  data() {
    return {
      loading: false,

    };
  },
  methods: {
    show() {
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
      this.loading = true;
    },

    async confirm() {
      this.close();
      $.notify('The report will be recalculated.', {
        position: 'top center',
        className: 'info',
      });
      this.$emit('startProcessing');
      await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/reports/start-job`,
        {
          reportCode: this.reportCode,
          month: this.month,
          hospitalId: this.hospitalId,
        },
      );
      $.notify('The report was successfully recalculated.', {
        position: 'top center',
        className: 'success',
      });
      this.$emit('endProcessing');
    },
  },

  components: {
    SlottedMessageInDrawer,
    Loader,
  },
};
</script>
