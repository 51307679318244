<template>
  <div
    ref="td"
    class="td h-text-center h-h4 h-primary_shade_1"
    :class="{
      dropdown: mealPlan && mealPlan.title,
      'align-items-start': showMealPlanDetails,
    }"
    @click="toggleDropdown"
  >
    <div
      ref="dropdown"
      v-if="mealPlan && mealPlan.title"
      data-toggle="dropdown"
      :class="{ 'text-left px-2': showMealPlanDetails }"
      aria-haspopup="true"
      aria-expanded="false"
      :id="`MealPlanCard:${id}`"
    >
      <img
        v-if="!showMealPlanDetails"
        class="MealImage"
        :src="mealPlan.image"
        alt=""
      />
      <span v-else>
        <strong>{{ mealPlan.title }}</strong>
        <hr class="h-hr" />

        <strong><i>Description:</i></strong>
        {{ recepieData.recipie.description || "-" }}
        <hr class="h-hr" />

        <strong><i>Ingredients:</i></strong
        ><br />
        <ul v-if="recepieData.ingredients.length !== 0" class="ingredient-list">
          <li
            v-for="ingredient of recepieData.ingredients"
            :key="ingredient.ingredient_id"
          >
            {{ ingredient.qty_g }} {{ ingredient.unit }} {{ ingredient.base }}
          </li>
        </ul>
        <span v-else>-</span>
        <hr class="h-hr" />

        <strong><i>Calories:</i></strong>
        {{ recepieData.recipie.kcal || "-" }} kcal<br />
      </span>
    </div>

    <div
      v-if="mealPlan && mealPlan.title"
      class="dropdown-menu dropdown-menu-right h-margin-0"
      :aria-labelledby="`MealPlanCard:${id}`"
    >
      <div class="h-text-center h-padding-bottom-s">
        <strong> {{ mealPlan.date }}: {{ mealPlan.type }} </strong>
      </div>
      <div
        class="
          h-text-center h-padding-bottom-s h-padding-left-s h-padding-right-s
        "
      >
        {{ mealPlan.title }}
      </div>
      <div class="dropdown-divider"></div>

      <!--
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="fas fa-hamburger mr-2"></i> Edit ingredients
      </a>

      <a class="dropdown-item" href="javascript:void(0)">
        <i class="fas fa-random mr-2"></i> Change this meal
      </a>
       -->
      <a class="dropdown-item" href="javascript:void(0)" @click="showRecipe">
        <i class="far fa-eye h-margin-right-xs"></i> View recipe
      </a>

      <a class="dropdown-item" href="javascript:void(0)">
        <i class="fas fa-times h-margin-right-s"></i> Close
      </a>
    </div>

    <span v-else class="text-muted">&lt;empty&gt;</span>

    <!-- RecipeDialog -->
    <RecipeDialog
      v-if="showRecipeDialog"
      ref="recipeDialog"
      :recipeId="mealPlan.recipe_id"
      @closed="showRecipeDialog = false"
      @recipeSaved="recipeSaved"
    />
  </div>
</template>


<style lang="stylus" scoped>
.td {
  cursor: pointer;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-items-start {
  align-items: start;
}

.td > div:first-child {
  padding: 0.156vw;
  white-space: initial;
}

.td:hover {
  background-color: #f5f5f5;
}

.ingredient-list {
  padding-left: 1.25vw;
}
</style>

<script>
import RecipeDialog from '@/views/general/recipe/view_recipe/RecipeDialog';

let lastId = 0;

export default {
  data() {
    lastId += 1;
    return {
      id: lastId,
      showRecipeDialog: false,
      timeout: null,
    };
  },

  methods: {
    toggleDropdown(e) {
      if (e.target === this.$refs.td && this.$refs.dropdown) {
        if (!$(this.$refs.td).hasClass('show')) {
          this.timeout = setTimeout(() => {
            $(this.$refs.dropdown).trigger('click');
          }, 1);
        }
      }
    },

    showRecipe() {
      this.showRecipeDialog = true;
      this.$nextTick(() => {
        this.$refs.recipeDialog.show();
      });
    },

    recipeSaved(recipe, ingredients) {
      this.$emit('recipeSaved', recipe, ingredients);
    },
  },

  mounted() {
    // fixes scrallbar bug
    $(this.$refs.td).on('shown.bs.dropdown', () => {
      const scrollTop = $(window).scrollTop();
      window.scrollBy(0, -1);
      window.scrollBy(0, 1);
      $(window).scrollTop(scrollTop);
    });
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  components: {
    RecipeDialog,
  },

  props: {
    mealPlan: {
      type: Object,
    },
    recepieData: {
      type: Object,
    },
    showMealPlanDetails: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
