<template>
  <div>
    <a
      class="
        dropdown-toggle
        arrow-none
        waves-light waves-effect
        topbar-icon-bell
      "
      data-toggle="dropdown"
      href="#"
      role="button"
      aria-haspopup="false"
      aria-expanded="false"
      style="background: white"
      @click="NotificationsDrawer"
    >
      <i class="healenticons-bell"></i>
      <span class="badge badge-bell badge-pill" v-if="notifications.length">
        {{ notifications.length }}
      </span>
    </a>
    <NotificationsDrawer
      ref="NotificationsDrawer"
      v-if="isNotificationsDrawerVisible"
      @close="closeNotificationsDrawer"
    />
  </div>
</template>

<script>
import settings from '@/settings.js';
import NotificationsDrawer from './TopBarDrawers/NotificationsDrawer';

const SOCKET_EVENT_NAME = 'general_notification';

export default {
  data() {
    return {
      notifications: [],
      notificationsInterval: null,
      pendingNotifications: [],
      client: {},
      timeout: null,
      isNotificationsDrawerVisible: false,
    };
  },

  methods: {
    findCommunicationNoteIndex(haystack, data) {
      const existingNote = haystack.find((note) => {
        if (note.note_type !== 'communication') {
          return false;
        }
        return note.room.id === data.room.id;
      });
      if (existingNote) {
        return haystack.indexOf(existingNote);
      }
      return -1;
    },
    generalNotification(data) {
      if (!data.uuid) {
        data.uuid = this.$uuid.v4();
      }

      switch (data.note_type) {
        case 'pending_progress_reports':
          // eslint-disable-next-line no-case-declarations
          const n = this.notifications.find(e => e.note_type === 'pending_progress_reports');
          if (n) {
            if (!data.has_reports) {
              const index = this.notifications.indexOf(n);
              this.notifications.splice(index, 1);
            }
          } else if (data.has_reports) {
            this.notifications.unshift({
              note_type: 'pending_progress_reports',
              default_icon: true,
              uuid: data.uuid,
              level: 'info',
              title: 'You have new progress reports',
              description: 'You have new progress reports to download',
            });
          }
          return;
        case 'communication':
          if (data.code === 'message_seen') {
            let index = this.findCommunicationNoteIndex(this.pendingNotifications, data);
            if (index !== -1) {
              this.pendingNotifications.splice(index, 1);
            }
            index = this.findCommunicationNoteIndex(this.notifications, data);
            if (index !== -1) {
              this.notifications.splice(index, 1);
            }
          } else {
            const indexInPending = this.findCommunicationNoteIndex(this.pendingNotifications, data);
            if (indexInPending !== -1) {
              this.pendingNotifications.splice(indexInPending, 1);
            }

            const indexInNotifications = this.findCommunicationNoteIndex(this.notifications, data);
            if (indexInNotifications !== -1) {
              this.notifications.splice(indexInNotifications, 1);
            }

            this.pendingNotifications.push(data);
            this.timeout = setTimeout(() => {
              console.log('Transferring from pendingNotifications to notifications:', data);
              const i = this.findCommunicationNoteIndex(this.pendingNotifications, data);
              if (i !== -1) {
                this.notifications.unshift(this.pendingNotifications.splice(i, 1)[0]);
              }
            }, 250);
          }
          return;

        case 'alert':
          this.notifications.unshift({
            note_type: 'alert',
            default_icon: true,
            uuid: data.uuid,
            level: 'danger',
            title: data.title,
            text: data.text,
            description: data.description,
            actionButton: {
              text: 'Go to patient profile',
              btnType: 'secondary',
              onClick: () => {
                this.$router
                  .push({
                    name: 'PatientProfile',
                    params: {
                      id: data.clientId,
                    },
                  })
                  .catch((e) => {
                    // pass
                  });
              },
            },
          });
          return;
      }

      this.notifications.unshift(data);
    },

    async updateBellNotifications() {
      return
      const response = await this.$api.getChatData();
      response.rooms.forEach((room) => {
        if (!room.hasUnseenMessages) return;
        this.generalNotification({
          note_type: 'communication',
          title: room.short_name,
          description: 'You have new message.',
          room,
        });
      });
    },

    NotificationsDrawer() {
      if (this.isNotificationsDrawerVisible) {
        this.closeNotificationsDrawer();
      } else {
        this.isNotificationsDrawerVisible = true;
        this.$nextTick(() => {
          if (this.$refs.NotificationsDrawer) {
            this.$refs.NotificationsDrawer.show();
          }
        });
      }
    },

    closeNotificationsDrawer() {
      this.isNotificationsDrawerVisible = false;
    },
  },

  async mounted() {
    this.$notificationBar.setComponent(this);

    this.$globalCon.subscribe(SOCKET_EVENT_NAME, this.generalNotification);

    this.updateBellNotifications();
    this.notificationsInterval = setInterval(this.updateBellNotifications, 60000);
  },

  beforeDestroy() {
    clearInterval(this.notificationsInterval);
    this.$globalCon.unsubscribe(SOCKET_EVENT_NAME, this.generalNotification);
    if (this.timeout) clearTimeout(this.timeout);
  },

  components: {
    NotificationsDrawer,
  },
};
</script>
