<template>
  <div class="h-row h-100 position-relative">
    <div
      class="col-lg-4 px-0"
      :class="{
        'd-none': !roomListVisible
      }"
    >
      <div
        class="valign-top roomSelector"
        :class="{
          'd-none': !roomListVisible,
          'force-w-100': roomListVisible && mode === 'small'
        }"
      >
        <RoomSelector
          ref="roomSelector"
          :rooms="rooms"
          :selectedRoom="selectedRoom"
          :draftRoomIds="draftRoomIds"
          @roomSelected="roomSelected"
          @newRoom="newRoom"
          :key="draftRoomIds.join(',')"
        />
      </div>

      <!-- <div class="display-table-cell valign-top topicSelector" v-if="selectedRoom">
          <TopicSelector />
        </div> -->
    </div>
    <div class="col-lg-8 px-0">
      <div
        class="h-100 w-100"
        :class="{
          'd-none': !chatDisplayVisible
        }"
      >
        <ChatDisplay
          ref="chatDisplay"
          :room="selectedRoom"
          :mode="mode"
          @onRoomMessage="onRoomMessage"
          @hideChatDisplay="hideChatDisplay"
          @draftRoomsUpdate="handleDraftRoomsUpdate"
        />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.MainInterface-RouterView > div {
  overflow-x: hidden;
  overflow-y: hidden;
}

.chat-wrapper {
  display: flex;
}

.roomSelector {
  width: 100%;
  height: 100%;
}

.chatDisplay {
  padding-top: 0.521vw;
}

.topicSelector {
  min-width: width 10.417vw;
}

.force-w-100 {
  width: 100% !important;
}
</style>

<script>
import io from 'socket.io-client';
import settings from '@/settings.js';

import RoomSelector from './components/room_selector/RoomSelector';
// import TopicSelector from './components/TopicSelector';
import ChatDisplay from './components/ChatDisplay';

export default {
  data() {
    return {
      socket: null,

      pageContentEl: null,
      pageContentElFirstDiv: null,

      rooms: null,
      selectedRoom: null,

      mode: $(window).width() > 600 ? 'normal' : 'small',
      roomListVisible: true,
      chatDisplayVisible: false,
      draftRoomIds: [],
      keyForRoomList: 0,
    };
  },

  watch: {
    selectedRoom(selectedRoom) {
      if (this.mode === 'small') {
        if (selectedRoom) {
          this.roomListVisible = false;
          this.chatDisplayVisible = true;
        } else {
          this.roomListVisible = true;
          this.chatDisplayVisible = false;
        }
      }
    },
  },

  methods: {
    handleDraftRoomsUpdate(draftRoomIds) {
      this.draftRoomIds = [...draftRoomIds];
    },
    roomSelected(room) {
      if (this.selectedRoom && room) {
        if (this.selectedRoom.id === room.id) {
          return;
        }
      }
      this.selectedRoom = room;
    },

    newRoom(room) {
      this.addRoom(room, true);
      this.roomSelected(room);
    },

    addRoom(room, moveUp) {
      if (!this.rooms) {
        this.rooms = [room];
        return;
      }

      const existingRoom = this.rooms.find(entry => entry.id === room.id);
      if (existingRoom) {
        // existingRoom.hasUnseenMessages = true;
        existingRoom.latestMessageReceivedAt = room.latestMessageReceivedAt;
        existingRoom.latestMessageAt = room.latestMessageAt;

        if (moveUp && (!this.selectedRoom || existingRoom.id !== this.selectedRoom.id)) {
          const index = this.rooms.indexOf(existingRoom);
          this.rooms.splice(index, 1);
          this.rooms.unshift(existingRoom);
        }
        this.sortRooms();
        return;
      }

      this.rooms.unshift(room);
      this.sortRooms();
    },

    sortRooms() {
      this.rooms
        .sort((a, b) => {
          const dateA = new Date(a.latestMessageAt);
          const dateB = new Date(b.latestMessageAt);
          return dateA - dateB;
        })
        .reverse();
    },

    onRoomMessage(data) {
      const room = this.rooms.find(entry => entry.id === data.room.id) || data.room;
      room.latestMessageReceivedAt = data.message.created_at;
      room.latestMessageAt = data.message.created_at;
      if (data.message.from_user.id === settings.currentSession.user.id) {
        return;
      }

      if (!this.selectedRoom || this.selectedRoom.id !== room.id) {
        room.hasUnseenMessages = true;
      }
      this.addRoom(room, true);
      if (data.message.is_seen) return;

      if (!this.selectedRoom || this.selectedRoom.id !== room.id) {
        this.$forceUpdate();
        this.$refs.roomSelector.$forceUpdate();
      }
    },

    onResize() {
      this.mode = $(window).width() > 600 ? 'normal' : 'small';
      if (this.mode === 'small') {
        this.roomListVisible = !this.selectedRoom;
        this.chatDisplayVisible = !this.roomListVisible;
      } else {
        this.roomListVisible = true;
        this.chatDisplayVisible = true;
      }
    },

    hideChatDisplay() {
      this.selectedRoom = false;
    },
  },

  mounted() {
    this.$refs.chatDisplay.$on('draftRoomsUpdate', (draftRoomIds) => {
      this.draftRoomIds = draftRoomIds;
    });
    $(window).on('resize', this.onResize);

    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>chat');
    });
    this.pageContentEl = $('.page-content');
    this.pageContentEl
      .removeClass('page-content')
      .addClass('w-100')
      .addClass('h-100');
    $('#content').addClass('forceContent100');
    $('.MainInterface-RouterView')
      .addClass('pt-0')
      .addClass('overflow-hidden');

    this.socket = io(`${settings.BACKEND_URL}/web-socket/chat`, { withCredentials: true });

    this.socket.on('connected', (data) => {
      if (!this.rooms) {
        this.rooms = [];
      }
      data.rooms.forEach((room) => {
        this.addRoom(room);
      });

      if (this.$route.params.userId) {
        this.$api.searchChatRoom(parseInt(this.$route.params.userId, 10)).then((result) => {
          if (result.length) {
            this.newRoom(result[0]);
          }
        });
      } else if (this.rooms && this.mode !== 'small') {
        this.roomSelected(this.rooms[0]);
      }
    });
    this.socket.on('room_messages_seen', (data) => {
      if (this.rooms) {
        const room = this.rooms.find(entry => entry.id === data.room.id);
        if (!room) return;
        room.hasUnseenMessages = false;
        this.rooms = JSON.parse(JSON.stringify(this.rooms));
        if (this.selectedRoom) {
          this.selectedRoom = this.rooms.find(roomsEntry => roomsEntry.id === this.selectedRoom.id);
        }
        this.sortRooms();
      }
    });
    this.$refs.chatDisplay.setSocket(this.socket);

    this.onResize();
  },

  destroyed() {
    $(window).off('resize', this.onResize);
    $('.MainInterface-RouterView')
      .removeClass('pt-0')
      .removeClass('overflow-hidden');
    $('nav.navbar').removeClass('forceNavBarForContent100');
    this.pageContentEl
      .addClass('page-content')
      .removeClass('w-100')
      .removeClass('h-100');
    this.socket.disconnect();
  },

  components: {
    RoomSelector,
    // TopicSelector,
    ChatDisplay,
  },
};
</script>
