
    <template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="row h-100 h-drawer-max79">
      <div class="col-lg-12">
        <div class="card h-100 h-drawer-card">
          <div
            class="card-header h-drawer-card-header h-padding-bottom-l"
            style="background-color: white"
          >
            <div class="row">
              <div class="col-lg-8 text-left">
                <h5 class="">Meal Plan Generation</h5>
              </div>
              <div class="col-lg-4 text-right">
                <button
                  type="button"
                  class="close"
                  aria-hidden="true"
                  @click="close"
                  :disabled="loading"
                >
                  <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
                  <img
                    src="../../../../../../../public/assets/images/Collapse-drawer.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="card-body h-drawer-card-body">
            <div class="row">
              <div class="col-12">
                <div v-if="!loading" class="loadedModalBody">
                  <h4 class="h-h4 h-primary_shade_1">
                    Meal plan generation can replace some of currently assigned
                    meal plans.
                  </h4>
                  <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
                    Do you want to proceed?
                  </h4>
                </div>
                <Loader v-if="loading" />
              </div>
            </div>
          </div>
          <div class="h-drawer-card-footer">
            <button
              type="button"
              class="h-btn h-btn-accent"
              @click="generate"
              :disabled="loading"
            >
              Generate
            </button>

            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-s"
              @click="close"
              :disabled="loading"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>


<style lang="stylus" scoped>
.loadedModalBody {
  min-height: 100px;
}
</style>

<script>
import Loader from '@/components/Loader';

export default {
  props: {
    client: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      startDate: null,
    };
  },

  methods: {
    show(startDate) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.startDate = startDate;
      // this.$refs.slottedMessage.show();
    },

    close(force) {
      // this.$refs.slottedMessage.close(force);
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async generate() {
      this.loading = true;
      const response = await this.$api.generateClientMealPlan(
        this.client.id,
        this.startDate.format('YYYY-MM-DD'),
        3.0,
        true,
      );
      if (response.status === 'ok') {
        $.notify('Meal plan generated.', {
          position: 'top center',
          className: 'success',
        });
        this.$emit(
          'mealPlanGenerated',
          response.diet_data.plans,
          response.diet_data.notes,
        );
      } else {
        $.notify(response.message, {
          position: 'top center',
          className: 'error',
        });
      }
      this.close(true);
    },
  },

  components: {
    Loader,
  },
};
</script>
