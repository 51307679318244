<template>
  <div>
    <div class="h-d-flex h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1 h-margin-left-l">Diet overview</h3>
      <div>
        <a
          v-if="dietMode === 'recipe'"
          class="h-btn h-btn-accent h-a-btn h-margin-right-l h-padding-l"
          href="javascript:void(0)"
          @click="$emit('generateMealPlan', startDate)"
          >Generate meal plan</a
        >
      </div>
    </div>
    <div class="h-margin-top-l">
      <DateSwitcher
        class="dateSwitcher h-margin-bottom-l h-margin-top-l h-text-label"
        :step="7"
        :disabled="loading"
        v-model="startDate"
      />
    </div>
    <!--     <div class="mb-3 clearfix">
      <div class="float-right btn-group">
        <button
          type="button"
          class="btn btn-primary bnt-sm zIndex"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          :disabled="loading"
        >
          Commands <i class="ml-1 fas fa-caret-down"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right">
          <div v-if="dietMode === 'recipe'" class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)">
            <i class="fas fa-times mr-2 ml-1"></i> Close
          </a>
        </div>
      </div> -->

    <div class="h-mealplancheckbox h-margin-right-l h-margin-left-l h-d-flex h-space-between">
      <div>
        <InputField
          type="checkbox"
          checkboxSwitchType="primary"
          label="Manual Meal Plan Mode"
          id="diet:free-meal-plane-mode-switcher"
          class="h-margin-top-l"
          :disabled="loading"
          :value="dietMode === 'free'"
          @input="$emit('dietModeChanged', $event ? 'free' : 'recipe')"
        />
      </div>

      <div>
        <InputField
          v-if="dietMode === 'recipe'"
          type="checkbox"
          checkboxSwitchType="primary"
          label="Show meal details"
          id="diet:show-mealPlan-details"
          class="h-margin-top-l"
          :disabled="loading"
          v-model="showMealPlanDetails"
        />
      </div>
    </div>

    <div v-if="startDate">
      <div class="h1-table mealGrid" :class="{ 'd-none': loading }">
        <table
          class="table dataTable w-100 table-bordered xs-title-content"
          :class="{ 'table-striped': showMealPlanDetails }"
        >
          <thead>
            <tr>
              <th></th>
              <th
                v-for="gridDate of gridDates"
                :key="gridDate"
                class="text-center text-nowrap"
                style="font-size: 0.729vw; color: black; font-weight: 900"
              >
                {{ gridDate | formatDate }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="mealType of mealTypes" :key="mealType">
              <th class="">
                {{ mealType }}
              </th>

              <td v-for="gridDate of gridDates" :key="gridDate" class="">
                <MealPlanRecipeCard
                  v-if="dietMode === 'recipe'"
                  :mealPlan="getRecipeMealPlanEntry(gridDate, mealType)"
                  :recepieData="getRecipeData(gridDate, mealType)"
                  :showMealPlanDetails="showMealPlanDetails"
                  @recipeSaved="
                    (recipe, ingredients) => {
                      $emit('recipeSaved', recipe, ingredients)
                    }
                  "
                />
                <MealPlanFreeCard
                  v-else
                  :mealPlan="getFreeMealPlanEntry(gridDate, mealType)"
                  :date="gridDate"
                  :mealType="mealType"
                  :diet="diet"
                  @freeMealPlanUpdated="
                    (posted, recieved) => {
                      $emit('freeMealPlanUpdated', posted, recieved)
                    }
                  "
                />
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr class="notes">
              <th class="text-center text-nowrap w-1">Notes</th>
              <td
                v-for="noteData of getNotes(gridDates)"
                :key="noteData.KEY"
                :class="{ 'text-center no-note': !noteData.note }"
                @click="$refs.dayNoteEditorDialog.show(noteData)"
              >
                <span v-if="!noteData.note">&lt;no note&gt;</span>
                <span v-else class="pre-wrap">{{ noteData.note.note }}</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <Loader v-if="loading" />
    </div>

    <DayNoteEditorDialog ref="dayNoteEditorDialog" @noteUpdated="noteUpdated" />
  </div>
</template>

<style lang="stylus" scoped>
.dateSwitcher {
  text-align: center;
  margin-top: 0.833vw;
  margin-bottom: 0.417vw;
}

.mode-switcher {
  margin-left: 2.5vw;
  padding-top: 0.521vw;
}

table {
  tbody {
    th.meal-card, td.meal-card {
      height: 5.833vw;
      width: 12.5%;
      vertical-align: middle;
    }

    td.meal-card {
      padding: 0px;
      position: relative;
    }
  }

  tfoot {
    tr.notes {
      background-color: #f1f5fa;
      color: #50649c;

      th, td {
        vertical-align: top;
      }

      td {
        cursor: pointer;
      }

      td.no-note {
        text-align: center;
        color: #a4abc5;
      }

      td:hover {
        color: #2b5adcff;
      }
    }
  }
}

.zIndex {
  z-index: 1;
}
</style>

<script>
import moment from 'moment-timezone';
import Loader from '@/components/Loader';
import DateSwitcher from '@/components/DateSwitcher';
import MealPlanRecipeCard from './MealPlanRecipeCard';
import MealPlanFreeCard from './MealPlanFreeCard';
import DayNoteEditorDialog from './DayNoteEditorDialog';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      mealTypes: ['Breakfast', 'Morning Snack', 'Lunch', 'Evening Snack', 'Dinner'],
      startDate: null,
      showMealPlanDetails: false,
    };
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY');
    },
  },
  computed: {
    gridDates() {
      const curDate = moment(this.startDate);
      const result = [];
      for (let i = 0; i < 7; i += 1) {
        result.push(this.$dateUtils.formatDate(moment(curDate)));
        curDate.add(1, 'days');
      }
      return result;
    },
  },
  mounted() {},
  methods: {
    getRecipeMealPlanEntry(mealDate, mealType) {
      return this.mealPlans.find((entry) => {
        return entry.date === mealDate && entry.type === mealType;
      });
    },

    getRecipeData(mealDate, mealType) {
      const mealPlan = this.getRecipeMealPlanEntry(mealDate, mealType);
      if (!mealPlan) return null;
      return this.recepies[mealPlan.recipe_id];
    },

    getFreeMealPlanEntry(mealDate, mealType) {
      return this.freeMealPlans.find((entry) => {
        return entry.date === mealDate && entry.meal_type === mealType;
      });
    },

    getNotes(dates) {
      return dates.map((oneDate) => {
        return {
          date: oneDate,
          clientId: this.client.id,
          note: this.notes.find((entry) => {
            return entry.date === oneDate;
          }),
        };
      });
    },

    noteUpdated(noteData, note) {
      const existingNote = this.notes.find((entry) => {
        return entry.date === noteData.date;
      });
      if (existingNote) {
        this.notes.splice(this.notes.indexOf(existingNote), 1);
      }
      if (note) {
        this.notes.push(note);
      }
      this.$forceUpdate();
    },
  },

  components: {
    Loader,
    InputField,
    DateSwitcher,
    MealPlanRecipeCard,
    MealPlanFreeCard,
    DayNoteEditorDialog,
  },

  props: {
    client: {
      type: Object,
    },
    diet: {
      type: Object,
    },
    mealPlans: {
      type: Array,
    },
    freeMealPlans: {
      type: Array,
    },
    notes: {
      type: Array,
    },
    dietMode: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    recepies: {
      type: Object,
    },
  },
};
</script>
