<template>
  <div class="h-drawer-max90 PatientProfileInDrawer">
    <ProfileDrawer :direction="'right'" :exist="true" ref="profileDialog">
      <div class="h-drawer-card90">
        <div class="h-drawer-card-header90">
          <div class="h-main-drawer-card-header90">
            <div>
              <h3 v-if="client" class="h-h3 h-primary_shade_1 h-margin-bottom-xs align-baseline">
                {{ client.hospital_name }} - {{ client.name }}
                <i
                  class="idToClipboard ToClipboard healenticons-path-4039 h-padding-left-xs"
                  data-tooltip="Copy patient ID to clipboard"
                  @click="copyToClipboard"
                ></i>
              </h3>
              <tabs
                :tabs="tabs"
                :currentTab="currentTab"
                :wrapper-class="'default-tabs'"
                :tab-class="'default-tabs__item'"
                :tab-active-class="'default-tabs__item_active'"
                :line-class="activeLineStyle"
                @onClick="handleClick"
              />
            </div>
            <button
              id="PatientProfileDrawer"
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../../../public/assets/images/Collapse-drawerDarckGray.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body90">
          <div
            v-if="client && (client.pending_activation === true || client.is_archived === true)"
            class="h-h4 h-primary_shade_1"
            style="
        background-color: white;
        padding: 15px;
        display: flex;
        justify-content: center;
        box-shadow: 0.052vw 0.052vw 0.156vw rgba(0, 0, 0, 0.1);
        align-items: center;
        position: fixed;
        width: 100%;
        z-index: 999;
        margin-left: -15rem;
      "
          >
            {{
              client.pending_activation === true
                ? "This patient is pending activation"
                : "This patient is inactive"
            }}
            &nbsp;
            <a @click.prevent="bannerLinkAction" href="#">{{ bannerLinkText }}</a>
          </div>
          <div v-if="client" class="h-h4 h-primary_shade_1">
            <PatientProfile
              :key="client.id"
              :client="client"
              :currentTab="currentTab"
              @activityCount="setActivityCount"
            />
          </div>
        </div>
      </div>
    </ProfileDrawer>
  </div>
</template>

<script>
import Tabs from 'vue-tabs-with-active-line';
import PatientProfile from '../../patient_profile/PatientProfile';
import eventBus from '../../../../event-bus';
import { PATIENT_TABS } from '@/utils/constants';

export default {
  data() {
    return {
      loading: false,
      client: null,
      tabs: PATIENT_TABS,
      bannerNoticeText: '',
      bannerLinkText: '(Activate now)',
      currentTab: localStorage.lastSelectedTabInPatientProfile
        ? localStorage.lastSelectedTabInPatientProfile
        : PATIENT_TABS[0].value,
      updateCurrentTab: false,
      activeLineStyle: 'default-tabs__active-line',
      appointment_being_edited: false,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Message',
    },
    closeDisabled: {
      type: Boolean,
      default: false,
    },
    showOnMount: {
      type: Boolean,
      default: false,
    },
    bodyBackgroundColor: {
      type: String,
      default: null,
    },
  },

  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
      localStorage.lastSelectedTabInPatientProfile = this.currentTab;
      eventBus.$emit('tabSelectedInTopBar', { newTab });
    },
    setActivityCount(count) {
      this.tabs = this.tabs.map(item => ({
        ...item,
        title:
          item.value === 'tabPatientLog'
            ? `Activity Logs <span class="h-dangerous">(${count})</span>`
            : item.title,
      }));
      eventBus.$emit('activityCountUpdated', count);
    },
    async getClient(id) {
      try {
        this.client = await this.$api.getClient(id);
      } catch (e) {
        this.client = null;
      }
    },
    async refreshClientData(clientId) {
      if (!clientId) {
        console.error('Client ID is null or undefined');
        return;
      }

      try {
        this.loading = true;
        await this.getClient(clientId);
      } catch (error) {
        console.error('Error refreshing client data:', error);
      } finally {
        this.loading = false;
      }
    },
    removePatientIDParam() {
      const url = new URL(window.location.href);
      if (url.searchParams.has('patientID')) {
        url.searchParams.delete('patientID');
        window.history.pushState({}, '', url);
      }
    },

    async show(clientId) {
      await this.getClient(clientId);
      this.$refs.profileDialog.open();
      const currentUrl = new URL(window.location.href);
      let hash = currentUrl.hash;
      const hashParams = new URLSearchParams(hash.slice(hash.indexOf('?')));
      if (!hashParams.has('patientID')) {
        if (hash.includes('?')) {
          hash += `&patientID=${clientId}`;
        } else {
          hash += `?patientID=${clientId}`;
        }
        window.location.hash = hash;
      }
      this.$refs.profileDialog.active = true;
      this.loading = false;
    },

    close(force) {
      if (this.appointment_being_edited) {
        const title = 'Tab close';
        eventBus.$emit('confirmAppointmentOutstandingChangesSave', { title });
      } else if (this.closeDisabled && force !== true) {
      } else {
        this.$refs.profileDialog.close();
        this.$refs.profileDialog.active = false;
        eventBus.$emit('reloadDataTable', {});
        eventBus.$emit('profile-closed', {});
        this.$emit('profileClosed');
      }
    },

    scrollTop(top) {
      return $(this.$refs.profileDialog).scrollTop(top);
    },
    async bannerLinkAction() {
      const today =
        this.client.plan_start_date == null
          ? new Date().toISOString().split('T')[0]
          : this.client.plan_start_date;

      const assignedNurseId = this.client.assigned_nurse.id;
      const careProtocolId = this.client.care_protocol.id;
      const surgeonId = this.client.surgeon.id;
      const email = '';

      const payload = {
        ...this.client,
        email,
        assigned_nurse_id: assignedNurseId,
        care_protocol_id: careProtocolId,
        surgeon_id: surgeonId,
        is_archived: false,
        pending_activation: false,
        plan_start_date: today,
      };

      const response = await this.$api.upsertClient(payload);

      if (response.status === 'ok') {
        this.client.is_archived = false;
        this.client.pending_activation = false;
        $.notify('Patient successfully activated.', {
          position: 'top center',
          className: 'success',
        });
      } else {
        this.loading = false;
        $.notify('Failed to activate patient.', {
          position: 'top center',
          className: 'error',
        });
        console.error('Error updating client:', response.errors);
      }
    },
    copyToClipboard() {
      const id = this.client.id;

      navigator.clipboard
        .writeText(id)
        .then(() => {
          $.notify(`Copied the patient ID ${id} to clipboard`, {
            position: 'top center',
            className: 'success',
          });
        })
        .catch((err) => {
          console.error('Unable to copy to clipboard', err);
        });
    },
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    $(this.$refs.profileDialog).remove();
  },

  async mounted() {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    eventBus.$on(
      'sendingTabArrayInfo',
      async (event) => {
        this.tabs = event.payload.tabInfo;
        this.updateCurrentTab = event.payload.updateCurrentTab;
        if (this.tabs.length > 0) {
          if (this.updateCurrentTab) {
            const currentTabInfo = event.payload.activeTab;
            const selectedTab = this.tabs.filter(tab => tab.value === currentTabInfo);
            await sleep(10);
            this.currentTab = selectedTab.length > 0 ? selectedTab[0].value : this.tabs[0].value;
          }
        } else {
          await sleep(10);
          this.currentTab = 'tabMessages';
        }
        const newTab = this.currentTab;
        eventBus.$emit('tabSelectedInTopBar', { newTab });
      },
      this,
    );
    eventBus.$on(
      'setActiveTab',
      (event) => {
        this.currentTab = event.activeTabValue;
      },
      this,
    );
    eventBus.$on(
      'uncompleted-appointment-edit-begin',
      (event) => {
        this.appointment_being_edited = true;
      },
      this,
    );

    eventBus.$on(
      'uncompleted-appointment-edit-stop',
      (event) => {
        this.appointment_being_edited = false;
      },
      this,
    );
    eventBus.$on(
      'appointment-edit-end',
      (event) => {
        this.close();
      },
      this,
    );
    await this.$nextTick();
    eventBus.$on(
      'CloseEditProfileInfoTab',
      async (event) => {
        if (!this.client) {
          console.warn('Client data not ready in CloseEditProfileInfoTab event');
          return;
        }
        if (this.client && this.client.id) {
          await this.refreshClientData(this.client.id);
        }
      },
      this,
    );
    await this.$nextTick();
    eventBus.$on(
      'drawer-closed',
      async (event) => {
        if (this.client && this.client.id) {
          await this.refreshClientData(this.client.id);
        }
      },
      this,
    );
  },

  components: {
    PatientProfile,
    Tabs,
  },
};
</script>
