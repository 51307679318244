<template>
  <div class="h-margin-left-l h-margin-right-l">
    <div class="h-d-flex h-margin-bottom-m h-margin-top-l">
      <InputField
        v-if="!forceEnabled"
        :id="`PerMeal-${mealName}`"
        type="checkbox"
        checkboxSwitchType="success"
        v-model="enabled"
        class="d-inline-block"
      />
      <h4
        class="h-h4 h-primary_shade_1"
        :class="{ 'text-muted h-secondary_shade_1': !enabled && !forceEnabled }"
      >
        {{ title || mealName }}
      </h4>
    </div>
    <div v-if="enabled || forceEnabled">
      <div v-if="!hideSwitchers">
        <div
          v-for="nutrientType of nutrientTypes"
          :key="nutrientType"
          class="d-inline-block h-margin-top-s"
        >
          <button
            class="h-btn-bordered h-btn-height20 h-margin-right-s"
            :class="{
              'h-btn-fill-accent': isNutrientEnabled(nutrientType),
              'h-btn-bordered': !isNutrientEnabled(nutrientType),
            }"
            @click="toggleNutrientType(nutrientType)"
          >
            + {{ nutrientType }}
          </button>
        </div>
      </div>
      <div class="h-margin-auto h1-table h-table-inline-input">
        <table
          v-if="enabledNutrientTypes.length !== 0"
          class="h-margin-top-l table"
          :class="{ 'h-margin-top-l': !hideSwitchers }"
        >
          <tbody>
            <tr
              v-for="nutrientType of enabledNutrientTypes"
              :key="nutrientType"
            >
              <th
                style="width: 5%"
                :class="{
                  '': percent && nutrientType !== 'Calories',
                }"
              >
                {{ nutrientType }}
                <div
                  v-if="percent && nutrientType !== 'Calories'"
                  class="h-secondary_shade_1"
                >
                  <small> (MAX % of Calories) </small>
                </div>
              </th>

              <td style="width: 25%" class="" v-if="isTargetColumnVisible">
                <InputField
                  type="number"
                  placeholder="Target"
                  :min="0"
                  :value="
                    rangeValues[getNutrientTypeKey(nutrientType, 'target')]
                  "
                  @change="(e) => valueChanged(e, nutrientType, 'target')"
                  :step="nutrientTypesMap[nutrientType] === 'calorie' ? 10 : 1"
                  class=""
                  dense
                  :error="errors[getNutrientTypeKey(nutrientType, 'target')]"
                />
              </td>
              <td style="width: 4%" v-if="isTargetColumnVisible">
                <span v-if="nutrientType !== 'Calories'">%</span>
              </td>

              <td style="width: 25%">
                <InputField
                  type="number"
                  placeholder="Min"
                  :min="0"
                  :value="
                    rangeValues[getNutrientTypeKey(nutrientType, 'floor')]
                  "
                  @change="(e) => valueChanged(e, nutrientType, 'floor')"
                  dense
                  :error="errors[getNutrientTypeKey(nutrientType, 'floor')]"
                />
              </td>
              <td style="text-align: center; width: 2%">-</td>
              <td style="width: 25%">
                <InputField
                  type="number"
                  placeholder="Max"
                  :min="0"
                  :value="rangeValues[getNutrientTypeKey(nutrientType, 'cap')]"
                  @change="(e) => valueChanged(e, nutrientType, 'cap')"
                  dense
                  :error="errors[getNutrientTypeKey(nutrientType, 'cap')]"
                />
              </td>
              <td style="width: 4%">
                {{ getNutrientUnit(nutrientType) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="h-h4 h-primary_shade_1 h-text-center h-margin-top-l">
          No nutrients selected.
        </div>
      </div>
      <hr class="h-hr h-margin-top-l" />
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.switch-button {
  border-radius: 1.302vw;
  padding: -0.104vw;
  height: 1.042vw;
  line-height: 0px;
  padding: 0.521vw 0.781vw 0.521vw 0.521vw;
}

.nutrients-table {
  td, th {
    padding: 0.156vw 0em;
    vertical-align: center;
  }

  .name-cell {
    min-width: 10em;
    text-align: right;
  }

  .name-cell-2l {
    vertical-align: middle;
  }

  .number-cell {
    width: 10.417vw;
    padding-top: 0.75em;
  }

  .hyphen-cell {
    width: 2em;
    text-align: center;
  }
}
</style>


<script>
import InputField from '@/components/InputField';
import calculator from './calculator';

const nutrientTypesMap = {
  Calories: 'calorie',
  Carbohydrates: 'carbohydrate',
  Protein: 'protein',
  Fat: 'fat',
  Fiber: 'fiber',
  Sugar: 'sugar',
  Sodium: 'sodium',
  Potassium: 'potassium',
  Phosphorus: 'phosphorus',
};

const enabledNutrientTypesMap = {
  calories_on: 'Calories',
  carbs_on: 'Carbohydrates',
  protein_on: 'Protein',
  fat_on: 'Fat',
};

const nutrientTypeUnits = {
  Calories: 'cal',
  Carbohydrates: 'g',
  Protein: 'g',
  Fat: 'g',
  Fiber: 'g',
  Sugar: 'g',
  Sodium: 'mg',
  Potassium: 'mg',
  Phosphorus: 'mg',
};

export default {
  data() {
    return this.getInitialData();
  },

  computed: {
    isTargetColumnVisible() {
      return (
        this.percent && this.enabledNutrientTypes.indexOf('Calories') !== -1
      );
    },
  },

  methods: {
    getInitialData() {
      const enabledNutrientTypes = [];
      const rangeValues = {};
      this.nutrientTypes.forEach((nutrientType) => {
        const floorKey = this.getNutrientTypeKey(nutrientType, 'floor');
        const capKey = this.getNutrientTypeKey(nutrientType, 'cap');
        rangeValues[floorKey] = this.dietData.diet[floorKey];
        rangeValues[capKey] = this.dietData.diet[capKey];
        if (!this.percent && this.forceEnabledNutrientTypes === null) {
          if (this.dietData.diet[floorKey] || this.dietData.diet[capKey]) {
            enabledNutrientTypes.push(nutrientType);
          }
        }
      });

      if (this.percent && this.forceEnabledNutrientTypes === null) {
        Object.keys(enabledNutrientTypesMap).forEach((key) => {
          if (this.dietData.diet[key]) {
            enabledNutrientTypes.push(enabledNutrientTypesMap[key]);
          }
        });
      }

      const targetValues = null;
      if (this.percent) {
        calculator.setTargetValues(this.mealPrefix, rangeValues);
      }

      return {
        errors: {},
        nutrientTypesMap,
        enabled: this.dietData.diet[`has_${this.mealPrefix}`],
        enabledNutrientTypes,
        rangeValues,
      };
    },

    reset() {
      const initialData = this.getInitialData();
      Object.keys(initialData).forEach((key) => {
        this[key] = initialData[key];
      });
    },

    getNutrientTypeKey(nutrientType, keyType) {
      const nutrientKey = nutrientTypesMap[nutrientType];
      return calculator.getKey(this.mealPrefix, nutrientKey, keyType);
    },

    isNutrientEnabled(nutrientType) {
      return this.enabledNutrientTypes.indexOf(nutrientType) !== -1;
    },

    getNutrientUnit(nutrientType) {
      return nutrientTypeUnits[nutrientType];
    },

    toggleNutrientType(nutrientType) {
      const index = this.enabledNutrientTypes.indexOf(nutrientType);
      if (index === -1) {
        this.enabledNutrientTypes.push(nutrientType);
      } else {
        this.enabledNutrientTypes.splice(index, 1);
      }

      // sorting
      this.enabledNutrientTypes = this.nutrientTypes.filter((entry) => {
        return this.enabledNutrientTypes.indexOf(entry) !== -1;
      });
    },

    valueChanged(event, nutrientType, keyType) {
      ['target', 'floor', 'cap'].forEach((key) => {
        this.errors[this.getNutrientTypeKey(nutrientType, key)] = null;
      });
      calculator.setNutrientValue(
        this.mealPrefix,
        this.rangeValues,
        nutrientTypesMap[nutrientType],
        keyType,
        event.target.value,
        this.percent,
      );
      this.$forceUpdate();
    },

    grabData() {
      const result = {};
      result[`has_${this.mealPrefix}`] = this.enabled;
      this.nutrientTypes.forEach((nutrientType) => {
        ['floor', 'cap'].forEach((keyType) => {
          const key = this.getNutrientTypeKey(nutrientType, keyType);
          result[key] = this.enabled || this.forceEnabled ? this.rangeValues[key] : 0;
        });
      });
      if (this.percent) {
        Object.keys(enabledNutrientTypesMap).forEach((key) => {
          const nutrientType = enabledNutrientTypesMap[key];
          result[key] = this.enabledNutrientTypes.indexOf(nutrientType) !== -1;
        });
      }
      return result;
    },

    setErrors(errors) {
      this.errors = errors;
    },
  },
  components: {
    InputField,
  },

  props: {
    title: {
      type: String,
    },
    nutrientTypes: {
      type: Array,
      default: () => Object.keys(nutrientTypesMap),
    },
    forceEnabled: {
      type: Boolean,
      default: false,
    },
    mealName: {
      type: String,
    },
    mealPrefix: {
      type: String,
    },
    dietData: {
      type: Object,
    },
    percent: {
      type: Boolean,
      default: false,
    },
    hideSwitchers: {
      type: Boolean,
      default: false,
    },
    forceEnabledNutrientTypes: {
      default: null,
    },
  },
};
</script>
