<template>
  <div class="account-body">
    <div class="row">
      <div class="col-12 align-self-center">
        <div class="auth-page">
          <div class="card auth-card shadow-lg">
            <div class="card-body">
              <div class="px-3">
                <div class="text-center auth-logo-text">
                  <h4 class="mt-0 mb-3 mt-4">Change your password</h4>
                  <p v-if="!loading && !globalMessage" class="text-muted mb-0">
                    Set new password
                  </p>
                </div> <!--end auth-logo-text-->

                <div v-if="!loading && !globalMessage">
                  <form class="form-horizontal auth-form my-4">

                    <div class="form-group">
                      <label for="useremail">Email</label>
                      <div class="input-group mb-3">
                        <span class="auth-form-icon">
                          <i class="dripicons-mail"></i>
                        </span>
                        <input
                          id="useremail"
                          type="email"
                          class="form-control"
                          :value="recoverData.email"
                          readonly
                        >
                      </div>
                    </div><!--end form-group-->

                    <div class="form-group">
                      <label for="userpassword">Password</label>
                      <div class="input-group mb-3">
                        <span class="auth-form-icon">
                          <i class="dripicons-lock"></i>
                        </span>
                        <input
                          type="password"
                          class="form-control"
                          :class="{ 'is-invalid': errors.password }"
                          id="userpassword"
                          placeholder="Enter password"
                          v-model="password"
                        >
                        <div class="invalid-feedback" v-if="errors.password">
                          {{ errors.password }}
                        </div>
                      </div>
                    </div><!--end form-group-->

                    <div class="form-group">
                      <label for="conf_password">Confirm Password</label>
                      <div class="input-group mb-3">
                        <span class="auth-form-icon">
                          <i class="dripicons-lock-open"></i>
                        </span>
                        <input
                          id="conf_password"
                          type="password"
                          class="form-control"
                          :class="{ 'is-invalid': errors.confirmPassword }"
                          placeholder="Enter Confirm Password"
                          v-model="confirmPassword"
                        >
                        <div class="invalid-feedback" v-if="errors.confirmPassword">
                          {{ errors.confirmPassword }}
                        </div>
                      </div>
                    </div><!--end form-group-->

                    <div class="form-group mb-0 row">
                      <div class="col-12 mt-2">
                        <button
                          class="btn btn-primary btn-round btn-block waves-effect waves-light"
                          type="button"
                          @click="changePassword"
                        >
                          Change password <i class="fas fa-sign-in-alt ml-1"></i>
                        </button>
                      </div><!--end col-->
                    </div> <!--end form-group-->
                  </form><!--end form-->

                </div>

                <Loader v-if="loading" />

                <div v-if="globalMessage">
                  <p class="text-danger text-center">{{ globalMessage }}</p>

                  <div v-if="!isMobile" class="m-3 mt-4 text-right text-muted">
                    <p>Go to
                      <router-link :to="{name: 'Login'}" class="text-primary">
                        login page
                      </router-link>
                    </p>
                  </div>
                </div>

              </div><!--end /div-->
            </div><!--end card-body-->
          </div><!--end card-->
        </div><!--end auth-page-->
      </div><!--end col-->
    </div><!--end row-->
  </div>
</template>


<style lang="stylus" scoped>
.account-body
  background-color transparent
  padding-top 2em
  padding-bottom 4em
</style>


<script>
import settings from '@/settings.js';
import Loader from './components/Loader';
import virgilHelper from '@/utils/virgil_helper';


export default {
  name: 'ChangePassword',

  data() {
    return {
      loading: true,

      recoverData: {
        email: '',
      },
      password: '',
      confirmPassword: '',

      errors: {},

      globalMessage: '',

      isMobile: this.$route.query.mobile === 'true',
    };
  },

  watch: {
    password() {
      this.errors.password = null;
    },

    confirmPassword() {
      this.errors.confirmPassword = null;
    },
  },

  methods: {
    async changePassword() {
      this.loading = true;
      const response = await this.$api.usePasswordRestore({
        code: this.$route.params.code,
        password: this.password,
        confirmPassword: this.confirmPassword,
      });

      this.errors = response.errors || {};
      if (response.status === 'error') {
        if (response.message) {
          this.globalMessage = response.message;
        }
        this.loading = false;
        return;
      }
      if (response.status === 'ok') {
        if (!this.isMobile) {
          settings.currentSession = response.current_session_data;
          this.$router.push({
            name: 'index',
          }).catch((err) => {});
        } else {
          this.$router.push({
            name: 'PasswordChanged',
          }).catch((err) => {});
        }
      }
    },
  },

  async mounted() {
    const response = await this.$api.getPasswordRestore(this.$route.params.code);
    this.loading = false;
    if (response.status === 'error') {
      this.globalMessage = response.message;
      return;
    }
    this.recoverData = response.data;
  },

  components: {
    Loader,
  },
};
</script>
