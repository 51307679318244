<template>
  <div class="h-drawer-max70 EditPatientInDrawer">
    <Drawer :direction="'right'" :exist="true" ref="Dialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header">
            <div>
              <h3 class="h-h3 h-primary_shade_1 h-margin-bottom-s">
                Edit {{ this.client.name }} Profile
              </h3>
            </div>
            <button
              id="PatientProfileDrawer"
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../../public/assets/images/Collapse-drawerDarckGray.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <EditPatientInfoTab
            :defaultClient="client"
            :key="client.id"
            @updateSuccess="updateSuccess"
          />
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import EditPatientInfoTab from './EditPatientInfoTab';
import eventBus from '../../../event-bus';

export default {
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  props: {
    client: {
      type: Object,
    },
  },

  methods: {
    show(data) {
      this.data = data;
      this.$refs.Dialog.open();
      this.$refs.Dialog.active = true;
      this.loading = false;
      eventBus.$emit('OpenEditProfile', {});
    },

    updateSuccess(client) {
      this.$emit('updateSuccess', client);
    },

    close() {
      this.$refs.Dialog.close();
      this.$refs.Dialog.active = false;
    },
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    $(this.$refs.Dialog).remove();
  },

  mounted() {
    eventBus.$on(
      'CloseEditProfileInfoTab',
      (event) => {
        this.close();
      },
      this,
    );
  },

  components: {
    EditPatientInfoTab,
  },
};
</script>

<style lang="stylus" scoped>
@import url('https://fonts.googleapis.com/css?family=Quicksand');

.tabs-with-content {
  border: 0.052vw solid #e6e6e6;
  background-color: #f0f4f9;
  margin-left: -0.521vw;
  margin-right: -0.521vw;
  padding-left: 0.521vw;
  padding-right: 0.521vw;
  padding-top: 0.521vw;
}

h5 {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 1.25vw;
  color: #152145;
  letter-spacing: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin: 0;
}

.tab-text {
  font-family: Quicksand;
  font-weight: Medium;
  font-size: 0.729vw;
  color: #152145;
}

.table-title-bold {
  font-family: Quicksand;
  font-weight: 900;
  font-size: 0.781vw;
  color: #152145;
}
</style>
