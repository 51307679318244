<template>
  <div>
    <div class="h-drawer-card-header">
      <div class="h-d-flex h-space-between align-items-center h-margin-bottom-m">
        <div>
          <h3 class="h-h3 h-primary_shade_1">
            {{ this.filterTitle }}
          </h3>
        </div>
        <div class="h-item-dialog-topbar-right h-d-flex h-align-items-center align-items-end">
          <input
            type="search"
            placeholder="Search"
            class="h-input h-item-search"
            v-model="textSearch"
            @input="handleInput()"
          />

          <div class="h-relative h-line-height-1 dropdown h-add-dropdown">
            <a
              class="dropdown-toggle arrow-none"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
              style="background: white"
            >
              <i
                style="font-size: 10rem;"
                class=" h-secondary_shade_1 healenticons-download h-margin-left-m"
              >
              </i>
            </a>
            <div ref="collectionDropdown" class="dropdown-menu assessment-dropdown"></div>
          </div>

          <i
            class="h-cursor-pointer h-h4 h-secondary_shade_1 healenticons-ref h-margin-left-m"
            @click="refreshClientAssessments"
          ></i>
          <button type="button" class="close h-margin-left-m" aria-hidden="true" @click="close">
            <img src="../../../public/assets/images/Collapse-drawer.svg" alt="" />
          </button>
        </div>
      </div>
    </div>

    <div class="h1-table h-margin-left-l h-margin-right-l h-table-footer-padding">
      <table
        id="assessmentsTable"
        ref="assessmentsTable"
        class="table dataTable AssessmentClient h-no-footer"
      >
        <thead class="thead-light">
          <tr>
            <th style="width: 5%;">Actions</th>
            <th style="width: 5%;">Repeat Interval</th>
            <th style="width: 5%;">Due date</th>
            <th style="width: 5%;">Submitted on</th>
            <th style="width: 5%;">Status</th>
            <th style="width: 5%;">Score</th>
            <th style="width: 5%;" class="h-text-center">Trend</th>
          </tr>
        </thead>
      </table>
    </div>

    <SendReminderDialog ref="sendReminderDialog" />
    <AssessmentAnswerDialog
      ref="assessmentAnswerDialog"
      :client="client"
      @updatedAssessment="refreshClientAssessments"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import SparklineChart from '@/components/charts/SparklineChart';
import AssessmentAnswerDialog from '@/components/assesment_client/components/AssessmentAnswerDialog';
import SendReminderDialog from '@/components/assesment_client/components/SendReminderDialog';

export default {
  data() {
    return {
      items: [],
      dataTable: null,
      textSearch: '',
      showCollectionMenu: false,
    };
  },
  watch: {
    filterTitle() {
      this.filterByTitle();
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
    filterByTitle() {
      if (this.dataTable) {
        this.dataTable.draw();
      }
    },
    async refreshClientAssessments() {
      await this.updateClientAssessments();
      this.dataTable.ajax.reload();
    },

    async showAssessmentDialog(item) {
      await this.$refs.assessmentAnswerDialog.show(item.id);
      // await this.updateClientAssessments();
      this.dataTable.ajax.reload();
    },

    async showSendReminderDialog(item) {
      this.$refs.sendReminderDialog.show(item.id);
    },

    async updateClientAssessments() {
      try {
        await this.$api.updateClientAssessments({ clientId: this.client.id });
      } catch (e) {
        // todo error catch and show error message
        console.log(e);
      }
    },

    async initializeDataTable() {
      const columns = [
        {
          name: 'action',
          data: 'status',
          searchable: false,
          orderable: false,
          render: (data, type, row, index) => {
            if (data === 'Completed') {
              return `
             <div class="actions">
                  <a class="name h-openassessment" href="javascript:void(0);" data-id="${index.row}">
                <i class="healenticons-path-2519" title="open assessment"></i>
                </a>
              </div>
          `;
            }
            return `
              <div class="actions">
                <a href="javascript:void(0);" class="reminder" data-id="${index.row}">
                  <i class="fas fa-bell text-info font-16" title="Send reminder"></i>
                </a>
                <a class="name h-openassessment" href="javascript:void(0);" data-id="${index.row}">
                <i class="healenticons-path-2519" title="open assessment"></i>
                </a>
              </div>
            `;
          },
        },
        {
          name: 'repeat_interval',
          data: 'cp_assesment',
          searchable: true,
          render: data => data.repeat_every_x_days,
        },
        /*         {
          name: 'name',
          data: 'cp_assesment',
          searchable: true,
          render: (data, type, row, index) => `
            <a class="name" href="javascript:void(0);" data-id="${index.row}">
              ${data.assesment.title}
            </a>
          `
        }, */
        {
          name: 'due_date',
          data: 'due_date',
          searchable: true,

          render: data => data || '-',
        },
        {
          name: 'submitted_on',
          data: 'submitted_on',
          searchable: true,

          render: data => data || '-',
        },
        {
          name: 'status',
          data: 'status',
          searchable: true,

          render: data => data || '-',
        },
        {
          name: 'score',
          data: 'score',
          searchable: true,

          render: data => (data !== null ? data : '-'),
        },
        {
          name: 'trend',
          data: 'trend',
          searchable: false,
          orderable: false,
          className: 'h-trend-assessment',
          render: () => '',
        },
      ];
      const that = this;
      this.dataTable = $(this.$refs.assessmentsTable).DataTable({
        processing: true,
        serverSide: true,
        paging: true,
        pageLength: 10,
        searching: true,
        ordering: true,
        bDestroy: true,
        order: [[3, 'desc']],
        columns,
        ajax: (data, callback, tableSettings) => {
          const column = data.columns[data.order[0].column].name;
          const order = `${data.order[0].dir === 'desc' ? '-' : ''}${column}`;
          const search = data.search.value;

          this.$api
            .getClientAssessments({
              client_id: this.client.id,
              assessment_id: this.assessmentId,
              start: data.start,
              length: data.length,
              order,
              search,
            })
            .then((response) => {
              tableSettings.json = response;
              callback(response);
            });
        },

        dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lipB>',
        buttons: {
          buttons: ['copy', 'excel', 'csv', 'print'],
        },

        drawCallback: () => this.drawTrendChart(),
      });

      this.$nextTick(() => {
        $(this.$refs.assessmentsTable).on('init.dt', () => {
          this.dataTable
            .buttons()
            .container()
            .appendTo(this.$refs.collectionDropdown);
        });
      });

      // document.querySelector('div.h-h3.h-primary_shade_1').textContent = 'Assessments'

      this.dataTable.on('click', 'a.name', ({ currentTarget }) => {
        const row = currentTarget.getAttribute('data-id');
        const rowData = this.dataTable.data()[row];
        this.showAssessmentDialog(rowData);
      });
      this.dataTable.on('click', 'a.reminder', ({ currentTarget }) => {
        const row = currentTarget.getAttribute('data-id');
        const rowData = this.dataTable.data()[row];
        this.showSendReminderDialog(rowData);
      });
    },

    drawTrendChart() {
      this.dataTable.rows().every((index) => {
        const rowData = this.dataTable.data()[index];
        const trendChartComponent = new Vue({
          render: h => h(SparklineChart, {
            props: {
              options: this.getTrendOptions(rowData),
            },
          }),
        });
        const cell = this.dataTable.cell(index, 6).node();
        trendChartComponent.$mount();
        cell.appendChild(trendChartComponent.$el);
        return '';
      });
    },

    getTrendOptions(item) {
      return {
        chart: {
          type: 'line',
          width: 80,
          height: 25,
        },
        series: [
          {
            data: item.trend.map(entry => entry[1]),
            name: item.cp_assesment.assesment.title,
            color: '#2b5adc',
            lineWidth: 2,
          },
        ],
        xAxis: {
          categories: item.trend.map(entry => entry[0]),
        },
      };
    },
  },

  async mounted() {
    await this.initializeDataTable();
    // await this.$api.getClientAssessmentsInsights({ client_id: this.client.id });
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
    $.fn.dataTable.ext.search.pop();
  },

  components: {
    SendReminderDialog,
    AssessmentAnswerDialog,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
    assessmentId: {
      type: Number,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    filterTitle: {
      type: String,
      default: '',
    },
  },
};
</script>
