<template>
  <div class="h-bg-gray">
    <div
      v-if="loadingOptions"
      id="headerBox"
      class="h-padding-left-l h-padding-right-l h-padding-bottom-l h-bg-w text-center text-muted"
      style="font-size: 1.5em"
    >
      Please wait...
    </div>
    <div v-if="!loadingOptions" class="h1-table h-margin-top-l h-padding-top-l h-bg-w">
      <table
        id="hospital-report-table"
        class="table dataTable TableReports dtr-inline collapsed"
        ref="table"
      >
        <thead class="thead-light">
          <tr>
            <th width="15%">Hospital</th>
            <th v-for="metric of metrics" :key="metric.name" class="text-right">
              {{ metric.display_name }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name
        }
      "
      :getOptionText="
        option => {
          return option.text
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
    <StartTaskDialog
      ref="startTaskDialog"
      reportCode="all"
      :month="getMonth"
      :hospitalId="this.hospitalId"
      v-on:startProcessing="startProcessing"
      v-on:endProcessing="endProcessing"
    />
  </div>
</template>

<style>
div.loading {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #2b5adc;
}
</style>

<script>
import moment from 'moment';
import StartTaskDialog from '../StartTaskDialog';
import settings from '@/settings.js';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';
import eventBus from '../../../../../event-bus';

const TABLE_COLUMNS_KEY = 'MonthlyReport_columnKeys';

export default {
  data() {
    return {
      loadingOptions: true,
      dataTable: null,
      allTableColumns: [],
      visibleTableColumns: [],
      errors: {},
      hospitals: [],
      hospitalId: null,
      metrics: [],
      reportDateStart: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      reportDateEnd: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
      skipEmptyReports: true,
      showOnlyEligible: false,
      msg: 'Report is being downloaded now',
      html: '<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>',
      downloadingReport: false,
      downloadingLongNumberOfReports: false,
      displayReportDownloadingMsgBox: false,
      openDownloadDialogToQueryForStats: false,
      longReportDownloadJobThreshold: 3,
      datatableProcessingDone: false,
      singleReportDownload: false,
      defaultDialogBoxTitle: 'Report is being generated',
      response: null,
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },
    getMonth() {
      if (this.reportDateStart) {
        return moment(this.reportDateStart).month() + 1;
      }
      return moment().month() + 1;
    },
  },

  watch: {
    hospitalId() {
      this.reload();
    },

    reportDateStart() {
      // this.reportDateEnd = moment(this.reportDateStart).endOf('month').format('YYYY-MM-DD');
      this.reload();
    },

    reportDateEnd() {
      this.reload();
    },

    showOnlyEligible() {
      this.reload();
    },
  },

  methods: {
    startProcessing() {
      this.datatableProcessingDone = false;
      this.dataTable.processing(true);
    },

    endProcessing() {
      this.datatableProcessingDone = true;
      this.reload();
    },

    reload() {
      this.dataTable.ajax.reload();
    },

    async downloadAllReports() {
      if (!this.datatableProcessingDone) {
        $.notify('Report list is still being populated.', {
          position: 'top center',
          className: 'error',
        });
        return;
      }

      if (this.downloadingReport) {
        $.notify('There is a download operation in progress.', {
          position: 'top center',
          className: 'error',
        });
        return;
      }

      const dateStart = this.reportDateStart;
      const dateEnd = this.reportDateEnd;
      const hospitalIdValue = this.hospitalId != null ? this.hospitalId : '';
      const surgeonIdValue = this.surgeonId != null ? this.surgeonId : '';

      const url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/progress-report`;
      const payload = {
        'date-start': dateStart,
        'date-end': dateEnd,
        ext: 'zip',
        'skip-empty': false,
        'download-in-background': this.longJobToDownload,
        'show-eligible': this.showOnlyEligible,
        'hospital-id': hospitalIdValue,
        'surgeon-id': surgeonIdValue,
        clientIds: this.clientIdsToIncludeInZipDownload,
      };
      this.defaultDialogBoxTitle = 'Report is being generated';
      this.displayReportDownloadingMsgBox = true;
      this.msg = 'Reports are being downloaded now.';
      this.singleReportDownload = false;

      if (this.longJobToDownload) {
        await this.$api.scheduleDownloadOrder(url, payload).then((response) => {
          if (response.status === 'error') {
            this.defaultDialogBoxTitle = 'There is already a report request to process';
          } else eventBus.$emit('refreshReportOrdersCounter');
        });
      } else {
        this.downloadingReport = true;
        this.$api.downloadFile(url, payload).then((response) => {
          eventBus.$emit('refreshReportOrdersCounter');
        });
      }
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },

    msgClose() {
      this.displayReportDownloadingMsgBox = false;
    },

    recalculateReport() {
      this.$refs.startTaskDialog.show();
    },

    reportPushStatusChanged(rowMetadata) {
      this.reload();
    },
  },

  async mounted() {
    const that = this;
    const [hospitals, metrics] = await Promise.all([
      this.$api.getHospitalsList(),
      this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/reports/monthly_hospital_metrics`),
    ]);
    this.metrics = metrics;
    this.hospitals = hospitals;
    this.loadingOptions = false;
    await this.$forceUpdate();

    this.dataTable = $('#hospital-report-table').DataTable({
      processing: true,
      serverSide: true,
      responsive: false,
      lengthMenu: [5, 10, 20, 50, 100, 200, 500],
      pageLength: 100,
      order: [[0, 'desc']],
      ajax: (data, callback, tableSettings) => {
        this.datatableProcessingDone = false;
        data.hospitalId = this.hospitalId;
        data.reportDateStart = moment(this.reportDateStart)
          .startOf('month')
          .format('YYYY-MM-DD');
        data.reportDateEnd = moment(this.reportDateEnd)
          .endOf('month')
          .format('YYYY-MM-DD');
        data.showOnlyEligible = this.showOnlyEligible;

        this.$api
          .post(`${settings.BACKEND_URL}/api/v-nurse/reports/monthly_hospital_summary`, null, data)
          .then((response) => {
            this.response = response;
            callback(response);
            this.datatableProcessingDone = true;
          });
      },
      dom:
        '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt"fB>>' +
        '<"h-row"<"h-col-desktop-12 h-col-mobile-12"tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
      buttons: {
        buttons: [
          {
            extend: 'collection',
            className: 'ExportIcon',
            buttons: ['copy', 'excel', 'csv', 'print'],
          },
          {
            text: 'Change columns',
            action(e, dt, node, config) {
              that.$refs.toggleColumnsDialog.show();
            },
            className: 'h-btn-icon',
          },
        ],
        dom: {
          button: {
            className: 'h-btn',
          },
        },
      },
      columns: [
        {
          name: 'hospital_name',
        },
        ...this.metrics.map((metric) => {
          return {
            name: metric.name,
            searchable: false,
            orderable: true,
            render: (data) => {
              if (data !== null) {
                const result = metric.unit ? data.toFixed(2) : data;
                if (metric.name.endsWith('::trend')) {
                  const sign = result > 0 ? '+' : '';
                  return `${sign}${result}%`;
                }
                return result;
              }
              return '-';
            },
          };
        }),
      ],
    });

    $('div.header-title').html(
      "<h3 class='h-h3 h-primary_shade_1'>Monthly hospital report<span id='iconExpend' class='ExpendIcon h-margin-left-s'></span></h3>",
    );
    $(document).ready(() => {
      $('#iconExpend').click(() => {
        $('#iconExpend').toggleClass('CollapseIcon');
        $('.TableReports').toggleClass('Expended');
        $('#headerInput').toggleClass('d-none');
        $('#headerBox').toggleClass('d-none');
      });
    });
    this.allTableColumns = this.dataTable.context[0].aoColumns.map((aoColumn, index) => {
      return {
        text: $(this.dataTable.column(index).header()).text(),
        name: aoColumn.name,
      };
    });
    this.visibleTableColumns = JSON.parse(localStorage.getItem(TABLE_COLUMNS_KEY) || '[]');
    if (this.visibleTableColumns.length === 0) {
      this.visibleTableColumns = this.allTableColumns.slice();
    } else {
      this.visibleTableColumns = this.allTableColumns.filter((columnExists) => {
        const keep = this.visibleTableColumns.find(
          columnVis => columnVis.name === columnExists.name,
        );
        if (!keep) {
          const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(columnExists));
          dtColumn.visible(false);
        }
        return keep;
      });
    }

    eventBus.$on(
      'downloadFileOperationEnded',
      (event) => {
        this.downloadingReport = false;
        this.displayReportDownloadingMsgBox = false;
      },
      this,
    );

    eventBus.$on(
      'hospitalSelectedChange',
      (event) => {
        this.hospitalId = event.hospitalId;
      },
      this,
    );

    eventBus.$on(
      'reportDateStartChanged',
      (event) => {
        const dateStart = moment(event.monthStart)
          .startOf('month')
          .format('YYYY-MM-DD');
        this.reportDateStart = dateStart;
      },
      this,
    );

    eventBus.$on(
      'reportDateEndChanged',
      (event) => {
        const dateEnd = moment(event.monthEnd)
          .endOf('month')
          .format('YYYY-MM-DD');
        this.reportDateEnd = dateEnd;
      },
      this,
    );
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },
  components: {
    SelectorDialogInDrawer,
    StartTaskDialog,
  },
};
</script>
