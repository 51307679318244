<template>
  <tbody>
    <tr>
      <td class="h-text-center">{{ index + 1 }}</td>

      <td class="position-relative">
        <input
          :id="inputId"
          class="h-input"
          placeholder="Type to search..."
          data-noresults-text="No codes found."
          autocomplete="off"
          multiple
        />
      </td>
      <td>
        <select v-model="diagnoseType" class="h-input">
          <option value="primary">Primary</option>
          <option value="secondary">Secondary</option>
        </select>
      </td>
      <td class="h-text-center">
        <i
          class="fas fa-trash-alt h-dangerous cursor-pointer"
          title="Remove"
          @click="$emit('remove', clientDiagnose)"
        ></i>
      </td>
    </tr>
  </tbody>
</template>

<script>
let lastId = 0;

export default {
  data() {
    console.log('this.clientDiagnose', this.clientDiagnose.diagnose);
    lastId += 1;
    return {
      lastId,
      diagnose: this.clientDiagnose.diagnose || null,
      diagnoseType: this.clientDiagnose.diagnose_type || 'primary',
    };
  },

  computed: {
    diagnoses() {
      if (!this.category) return [];
      return this.category.diagnoses;
    },

    inputId() {
      return `DiagnoseRow-${this.lastId}`;
    },

    inputIdSelector() {
      return `#${this.inputId}`;
    },
  },

  watch: {
    category(value) {
      this.diagnose = null;
      if (value) {
        this.$emit('categoryChange', {
          id: value.id,
          title: value.title,
        });
      } else {
        this.$emit('categoryChange', null);
      }
      this.$emit('diagnoseChange', this.diagnose);
    },

    diagnose(value) {
      this.$emit('diagnoseChange', value);
    },

    diagnoseType(value) {
      this.$emit('diagnoseTypeChange', value);
    },
  },

  methods: {
    get() {
      if (!this.diagnose) return null;
      return {
        category: this.category,
        diagnose: this.diagnose,
      };
    },
  },
  mounted() {
    const that = this;
    $(this.inputIdSelector).autoComplete({
      minLength: 2,
      events: {
        search(qry, callback, origJQElement) {
          that.$api.searchICD({ search: qry }).then((result) => {
            result.diagnose_options.forEach((entry) => {
              entry.text = entry.title;
            });
            callback(result.diagnose_options);
          });
        },
      },
    });
    // initial value
    if (this.diagnose) {
      $(this.inputIdSelector).autoComplete('set', this.diagnose.title);
    }
    $(this.inputIdSelector).on('autocomplete.select', (evt, item) => {
      this.diagnose = item;
    });
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    clientDiagnose: {
      type: Object,
    },
  },
};
</script>
