<template>
  <div class="h-drawer-max79 DayMealsEditor">
    <SlottedMessageInDrawer
      ref="slottedMessageInDrawer"
      title="Day meals editor"
      :closeDisabled="loading"
    >
      <template v-slot:modal-body>
        <div v-if="!loading" class="loadedModalBody mx-2">
          <div class="h-h4 h-primary_shade_1 h-margin-bottom-l">
            <strong>{{ freeMealData.meal_type }} ({{ freeMealData.date }})</strong>
          </div>
          <label class="h-label"> Free text: </label>

          <InputField type="textarea" v-model="newContent" :rows="12" />
        </div>

        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <div class="h-align-items-center h-space-between h-margin-right-xl">
          <div>
            <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
              <span v-if="freeMealData.content">Update</span>
              <span v-else>Create</span>
            </button>

            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-l"
              @click="test"
              :disabled="loading"
            >
              Close
            </button>
          </div>
          <div>
            <button
              v-if="freeMealData.content"
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-l"
              @click="remove"
              :disabled="loading"
              title="Remove note"
            >
              Remove
            </button>
          </div>
        </div>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      freeMealData: {},
      newContent: '',
    };
  },

  methods: {
    show(freeMealData) {
      this.loading = false;
      this.freeMealData = freeMealData;
      this.newContent = this.freeMealData.content;
      this.$refs.slottedMessageInDrawer.show();
    },

    close(force) {
      this.$refs.slottedMessageInDrawer.close(force);
    },
    async test() {
      this.close(true);
    },
    async save() {
      this.loading = true;
      const payload = { ...this.freeMealData };
      payload.content = this.newContent;
      const response = await this.$api.saveDietFreeMeal(payload);
      this.$emit('freeMealPlanUpdated', payload, response.free_mean_plan);
      this.close(true);
    },

    async remove() {
      this.newContent = '';
      await this.save();
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>
