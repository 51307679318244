<template>
  <div class="patient-profile">
    <Loader v-if="loading" message="Loading ..." />
    <div class="h-box" v-if="!loading">
      <div class="h-left-box h-mobile-hide">
        <div class="h-appointments-card h-bg-w">
          <AppointmentsDrawer ref="AppointmentDetailsDrawer" />
          <PatientDetailsAppointments :client="client" :devEnv="running_env" />
        </div>
      </div>
      <div class="h-right-box">
        <div v-if="copyCurrentTab === 'tabMonitoring'">
          <div class="h-d-flex h-space-between h-align-items-center h-h5">
            <div class="h-d-flex h-space-between align-items-end flex-1">
              <h3 class="h-h3 h-primary_shade_1 monitoring">Monitoring</h3>
            </div>
            <HealthStatusSelector :holder="client" @itemsUpdated="healthStatusItemsUpdated" />
          </div>
          <hr class="h-hr h-margin-bottom-l" />
          <div
            v-if="isDevEnvironment"
            class="h-bg-w h-margin-top-l h-margin-bottom-l h-padding-l h-d-none"
          >
            <h3 class="h-h3 h-accent h-bold">{{ this.client.name }} is getting better!</h3>
            <h5 class="h-h5 h-primary_shade_1 h-margin-top-s">
              According to the overall readings
              <span class="TimeValue">last 30 days</span>, {{ this.client.name }} is getting better.
            </h5>
          </div>
          <div v-if="isDevEnvironment" class="h-margin-bottom-l h-charttoggler h-d-none">
            <div class="h-analytic-card">
              <h3 class="h-accent h-h3 h-bold h-margin-bottom-l">Biggest changes</h3>
              <ol>
                <li>
                  <div class="h-space-between h-d-flex">
                    <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">Blood Sugar</h4>
                    <h4 class="h-h4 h-accent">
                      <i class="fas fa-caret-down h-margin-right-s"></i>30.28%
                    </h4>
                  </div>
                </li>
                <li>
                  <div class="h-space-between h-d-flex">
                    <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">Pulse rate</h4>
                    <h4 class="h-h4 h-accent">
                      <i class="fas fa-caret-up h-margin-right-s"></i>28.24%
                    </h4>
                  </div>
                </li>
                <li>
                  <div class="h-space-between h-d-flex">
                    <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">Weight</h4>
                    <h4 class="h-h4 h-accent">
                      <i class="fas fa-caret-up h-margin-right-s"></i>22.4%
                    </h4>
                  </div>
                </li>
              </ol>
            </div>
            <div class="h-analytic-card">
              <h3 class="h-dangerous h-h3 h-bold h-margin-bottom-l">Most critical items</h3>
              <ol>
                <li>
                  <div class="h-space-between h-d-flex">
                    <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">Pain score</h4>
                    <h4 class="h-h4 h-dangerous">9</h4>
                  </div>
                </li>
                <li>
                  <div class="h-space-between h-d-flex">
                    <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">Blood Pressure (mmHg)</h4>
                    <h4 class="h-h4 h-dangerous">80</h4>
                  </div>
                </li>
                <li>
                  <div class="h-space-between h-d-flex">
                    <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">Breathing Difficulty</h4>
                    <h4 class="h-h4 h-dangerous">9</h4>
                  </div>
                </li>
              </ol>
            </div>
            <div class="h-analytic-card">
              <h3 class="h-accent h-h3 h-bold h-margin-bottom-l">Recommended actions</h3>
              <ol>
                <li>
                  <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">
                    Assign daily back pain exercises
                  </h4>
                </li>
                <li>
                  <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">
                    Decrease pain killer strength
                  </h4>
                </li>
                <li>
                  <h4 class="h-h4 h-primary_shade_1 h-margin-left-xs">
                    Assign daily breathing exercise
                  </h4>
                </li>
              </ol>
            </div>
          </div>
          <HealentScore :client="client" />
          <div v-bind:class="[client.monitoring_program === 'CCM' ? 'h-margin-bottom-l' : '']">
            <div v-if="client.care_protocol">
              <AssessmentClientCards :client="client" :canEdit="canEditAssessments" />
            </div>
          </div>

          <div v-if="client.monitoring_program === 'CCM' && canEditAssessments">
            <div class="h-bg-w h-scroll h-margin-bottom-l">
              <Microprotocols ref="microprotocols" :clientId="client.id" />
            </div>
            <div class="h-bg-w">
              <MedicationDoses :client="client" />
            </div>
          </div>
          <!--<Goals :client="client" />-->
        </div>

        <div v-if="copyCurrentTab === 'tabPlanning'">
          <div class="h-d-flex h-space-between h-align-items-center h-h5">
            <h3 class="h-h3 h-primary_shade_1">Planning</h3>
          </div>
          <div class="h-margin-top-s h-margin-bottom-l">
            <hr class="h-hr" />
          </div>
          <!-- <div class="h-margin-top-s h-margin-bottom-l">
            <button
            type="button"
            class="h-btn h-btn-accent"
            @click="sendConsentForms"
          >
            Send consent forms to athena
          </button>
          </div> -->
          <div class="h-bg-w h-margin-bottom-l">
            <CareProtocol :client="client" />
          </div>
          <div class="h-bg-w h-margin-bottom-l">
            <ClientCPAssessment :client="client" />
          </div>
          <div class="h-bg-w h-margin-bottom-l" v-if="client.care_protocol">
            <div class="h-d-flex h-align-items-center h-padding-left-l h-padding-top-l">
              <h5 class="h-h3 h-primary_shade_1">
                Thought of the Day
              </h5>
              <span class="h-h4 h-secondary_shade_1 h-padding-left-s">
                (care protocol: {{ client.care_protocol.name }})
              </span>
            </div>
            <ThoughtOfTheDay :clientId="client.id" :client="client" />
          </div>
          <div class="h-bg-w h-padding-top-l" style="position: relative">
            <div
              class="h-report-table-title w-75 h-d-flex h-align-items-center h-padding-left-l w-75"
            >
              <h5 class="h-h3 h-primary_shade_1">
                Therapy PT
              </h5>
              <span class="h-h4 h-secondary_shade_1 h-padding-left-s">
                (care protocol: {{ client.care_protocol.name }})
              </span>
            </div>
            <TherapyPt :clientId="client.id" />
          </div>

          <br />
          <div class="h-bg-w">
            <MedicationUse :client="client" :editMode="true" />
          </div>
        </div>

        <div v-if="copyCurrentTab === 'tabDiet'">
          <div class="h-row h-space-between h-align-items-center h-h5">
            <h3 class="h-h3 h-primary_shade_1">Diet</h3>
          </div>
          <div class="h-margin-top-s h-margin-bottom-l">
            <hr class="h-hr" />
          </div>

          <div class="h-diet h-bg-w h-padding-top-l h-margin-bottom-l">
            <DietOverview
              :client="client"
              :dietData="dietData"
              :loading="loading"
              @loadClientDietData="loadClientDietData"
              @setDietData="setDietData"
            />
          </div>
          <div class="h-bg-w h-padding-top-l h-padding-bottom-l">
            <h5 class="h-h3 h-primary_shade_1 h-padding-left-l">Diet restrictions</h5>
            <DietRestrictions
              ref="dietRestrictions"
              :client="client"
              :dietData="dietData"
              :loading="loading"
              @setDietData="setDietData"
            />
          </div>
        </div>

        <div v-if="copyCurrentTab === 'tabMessages'">
          <div class="h-row h-space-between h-align-items-center h-h5">
            <h3 class="h-h3 h-primary_shade_1">Messages</h3>
          </div>
          <div class="h-margin-top-s">
            <hr class="h-hr" />
          </div>
          <div class="patientChat">
            <ChatDisplay ref="chatDisplay" :room="selectedRoom" :mode="mode" />
          </div>
        </div>

        <div v-if="copyCurrentTab === 'tabPatientLog'">
          <PatientLog ref="Patient log" :client="client" :initialFilters.sync="logFilters" />
        </div>

        <div v-if="copyCurrentTab === 'tabAdditionalInfo'" class="h-AdditionalInfo">
          <SectionGeneral :client="client" />
          <SurgeryInfo :client="client" />
          <IntakeInfo :client="client" />
          <Documents :client="client" />
          <CommunicationNotes :client="client" />
          <FormData :client="client" />
        </div>

        <div ref="sectionGeneralDivScroll" />
      </div>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';
import moment from 'moment-timezone';
import Loader from '@/components/Loader';
import SectionGeneral from './components/section_general/SectionGeneral';
import HealentScore from './components/health_status_charts/HealentScore';
import CommunicationNotes from './components/communication_notes/CommunicationNotes';
import FormData from './components/form_data/FormData';
import CareProtocol from './components/care_protocol/CareProtocol';
import Documents from './components/documents/Documents';
import TherapyPt from '@/components/therapy_pt/TherapyPt';
import ThoughtOfTheDay from '@/components/thought_of_the_day/ThoughtOfTheDay';
import PatientDetailsAppointments from '@/components/appointments/PatientDetailsAppointments';
import AppointmentsDrawer from '@/components/appointments_drawer/AppointmentsDrawer';
import MedicationUse from './components/section_general/medication_use/MedicationUse';
import MedicationDoses from './components/section_general/medication_doses/MedicationDoses';
import AssessmentClientCards from '@/components/assesment_client/AssessmentClientCards';

import DietOverview from './components/section_diet/diet_overview/DietOverview';
import DietRestrictions from './components/section_diet/diet_restricitions/DietRestrictions';
import PatientLog from './components/patient_log/PatientLog';
import HealthStatusSelector from '@/components/health_status/HealthStatusSelector';
import eventBus from '@/event-bus';
import Microprotocols from './components/section_general/microprotocols/Microprotocols';
import ChatDisplay from '../chat/components/ChatDisplay';
import settings from '@/settings.js';
import SurgeryInfo from './components/surgery_info/SurgeryInfo';
import ClientCPAssessment from '@/components/client_cp_assessment/ClientCPAssessment';
import IntakeInfo from './components/intake_info/IntakeInfo';
// import Goals from './components/goals/Goals';
import { PATIENT_TABS } from '@/utils/constants';

export default {
  name: 'PatientProfile',
  props: {
    client: {
      type: Object,
      required: true,
    },
    currentTab: String,
  },

  data() {
    return {
      copyCurrentTab: this.currentTab,
      socket: null,
      pageContentEl: null,
      pageContentElFirstDiv: null,
      firstTimeVisit: false,
      rooms: null,
      selectedRoom: null,
      running_env: null,
      dietData: null,
      loading: true,
      appointment_being_edited: false,
      mode: window.innerWidth > 600 ? 'normal' : 'small',
      logFilters: {
        dateStart: moment()
          .subtract(50000, 'days')
          .format(),
        dateEnd: moment().format(),
        alertStatus: 'active',
        category: 'Follow-up Required',
        clientId: this.client.id,
        topic: '',
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    if (this.appointment_being_edited) {
      eventBus.$emit('confirmAppointmentOutstandingChangesSave', { title: 'Navigation' });
      next(false);
    } else {
      next();
    }
  },

  async mounted() {
    if (!this.client.id) return;

    await this.getRunningEnv();
    await this.getActivePatientLogs();
    await this.loadClientDietData();
    if (this.$route.query.roomId) {
      eventBus.$emit('openTabMessages', {});
      await this.configureSocket();
    }
    if (this.copyCurrentTab === 'tabMessages') {
      await this.configureSocket();
    }
    eventBus.$on(
      'tabSelectedInTopBar',
      async (event) => {
        this.copyCurrentTab = event.newTab;
        if (this.copyCurrentTab === 'tabMessages') {
          await this.configureSocket();
        }
      },
      this,
    );

    eventBus.$on(
      'uncompleted-appointment-edit-begin',
      () => {
        this.appointment_being_edited = true;
      },
      this,
    );

    eventBus.$on(
      'uncompleted-appointment-edit-stop',
      () => {
        this.appointment_being_edited = false;
      },
      this,
    );
    eventBus.$on(
      'profile-closed',
      () => {
        if (this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
      },
      this,
    );
    eventBus.$on(
      'profile-drawer-closed',
      () => {
        if (this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
      },
      this,
    );
    eventBus.$on(
      'openTabMessages',
      (event) => {
        this.copyCurrentTab = PATIENT_TABS[3].value;
        localStorage.lastSelectedTabInPatientProfile = this.copyCurrentTab;
      },
      this,
    );
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
  },

  computed: {
    isDevEnvironment() {
      return this.running_env.environment === 'development';
    },

    canEditAssessments() {
      return (
        settings.hasRole('system_administrator') ||
        settings.hasRole('nurse_administrator') ||
        settings.hasRole('nurse')
      );
    },
  },

  methods: {
    async loadClientDietData() {
      this.loading = true;
      try {
        const { diet_data: dietData } = await this.$api.getClientDietData(this.client.id);
        this.setDietData(dietData);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async getActivePatientLogs() {
      try {
        const { count } = await this.$api.getCountActivePatientLogs(this.client.id);
        this.$emit('activityCount', count);
      } catch (e) {
        this.$emit('activityCount', 0);
      }
    },

    async getRunningEnv() {
      try {
        this.running_env = await this.$api.getRunningEnviroment();
      } catch (e) {
        this.running_env = null;
      }
    },

    async sendConsentForms() {
      const payload = {
        clientId: this.client.id,
      };

      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/client/send_consent_forms`,
        null,
        payload,
      );
    },

    setDietData(dietData) {
      this.dietData = dietData;
    },

    async configureSocket() {
      this.socket = io(`${settings.BACKEND_URL}/web-socket/chat`, { withCredentials: true });
      this.socket.on('connected', async (data) => {
        if (!this.rooms) {
          this.rooms = [];
        }
        data.rooms.forEach((room) => {
          this.addRoom(room);
        });
        this.sortRooms();

        if (this.$refs.chatDisplay) {
          this.$refs.chatDisplay.setSocket(this.socket);
        }

        let roomQuery = this.rooms.filter((room) => {
          return room.client_id === this.client.id;
        });

        if (roomQuery[0] == null) {
          const result = await this.$api.searchChatRoom(this.client.name); // .then((result) => {
          roomQuery = result.filter((room) => {
            return room.client_id === this.client.id;
          });
        }

        this.selectedRoom = roomQuery[0];

        this.roomSelected(this.selectedRoom);

        this.socket.emit('room_selected', {
          room: this.selectedRoom,
        });
        this.socket.emit('room_messages_seen', {
          room: this.selectedRoom,
        });
      });
    },

    async openAppointmentDetailsDrawer(meetingId) {
      if (this.$refs.AppointmentDetailsDrawer.active) {
        this.$refs.AppointmentDetailsDrawer.close();
      } else {
        await this.$refs.AppointmentDetailsDrawer.open(meetingId);
      }
    },

    healthStatusItemsUpdated() {},

    reloadMicroprotocols() {
      this.$refs.microprotocols.load();
    },
    roomSelected(room) {
      if (this.selectedRoom && room) {
        if (this.selectedRoom.id === room.id) {
          return;
        }
      }
      this.selectedRoom = room;
    },
    addRoom(room, moveUp) {
      if (!this.rooms) {
        this.rooms = [room];
        return;
      }

      const existingRoom = this.rooms.find((entry) => {
        return entry.id === room.id;
      });
      if (existingRoom) {
        existingRoom.hasUnseenMessages = true;
        existingRoom.latestMessageReceivedAt = room.latestMessageReceivedAt;

        if (moveUp && (!this.selectedRoom || existingRoom.id !== this.selectedRoom.id)) {
          const index = this.rooms.indexOf(existingRoom);
          this.rooms.splice(index, 1);
          this.rooms.unshift(existingRoom);
        }
        this.sortRooms();
        return;
      }

      this.rooms.unshift(room);
      this.sortRooms();
    },
    sortRooms() {
      const sortByLastMessage = (a, b, defaultSort) => {
        if (a.latestMessageReceivedAt && !b.latestMessageReceivedAt) {
          return -1;
        }
        if (!a.latestMessageReceivedAt && b.latestMessageReceivedAt) {
          return 1;
        }
        if (a.latestMessageReceivedAt && b.latestMessageReceivedAt) {
          if (a.latestMessageReceivedAt > b.latestMessageReceivedAt) {
            return -1;
          }
          if (a.latestMessageReceivedAt < b.latestMessageReceivedAt) {
            return 1;
          }
        }
        return defaultSort || 0;
      };

      this.rooms.sort((a, b) => {
        if (a.hasUnseenMessages && !b.hasUnseenMessages) {
          return -1;
        }
        if (!a.hasUnseenMessages && b.hasUnseenMessages) {
          return 1;
        }

        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
          return sortByLastMessage(a, b, 1);
        }
        if (b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) {
          return sortByLastMessage(a, b, -1);
        }
        return 0;
      });
    },
  },

  components: {
    IntakeInfo,
    SurgeryInfo,
    Loader,
    HealentScore,
    CommunicationNotes,
    SectionGeneral,
    CareProtocol,
    TherapyPt,
    ThoughtOfTheDay,
    PatientDetailsAppointments,
    Documents,
    AppointmentsDrawer,
    MedicationUse,
    DietOverview,
    DietRestrictions,
    HealthStatusSelector,
    MedicationDoses,
    Microprotocols,
    ChatDisplay,
    PatientLog,
    ClientCPAssessment,
    AssessmentClientCards,
    FormData,
    // Goals,
  },
};
</script>
