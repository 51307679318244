<template>
  <div>
    <MealPlansGrid
      :client="client"
      :mealPlans="mealPlans"
      :freeMealPlans="freeMealPlans"
      :notes="notes"
      :dietMode="dietMode"
      :loading="gridLoading"
      :diet="diet"
      :recepies="recepies"
      @recipeSaved="$emit('loadClientDietData')"
      @freeMealPlanUpdated="freeMealPlanUpdated"
      @dietModeChanged="dietModeChanged"
      @generateMealPlan="generateMealPlan"
    />

    <Loader v-if="loading" />

    <GenerateMealPlanDialog
      v-if="dietMode === 'recipe'"
      ref="generateMealPlanDialog"
      :client="client"
      @mealPlanGenerated="mealPlanGenerated"
    />
  </div>
</template>


<script>
import Loader from '@/components/Loader';
import MealPlansGrid from './meal_plan_grid/MealPlansGrid';
import GenerateMealPlanDialog from './GenerateMealPlanDialog';

export default {
  data() {
    return {
      loaded: false,
      gridLoading: false,
      diet: null,
      mealPlans: null,
      notes: [],
      freeMealPlans: [],
      recepies: {},
      dietMode: null,
    };
  },
  mounted() {
    if (this.dietData) {
      this.setDietData(this.dietData);
    }
  },
  watch: {
    dietData(value) {
      if (value) {
        this.setDietData(value);
      }
    },
  },

  methods: {
    mealPlanGenerated(plans, notes) {
      this.mealPlans = plans;
      this.notes = notes;
    },

    generateMealPlan(startDate) {
      this.$refs.generateMealPlanDialog.show(startDate);
    },

    freeMealPlanUpdated(posted, recieved) {
      if (!recieved) {
        if (posted.id) {
          const existingPosted = this.freeMealPlans.find((mealPlan) => {
            return mealPlan.id === posted.id;
          });
          this.freeMealPlans.splice(
            this.freeMealPlans.indexOf(existingPosted),
            1,
          );
        }
        return;
      }
      const existingRecieved = this.freeMealPlans.find((mealPlan) => {
        return mealPlan.id === recieved.id;
      });
      if (existingRecieved) {
        this.freeMealPlans.splice(
          this.freeMealPlans.indexOf(existingRecieved),
          1,
        );
      }
      this.freeMealPlans.push(recieved);
    },

    setDietData(dietData) {
      this.mealPlans = dietData.plans;
      this.notes = dietData.notes;
      this.freeMealPlans = dietData.free_plans;
      this.diet = dietData.diet;
      this.dietMode = dietData.diet.diet_mode;
      this.recepies = dietData.recipes;
      this.loaded = true;
    },

    async dietModeChanged(dietMode) {
      this.gridLoading = true;
      const response = await this.$api.updateClientDietData(this.client.id, {
        diet: {
          diet_mode: dietMode,
        },
      });
      this.$emit('setDietData', response.diet_data);
      this.gridLoading = false;
    },
  },

  components: {
    MealPlansGrid,
    Loader,
    GenerateMealPlanDialog,
  },

  props: {
    client: {
      type: Object,
    },
    dietData: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
