<template>
  <div>
    <h3
      class="
        h-text-center h-h3
        h-primary_shade_1
        h-margin-top-l h-margin-bottom-l
      "
    >
      Daily
    </h3>
    <PerMeal
      ref="daySectionA"
      title="Macronutrients for the day"
      mealPrefix="day"
      mealName="Day"
      :dietData="dietData"
      :nutrientTypes="['Calories', 'Carbohydrates', 'Protein', 'Fat']"
      :forceEnabled="true"
      :percent="true"
    />
    <PerMeal
      ref="daySectionB"
      title="Other Nutrients for the day"
      mealPrefix="day"
      mealName="Day"
      :dietData="dietData"
      :nutrientTypes="['Fiber', 'Sugar', 'Sodium', 'Potassium', 'Phosphorus']"
      :forceEnabledNutrientTypes="[]"
      :forceEnabled="true"
    />

    <h3 class="h-text-center h-h3 h-primary_shade_1 h-margin-top-xl">
      Per meal
    </h3>
    <div v-for="mealInfo in mealInfos" :key="mealInfo[0]">
      <PerMeal
        ref="mealSections"
        :mealPrefix="mealInfo[1]"
        :mealName="mealInfo[0]"
        :dietData="dietData"
      />
    </div>
    <InputField
      id="DietRestricitons-showNutrients"
      type="checkbox"
      checkboxSwitchType="success"
      v-model="showNutrients"
      class="d-inline-block h-margin-top-l h-margin-left-l h-margin-right-l"
      label="Show nutrients"
    />
    <div class="text-center h-margin-top-l">
      <button class="h-btn h-btn-accent" @click="save">
        Save diet restrictions
      </button>
    </div>

    <GlobalWait ref="globalWait" title="Saving diet restrictions..." />
    <Loader v-if="loading" />
  </div>
</template>


<script>
import Loader from '@/components/Loader';
import GlobalWait from '@/components/GlobalWait';
import PerMeal from './per_meal/PerMeal';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      mealInfos: [
        ['Breakfast', 'bkf'],
        ['Morning Snack', 'mns'],
        ['Lunch', 'lch'],
        ['Evening Snack', 'evs'],
        ['Dinner', 'dnr'],
      ],
      showNutrients: this.client.show_nutrients,
    };
  },

  computed: {
    sectionComponents() {
      return [this.$refs.daySectionA, this.$refs.daySectionB].concat(
        this.$refs.mealSections,
      );
    },
  },

  methods: {
    reset() {
      this.showNutrients = this.client.show_nutrients;
      this.sectionComponents.forEach((section) => {
        section.reset();
      });
    },

    async save() {
      this.$refs.globalWait.start();
      let diet = {};

      this.sectionComponents.forEach((section) => {
        diet = Object.assign(diet, section.grabData());
        section.setErrors({});
      });

      const response = await this.$api.updateClientDietData(this.client.id, {
        diet,
        client: {
          show_nutrients: this.showNutrients,
        },
      });
      this.$refs.globalWait.stop();
      if (response.status === 'error') {
        this.sectionComponents.forEach((section) => {
          section.setErrors(response.errors);
        });
        $.notify('Saving failed. View errors on the page.', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.client.show_nutrients = this.showNutrients;
      this.$emit('setDietData', response.diet_data);
      $.notify('Diet restrictions saved.', {
        position: 'top center',
        className: 'success',
      });
    },
  },

  components: {
    Loader,
    GlobalWait,
    PerMeal,
    InputField,
  },

  props: {
    client: {
      type: Object,
    },
    dietData: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
