import axios from 'axios';
import router from '../router';
import settings from '@/settings.js';

export default class {
  constructor() {
    this.http = axios.create({
      timeout: 100000,
      withCredentials: true,
      headers: {
        'Healent-UtcTimeShift-TotalMinutes': (new Date()).getTimezoneOffset(),
      },
    });
    this.http.interceptors.response.use(response => response, (error) => {
      let message = error.response?.statusText;
      const object = error.response?.data || {};
      const data = {};
      if ('error' in object) {
        message = object.error;
      } else if ('validation_error' in object) {
        const validationError = object.validation_error;
        if ('body_params' in validationError) {
          const e = validationError.body_params;
          message = `${e[0].msg} for ${e[0].loc[0]}`;
          e.forEach((i) => {
            data[i.loc[0]] = i.msg;
          });
        }
      }
      const err = {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        validate: data,
        message,
      };
      return Promise.reject(err);
    });
  }

  async get(url, params, cancelToken) {
    return (await this.request('get', url, params, null, cancelToken)).data;
  }

  async patch(url, params, data, cancelToken) {
    return (await this.request('patch', url, params, data, cancelToken)).data;
  }

  async post(url, params, data, cancelToken) {
    return (await this.request('post', url, params, data, cancelToken)).data;
  }

  async postMultipart(url, params, data, cancelToken) {
    const formData = new FormData();
    const jsonData = {};
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        jsonData[key] = data[key];
      }
    });
    formData.append('json', JSON.stringify(jsonData));
    return (await this.request('post', url, params, formData, cancelToken)).data;
  }

  async delete(url, params, data, cancelToken) {
    return (await this.request('delete', url, params, data, cancelToken)).data;
  }

  async request(method, url, params, data, cancelToken) {
    if (settings.forceCognito && (settings.env !== 'local')) {
      if (!this.authInfo?.IdToken) {
        const path = window.location.href.split('/').pop();
        if (path !== 'login') {
          router.push({
            name: 'Login',
          });
          return {};
        }
      }
    }

    const result = await this.http.request({
      url,
      method: method || 'get',
      data: data || {},
      params: params || {},
      cancelToken: (cancelToken || this.makeToken()).token,
    });
    if (result.status === 401) {
      router.push({
        name: 'Login',
      });
    }
    return result;
  }

  // eslint-disable-next-line
  makeToken() {
    return axios.CancelToken.source();
  }
}
