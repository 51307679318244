<template>
  <div ref="wrapper" class="h-h100">
    <div v-if="!client" class="text-center mb-2 mt-2">
      <DateSelector @dateTimeChanged="dateTimeChanged" />
    </div>
    <div
      ref="tableView"
      :class="{ 'd-none': !appointmentListView && !loading }"
      class="h1-table h-h100"
    >
      <div
        class="
          h-d-flex
          h-space-between
          h-align-items-center
          h-margin-right-l
          h-margin-left-l
          h-padding-top-l"
      >
        <div
          class="h-d-flex h-appointment_title
"
        >
          <h3 class="h-h3 h-primary_shade_1 h-padding-right-xs">
            Appointments
          </h3>
          <h4 class="h-h4  h-primary_shade_1 h-line-height-1-5">
            (Total this month: {{ accumulatedTime }} mins)
          </h4>
        </div>

        <div class="appointment-details-icon">
          <img
            src="../../../public/assets/images/refresh.svg"
            class="h-bar-icon"
            data-tooltip="Refresh appointment list"
            @click="refresh"
          />

          <img
            src="../../../public/assets/images/zoom.svg"
            class="h-bar-icon"
            data-tooltip="Zoom call"
            @click="launchZoomCall(client.phone)"
          />
          <img
            src="../../../public/assets/images/support.svg"
            class="h-bar-icon"
            data-tooltip="New Tech Call Appointment"
            @click="newAppointment(false)"
          />
          <img
            src="../../../public/assets/images/notes.svg"
            class="h-bar-icon"
            data-tooltip="All Appointments Notes"
            v-if="appointmentsCount > 0"
            @click="allAppointmentsNotes"
          />
          <img
            src="../../../public/assets/images/newapp.svg"
            class="h-bar-icon"
            data-tooltip="New appointment"
            @click="newAppointment(false, 'patient_call')"
          />
        </div>
      </div>

      <table id="PatientAppointments" class="table dataTable" ref="table">
        <thead class="thead-light">
          <tr>
            <th v-if="!client" style="width: 5%" class="text-left">
              <span class="table-title-bold">Patient</span>
            </th>
            <th v-if="!client" style="width: 5%" class="text-left">
              <span class="table-title-bold">Email</span>
            </th>
            <th style="width: 10%" class="text-left">
              <span class="table-title-bold">Type</span>
            </th>
            <th v-if="client" style="width: 20%" class="text-right">
              <span class="table-title-bold">Date</span>
            </th>
            <th style="width: 15%" class="text-right">
              <span class="table-title-bold">Time</span>
            </th>
            <th style="width: 10%" class="text-left">
              <span class="table-title-bold">With</span>
            </th>
            <th style="width: 15%" class="text-right">
              <span class="table-title-bold">Time spent</span>
            </th>
            <th style="width: 10%" class="text-left">
              <span class="table-title-bold">Completed</span>
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div
      ref="appointmentDetailsView"
      :class="{
        'd-none': appointmentListView && !loading,
        'h-automatic-appointment':
          this.appointmentType === 'Tech call' || this.appointmentType === 'Automatic'
      }"
      class="h-patient-appointment position-relative h-h100"
    >
      <div class="h-margin-left-l h-margin-right-l h-padding-top-l">
        <div
          class="h-col-desktop-12 h-col-mobile-12 h-margin-bottom-m"
          :class="{
            'd-none': client || modeEdit
          }"
        >
          <label class="h-label">Patient: </label>
          <select
            id="newAppointmentDialog-searchClient"
            :class="{ 'is-invalid': errors.client }"
            class="h-input"
            placeholder="Type to search..."
            data-noresults-text="No patients found."
            autocomplete="off"
          />
          <div class="invalid-feedback" v-if="errors.client">
            {{ errors.client }}
          </div>
        </div>
        <div class="h-margin-bottom-s h-d-flex h-align-item-center h-space-between w-100">
          <h3 v-if="appointment.is_completed" class="h-h3 h-primary_shade_1">
            {{ appointmentType }}
          </h3>
          <div
            class="h-d-flex h-align-item-center h-space-between"
            :class="{ 'w-100': !appointment.is_completed }"
          >
            <h4 v-if="!appointment.is_completed" class="h-h4  h-primary_shade_1 h-line-height-1-5">
              Time spent:
            </h4>
            <h4 v-if="!appointment.is_completed" class="h-h4  h-primary_shade_1 h-line-height-1-5">
              This month: {{ accumulatedTime }} mins
            </h4>
            <div class="h-d-flex">
              <h4 class="h-h4 h-accent" v-if="shouldCounterBeVisible">
                Now: {{ formattedTimerValue }}
              </h4>
              <i
                style="font-size: 10rem"
                class="fa fa-file h-cursor-pointer h-accent h-padding-left-s"
                data-tooltip="All Appointments Notes"
                v-if="appointmentsCount > 0"
                @click="allAppointmentsNotes"
              />
            </div>
          </div>
        </div>
        <div class="h-margin-bottom-xm h-custom-checkbox h-d-flex" v-if="!appointment.is_completed">
          <div class="h-fix-checkbox radiochecked" v-for="(option, index) in options" :key="index">
            <div class="h-custom-radio h-padding-right-m">
              <div class="h-custom-checkbox">
                <input
                  type="radio"
                  :value="option"
                  class="custom-control-input"
                  v-model="appointmentType"
                />
                <label for="female" class="custom-control-label cursor-pointer">{{ option }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="h-row h-appointment-input-bar">
          <div class="h-col-desktop-3 h-col-mobile-3 h-border-right-0 h-input20 h-margin-bottom-m">
            <label class="h-label">Start date</label>
            <InputField
              type="date"
              :disabled="!shouldCounterBeVisible"
              v-model="appointment.start_date"
              :error="errors.start_date"
              @change="errors.start_date = null"
            />
          </div>
          <div class="h-col-desktop-3 h-col-mobile-3 h-border-left-0 h-border-right-0 h-input20">
            <label class="h-label">Start time</label>
            <InputField
              type="time"
              :disabled="!shouldCounterBeVisible"
              v-model="appointment.start_time"
              :error="errors.start_time"
              @change="errors.start_time = null"
            />
          </div>
          <div class="h-col-desktop-3 h-col-mobile-3 h-border-left-0 h-border-right-0 h-input20">
            <label class="h-label"
              >Duration
              <!-- <span class="h-h6">(mins)</span> --></label
            >
            <InputField
              type="number"
              :disabled="!shouldCounterBeVisible"
              v-model="appointment.planned_duration"
              :error="errors.planned_duration"
              @change="errors.planned_duration = null"
              @input="validateInput"
              pattern="[0-9]*"
            />
          </div>
          <div class="h-col-desktop-3 h-col-mobile-3 h-input20 h-margin-bottom-m h-border-left-0">
            <label class="h-label"
              >Time spent
              <!-- <span class="h-h6">(mins)</span> --></label
            >
            <InputField
              type="number"
              v-model="timeSpentValue"
              @keyup="timeSpentUpdated"
              :disabled="isTimeSpentEditingDisabled"
              @input="validateInput"
              pattern="[0-9]*"
            />
          </div>
        </div>
        <div
          v-if="shouldCounterBeVisible"
          :class="{ notAutoAppointment: !options.includes(appointmentType) }"
        >
          <!-- modal -->
          <h4 v-if="!loading" class="h-h4 h-primary_shade_1 h-margin-bottom-s">
            Appointment Notes
          </h4>

          <div v-if="!loading" class="h-CKEditor">
            <div class="h-toolbar">
              <CKEditor v-model="editorData" :config="editorConfig" :editor="editor"></CKEditor>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        class="h-d-flex h-space-between h-margin-top-m h-appointment-btn-section"
      >
        <div class="h-d-flex h-2btn-gap-5">
          <button
            v-if="shouldCounterBeVisible"
            type="button"
            class="h-btn details-goback-button h-btn-accent"
            @click="saveAndClose(false)"
            :disabled="loading"
          >
            Save and Close
          </button>

          <button
            v-if="!shouldCounterBeVisible && !isTypeAutomatic && !isTypeTechCall"
            type="button"
            class="h-btn h-btn-accent"
            :disabled="loading"
            @click="updateTimeSpentCounter"
          >
            Update time spent
          </button>

          <button
            type="button"
            class="h-btn-bordered"
            :class="appointment.is_completed ? 'h-btn-bordered h-btn-fill-safe' : 'h-btn-bordered'"
            @click="save(true)"
            :disabled="loading"
          >
            {{ appointment.is_completed ? "Reopen" : "Mark as completed" }}
          </button>
        </div>
        <div>
          <button
            type="button"
            class="h-btn h-btn-dangerous meeting-delete-button"
            @click="deleteAppointment"
            :disabled="loading"
          >
            Delete
          </button>
          <button
            v-if="!shouldCounterBeVisible"
            type="button"
            class="h-btn h-btn-accent meeting-delete-button h-margin-left-s"
            @click="CloseAndGoBack()"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
      <Loader v-if="loading" />
    </div>
    <AppointmentNotesDialog ref="AppointmentNotesDialog" />
    <DeleteAppointmentDialog ref="DeleteAppointmentDialog" />
    <NewAppointmentDialog
      ref="newAppointmentDialog"
      :client="client"
      @appointmentUpdated="reloadDataTable"
    />
    <AllAppointmentsNotesDialog ref="allAppointmentsNotesDialog" />
  </div>
</template>

<script>
import * as cheerio from 'cheerio';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import * as moment from 'moment';
import Vue from 'vue';
import NewAppointmentDialog from '../appointments_support/NewAppointmentDialog';
import DeleteAppointmentDialog from '../appointments_support/DeleteAppointmentDialog';
import AppointmentNotesDialog from '../appointments_support/AppointmentNotesDialog';
import InputField from '@/components/InputField';
import DateSelector from '@/components/DateSelector';
import * as customEditor from '../../../public/assets/js/ckeditor';
import 'moment-duration-format';
import Loader from '@/components/Loader';
import AllAppointmentsNotesDialog from './AllAppointmentsNotesDialog';
import eventBus from '../../event-bus';
import AppointmentTypesEnum from '../../enums/index';
import { createTechCallAppointment } from '../../utils/appointment-utils';

export default {
  data() {
    const dateStart = moment();
    const dateEnd = moment(dateStart);
    return {
      appointmentListView: true,
      disable_multiple_clicks: false,
      start_date: null,
      start_time: null,
      modeEdit: true,
      loading: false,
      meetings: [],
      options: ['Patient contact', 'Chart note'],
      selectedOption: 'Patient contact',
      appointment: {},
      appointmentsCount: 0,
      appointmentsHours: 0,
      appointmentsMinutes: 0,
      accumulatedTime: 0,
      currentAppointmentId: 0,
      shouldCounterBeVisible: true,
      shouldAppointmentListBeRefreshed: false,
      appointmentType: '',
      timerInterval: null,
      timeSpentValue: 0,
      timeSpentValueManuallyEdited: false,
      appointmentTimeCounter: 0,
      lastTimeAppointmentTimeUpdated: 0,
      updateInProgress: false,
      leftArrowEventListenerAssigned: false,
      saveTimeInterval: 5,
      errors: {},
      dataTable: null,
      dateStart,
      dateEnd,
      meeting: null,
      editor: customEditor,
      editorData: '',
      payload: null,
      editorConfig: {
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '-',
            'fontfamily',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'code',
            'codeBlock',
            '|',
            'insertTable',
            '|',
            'outdent',
            'indent',
            '|',
            'uploadImage',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
        language: 'en',
        image: {
          toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        licenseKey: '',
        wordCount: {
          onUpdate: (stats) => {
            this.charactersLength = stats.characters;
          },
        },
      },

      autoTableUpdater: {
        intervalHandler: null,
        oldResponseJson: null,
        counter: 0,
      },
    };
  },
  watch: {
    appointmentTimeCounter(oldValue, newValue) {
      if (this.shouldCounterBeVisible && !this.timeSpentValueManuallyEdited) {
        const minutesCounter = Math.floor(newValue / 60);
        if (this.timeSpentValue !== minutesCounter) {
          this.timeSpentValue = minutesCounter;
        }
      }
    },
  },

  computed: {
    isTimeSpentEditingDisabled() {
      return this.appointmentType === 'Tech call' || this.appointmentType === 'Automatic';
    },
    isTypeAutomatic() {
      return this.appointment.appointment_type === 'automatic';
    },
    isTypeTechCall() {
      return this.appointment.appointment_type === 'tech_call';
    },
    formattedTimerValue() {
      return moment
        .duration(this.appointmentTimeCounter, 'seconds')
        .format('mm:ss', { trim: false });
    },
    isDevEnvironment() {
      let isDev = true;
      if (this.running_env) {
        isDev = this.running_env.environment === 'development';
      }
      return isDev;
    },
  },
  methods: {
    launchZoomCall(phoneNumber) {
      // Format the phone number for the Zoom call
      const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

      // Generate the Zoom call URL
      const zoomCallURL = `callto:${formattedPhoneNumber}`;

      // Open the Zoom call URL in a new tab
      window.open(zoomCallURL, '_blank');
    },
    validateInput() {
      if (this.appointment.planned_duration < 0) {
        this.appointment.planned_duration = 0;
        $.notify('please enter a valid value.', {
          position: 'top center',
          className: 'error',
        });
      }
      if (this.timeSpentValue < 0) {
        this.timeSpentValue = 0;
        $.notify('please enter a valid value.', {
          position: 'top center',
          className: 'error',
        });
      }
    },
    timeSpentUpdated() {
      if (!this.timeSpentValueManuallyEdited) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
        $.notify(
          'Time spent value is being edited manually. Timer will resume after appointment is saved.',
          {
            position: 'top center',
            className: 'info',
          },
        );
        this.timeSpentValueManuallyEdited = true;
      }
      this.appointmentTimeCounter = this.timeSpentValue * 60;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getMeetingDate() {
      return this.$dateUtils.toLocalDate(this.meeting.start_date_time_utc);
    },

    getMeetingTime() {
      return this.$dateUtils.toLocalTime(this.meeting.start_date_time_utc);
    },

    showEmail() {
      if (this.meeting.client.email) {
        if (this.meeting.client.email.indexOf('@nomail.healent.com') === -1) {
          return true;
        }
      }
      return false;
    },
    reloadDataTable() {
      this.dataTable.ajax.reload();
    },

    goBackToAppointmentsList() {
      this.appointmentListView = true;

      clearInterval(this.timerInterval);
      this.timerInterval = null;
    },
    async open(meetingId) {
      this.loading = true;
      const response = await this.$api.getMeeting(meetingId);
      this.timeSpentValueManuallyEdited = false;

      if (response.status === 'fail') {
        this.loading = false;
        this.appointmentListView = true;

        $.notify('Appointment details can not be opened as it was created by a different user.', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.meeting = response;

      if (!this.leftArrowEventListenerAssigned) {
        eventBus.$on(
          'LeftArrowOnTopBarClicked',
          (event) => {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
            eventBus.$off('LeftArrowOnTopBarClicked');
          },
          this,
        );
        this.leftArrowEventListenerAssigned = true;
      }
      this.timeSpentValue = this.meeting.duration_minutes;

      if (this.meeting.appointment_type === 'chart_check') {
        this.appointmentType = 'Chart note';
      } else if (this.meeting.appointment_type === 'tech_call') {
        this.appointmentType = 'Tech call';
      } else if (this.meeting.appointment_type === 'automatic') {
        this.appointmentType = 'Automatic';
      } else {
        this.appointmentType = 'Patient contact';
      }

      this.shouldCounterBeVisible = !this.meeting.is_completed;
      if (this.meeting.note) {
        this.editorData = this.meeting.note.text;
        this.appointmentTimeCounter =
          this.meeting.duration_minutes * 60 + this.meeting.duration_seconds;
        if (this.timerInterval === null && this.shouldCounterBeVisible) {
          this.timerInterval = setInterval(this.generalTimer, 1000);
        }
      } else {
        this.editorData = '';
      }
      // 8Mar22. User Story: Time Spent in Patient Appointments
      // The counter should only be paused if the appointment is completed
      eventBus.$emit('appointmentEditInProgress', {
        completed: this.meeting.is_completed,
      });
      this.appointment.is_completed = this.meeting.is_completed;
      this.appointment.appointment_type = this.meeting.appointment_type;

      if (!this.appointment.is_completed) {
        eventBus.$emit('uncompleted-appointment-edit-begin', {});
      }

      // eventBus.$emit('overlay_off', {})
      this.loading = false;
    },
    AppointmentCalenderShow() {
      this.$refs.AppointmentCalender.show();
    },
    dateTimeChanged(value) {
      this.dateStart = moment(value);
      this.dateEnd = moment(value);
      this.reloadDataTable();
    },
    async updateTimeSpentCounter() {
      this.loading = true;
      await this.$api.updateMeetingRunningTimeCounter({
        meeting_id: this.meeting.id,
        new_minutes_duration: parseInt(this.timeSpentValue, 10),
      });
      $.notify('Time spent for this meeting successfully updated.', {
        position: 'top center',
        className: 'success',
      });
      eventBus.$emit('refreshAppointmentsList');
      this.shouldAppointmentListBeRefreshed = true;
      this.loading = false;
    },
    async generalTimer() {
      this.appointmentTimeCounter += 1;
      const currentDateTime = moment();
      const isFutureAppointment = moment(
        this.$dateUtils.toLocalDatetime(this.meeting.start_date_time_utc),
      ).isAfter(currentDateTime);
      if (!this.appointmentTimeCounter) clearInterval(this.timerInterval);
      if (
        this.appointmentTimeCounter - this.lastTimeAppointmentTimeUpdated > this.saveTimeInterval &&
        !this.updateInProgress
      ) {
        this.updateInProgress = true;
        // await this.save(false, false, true, isFutureAppointment, true);
      }
    },
    CloseAndGoBack() {
      eventBus.$emit('appointmentEditConcluded', {});
      clearInterval(this.timerInterval);
      this.timerInterval = null;

      eventBus.$emit('uncompleted-appointment-edit-stop', {});

      this.appointmentListView = true;
    },
    async saveAndClose(val) {
      const noErrors = await this.save(val);
      if (noErrors === false) {
        return;
      }
      if (this.shouldAppointmentListBeRefreshed) {
        this.reloadDataTable();
      }
      eventBus.$emit('appointmentEditConcluded', {});
      clearInterval(this.timerInterval);
      this.timerInterval = null;

      eventBus.$emit('uncompleted-appointment-edit-stop', {});

      this.appointmentListView = true;
    },

    async save(
      onlyCompletedStatus = false,
      showLoadingScreen = true,
      autoSave = false,
      futureAppointment = false,
      savedWhileEditingAppointment = false,
    ) {
      if (
        (this.editorData == null || this.editorData === '') &&
        onlyCompletedStatus &&
        !this.appointment.is_completed &&
        this.appointmentType === 'Automatic'
      ) {
        $.notify('Cannot mark empty note as completed', {
          position: 'top center',
          className: 'error',
        });
        return false;
      }

      if (showLoadingScreen) this.loading = true;
      if (this.client) {
        this.appointment.client = this.client;
      }

      const payload = JSON.parse(JSON.stringify(this.appointment));

      if (payload.planned_duration) {
        payload.planned_duration = parseInt(payload.planned_duration, 10);
      }

      // Math.floor(this.appointmentTimeCounter / 60);
      payload.duration_minutes = this.timeSpentValue;
      payload.duration_seconds = this.appointmentTimeCounter - payload.duration_minutes * 60;
      payload.zoom_utc_offset = this.$dateUtils.utcOffsetMinutes;

      if (payload.start_time && payload.start_date) {
        const startDateTimeUtc = this.$dateUtils.toUtcDatetime(
          `${payload.start_date} ${payload.start_time}`,
        );
        const timeParts = startDateTimeUtc.split(' ');
        payload.start_date = timeParts[0];
        payload.start_time = timeParts[1];
      }

      const d = new Date();
      const n = d.getTimezoneOffset();
      payload.utcOffset = n;

      if (onlyCompletedStatus) {
        payload.is_completed = !payload.is_completed;
        if (payload.is_completed) eventBus.$emit('uncompleted-appointment-edit-stop', {});
        else eventBus.$emit('uncompleted-appointment-edit-begin', {});
        this.appointment.is_completed = payload.is_completed;
        this.refresh();
      } else {
        payload.is_completed = this.appointment.is_completed || false;
      }

      payload.auto_save = autoSave;
      payload.is_future_appointment = futureAppointment;
      payload.saved_while_editing_appointment = savedWhileEditingAppointment;

      if (this.appointmentType === 'Patient contact') {
        payload.appointment_type = null;
      } else if (this.appointmentType === 'Chart note') {
        payload.appointment_type = 'chart_check';
      }

      const response = await this.$api.createMeeting(payload);
      if (response.status === 'error') {
        this.error = response.errors;
        this.loading = false;
        console.log('error', this.error);
        $.notify('Failed to save meeting.', {
          position: 'top center',
          className: 'error',
        });
        return false;
      }

      this.shouldCounterBeVisible = !response.item.is_completed;
      this.appointment.duration_minutes = response.item.duration_minutes;

      if (!this.shouldCounterBeVisible) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      if (response.item_is_completed) eventBus.$emit('appointmentEditConcluded', {});
      else {
        eventBus.$emit('appointmentEditInProgress', {
          completed: false,
        });
      }

      this.shouldAppointmentListBeRefreshed = true;
      this.timeSpentValueManuallyEdited = false;

      if (this.timerInterval === null && this.shouldCounterBeVisible) {
        this.timerInterval = setInterval(this.generalTimer, 1000);
      }

      await this.$api.saveMeetingNote({
        meeting_id: this.meeting.id,
        text: this.editorData,
        appointmentTimeUsed: this.appointmentTimeCounter,
      });
      $.notify('Appointment note saved.', {
        position: 'top center',
        className: 'success',
      });

      this.lastTimeAppointmentTimeUpdated = this.appointmentTimeCounter;
      this.updateInProgress = false;
      this.loading = false;
      return true;
    },
    close(force) {
      if (!force && this.loading) return;
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    },
    deleteAppointment() {
      const meetingId = this.currentAppointmentId; // parseInt($(this).attr('data-id'), 10);
      console.log(meetingId);
      this.$refs.DeleteAppointmentDialog.show(meetingId);
    },
    allAppointmentsNotes() {
      this.$api.getMeetings(this.payload).then((response) => {
        this.appointmentsCount = response.data.length;
        let durationTotalInMinutes = 0;
        this.meetings = [];
        response.data.forEach((meeting) => {
          const meetingInfoForSideBar = {
            meetingDate: meeting[1],
            meetingTime: meeting[2],
            meetingNotes: meeting[6],
          };
          this.meetings.push(meetingInfoForSideBar);
          durationTotalInMinutes += meeting[2];
        });
        console.log('meetings', this.meetings);
        this.$refs.allAppointmentsNotesDialog.show(this.meetings);
      });
    },
    refresh(notifyUser = true) {
      this.$api.getMeetings(this.payload).then((response) => {
        this.appointmentsCount = response.data.length;
        let durationTotalInMinutes = 0;
        this.meetings = [];
        response.data.forEach((meeting) => {
          const meetingInfoForSideBar = {
            meetingDate: meeting[1],
            meetingTime: meeting[2],
            meetingNotes: meeting[5],
          };
          this.meetings.push(meetingInfoForSideBar);
          durationTotalInMinutes += meeting[2];
        });
        this.accumulatedTime = response.duration_minutes;
        this.appointmentsHours = Math.floor(durationTotalInMinutes / 60);
        this.appointmentsMinutes = Math.round(
          (durationTotalInMinutes / 60 - this.appointmentsHours) * 60,
        );
        this.reloadDataTable();
        if (notifyUser) {
          $.notify('Appointments list refreshed.', {
            position: 'top center',
            className: 'success',
          });
        }
      });
    },
    newAppointment(justForNote = false, appointmentType = AppointmentTypesEnum.PATIENT_CALL) {
      if (this.disable_multiple_clicks) return;

      if (appointmentType === AppointmentTypesEnum.TECH_CALL) {
        this.loading = true;
        createTechCallAppointment(this).then((response) => {
          const meetingId = response.item.id;
          this.loading = false;
          this.appointmentListView = false;

          const currentDate = moment().format('YYYY-MM-DD');
          const currentTime = moment().format('HH:mm');

          this.appointment = {
            id: meetingId,
            appointment_type: AppointmentTypesEnum.TECH_CALL,
            start_date: currentDate,
            start_time: currentTime,
            planned_duration: response.item.planned_duration,
            is_completed: false,
            client: {
              id: response.item.client.id,
            },
          };

          this.open(meetingId);
        });
      } else {
        this.$refs.newAppointmentDialog.show({
          mode: 'new',
          is_completed: false,
          only_for_note: justForNote,
        });
      }
    },
    format(data) {
      const $ = cheerio.load(data[7]); // The Note HTML element
      // Retrieve all note span text
      let spanTextInsideParagraph = '';
      $('p').each((i, e) => {
        const innerText = $(this)
          .children()
          .first()
          .text()
          .trim();
        if (innerText !== 'Present with patient:' && innerText !== 'RPM Activities:') {
          spanTextInsideParagraph += `${innerText}<br/>`;
        }
      });

      let presentWithPatient = '';
      let rpmActivities = '';

      $('[type="checkbox"]:checked  ~ .todo-list__label__description').each(() => {
        const entryText = $(this)
          .text()
          .trim();
        if (
          entryText === 'family' ||
          entryText === 'friend' ||
          entryText === 'Professional caregiver'
        ) {
          presentWithPatient += `${entryText}, `;
        } else rpmActivities += `<li>${entryText}</li>`;
      });
      presentWithPatient = presentWithPatient.replace(/,\s*$/, '');
      return `
        <div class="thead th">Note content: </div>
        <br/>
        <div class="text-left">
            Present with Patient: ${presentWithPatient}
            <br/>
            <br/>
            RPM Activities
            <ul>
              ${rpmActivities}
            </ul>
            ${spanTextInsideParagraph}
        </div>`;
    },
  },

  async mounted() {
    const that = this;

    eventBus.$on(
      'confirmAppointmentOutstandingChangesSave',
      (event) => {
        clearInterval(that.timerInterval);
        localStorage.leavingUnsavedAppointmentId = this.appointment.id;
        Vue.$confirm({
          title: event.title,
          message: "You're currently editing an appointment",
          button: {
            yes: 'Complete editing',
            no: 'Save and close',
          },
          callback: (confirm) => {
            if (confirm) {
              that.timerInterval = setInterval(that.generalTimer, 1000);
            } else {
              eventBus.$emit('uncompleted-appointment-edit-stop', {});
              this.saveAndClose();
              localStorage.removeItem('leavingUnsavedAppointmentId');
              eventBus.$emit('appointment-edit-end', {});
              eventBus.$emit('profile-closed', {});
            }
          },
        });
        this.appointment_being_edited = false;
      },
      this,
    );
    eventBus.$on(
      'appointmentDeleted',
      (event) => {
        this.goBackToAppointmentsList();
      },
      this,
    );
    eventBus.$on(
      'refreshAppointmentsList',
      (event) => {
        eventBus.$emit('appointmentEditConcluded', {});
        this.appointmentListView = true;
        this.$refs.DeleteAppointmentDialog.close();
        this.reloadDataTable();
      },
      this,
    );

    eventBus.$on(
      'UpdateAutomaticTimerEvent',
      (event) => {
        this.reloadDataTable();
      },
      this,
    );

    this.dataTable = $(this.$refs.table)
      .DataTable({
        processing: true,
        serverSide: true,
        pageLength: 10,
        autoWidth: false,
        order: [[1, 'desc']],
        dom:
          'tr' +
          '<"h-table-footer h-row h-space-between h-align-items-center h-padding-bottom-l h-padding-top-l"lp>',
        ajax: (data, callback, tableSettings) => {
          this.payload = JSON.parse(JSON.stringify(data));
          if (this.client) {
            this.payload.clientId = this.client.id;
          } else {
            this.payload.datetimeStart = this.$dateUtils.toUtcDatetime(
              this.$dateUtils.moveTimeToDateStart(this.dateStart),
            );
            this.payload.datetimeEnd = this.$dateUtils.toUtcDatetime(
              this.$dateUtils.moveTimeToDateEnd(this.dateEnd),
            );
          }

          const execute = () => {
            this.autoTableUpdater.counter += 1;
            const count = this.autoTableUpdater.counter;
            this.$api.getMeetings(this.payload).then((response) => {
              this.appointmentsCount = response.data.length;
              let durationTotalInMinutes = 0;
              this.meetings = [];
              response.data.forEach((meeting) => {
                const meetingInfoForSideBar = {
                  meetingDate: meeting[1],
                  meetingTime: meeting[2],
                  meetingNotes: meeting[5],
                };
                this.meetings.push(meetingInfoForSideBar);
                durationTotalInMinutes += meeting[2];
              });
              this.accumulatedTime = response.duration_minutes;
              this.appointmentsHours = Math.floor(durationTotalInMinutes / 60);
              this.appointmentsMinutes = Math.round(
                (durationTotalInMinutes / 60 - this.appointmentsHours) * 60,
              );
              if (count !== this.autoTableUpdater.counter) {
                return; // prevent update with old data;
              }

              const oldResponseJson = JSON.stringify(response);
              if (this.autoTableUpdater.oldResponseJson === oldResponseJson) {
                return; // table has no new data
              }

              this.autoTableUpdater.oldResponseJson = oldResponseJson;
              tableSettings.json = response;
              callback(response);
            });
          };
          execute();

          // auto update
          if (this.autoTableUpdater.intervalHandler) {
            clearInterval(this.autoTableUpdater.intervalHandler);
          }
          // this.autoTableUpdater.intervalHandler = setInterval(execute, 5000);
        },
        columns: [
          !that.client
            ? {
              name: 'client__name',
              render: (data, _type, row, meta) => {
                const rowIndex = meta.settings.json.data.indexOf(row);
                let photoLink = meta.settings.json.metadata[rowIndex].client_avatar_url;
                if (!photoLink) {
                  photoLink = '/assets/images/users/patient-pro.png';
                }
                const path = this.$router.resolve({
                  name: 'PatientProfile',
                  params: {
                    id: meta.settings.json.metadata[rowIndex].client_id,
                  },
                });
                return `
                  <a href="${path.href}" class="router-link">
                    <img
                      src='${photoLink}'
                      alt='${that.$strUtils.escapeHtml(data)}'
                      class='thumb-sm rounded-circle mr-2'
                    >
                    ${that.$strUtils.escapeHtml(data)}
                  </a>
                `;
              },
            }
            : null,
          !that.client
            ? {
              name: 'client__email',
              render: (data, _type, _row, _meta) => {
                if (data) {
                  if (data.indexOf('@nomail.healent.com') !== -1) {
                    return '';
                  }
                }
                return data;
              },
            }
            : null,
          {
            name: 'appointment_type',
            className: 'text-left',
            render: (data, __type, _row, _meta) => {
              const rowIndex = _meta.settings.json.data.indexOf(_row);
              const meetingType = _meta.settings.json.metadata[rowIndex].meeting_type;
              let classes = 'h-icon-in-table';
              if (meetingType === 'onsite') {
                classes += ' meeting-disabled';
              }
              switch (data) {
                case 'chart_check':
                  return `<img class="${classes}" src="/assets/images/chart check.svg" data-tooltip="Chart check">`;
                case 'automatic':
                  return `<img class="${classes}" src="/assets/images/automatic.svg" data-tooltip="Automatic">`;
                case 'tech_call':
                  return `<img class="${classes}" src="/assets/images/call_support_dark.svg" data-tooltip="Tech call">`;
                case 'onsite':
                  return `<img class="${classes}" src="/assets/images/OnSite.svg" data-tooltip="Onsite">`;
                default:
                  return `<img class="${classes}" src="/assets/images/PatientCall.svg" data-tooltip="Patient call">`;
              }
            },
          },
          that.client
            ? {
              name: 'start_date_time_utc',
              searchable: false,
              className: 'text-right',
              render: (data, _type, row, meta) => {
                const rowIndex = meta.settings.json.data.indexOf(row);
                const id = meta.settings.json.metadata[rowIndex].id;
                const isCompleted = meta.settings.json.data[rowIndex][4];
                const meetingType = meta.settings.json.metadata[rowIndex].meeting_type;
                if (meetingType === 'onsite') {
                  return `
                    <h4
                    class="meeting-disabled"
                    >  ${moment(this.$dateUtils.toLocalDatetime(data)).format('MMM D, YYYY')}
                    </h4>
                `;
                }
                return `
                  <a
                  href="javascript:void(0)"
                  data-id="${id}"
                  data-is-completed="${isCompleted}"
                  class="meeting-edit-button"
                >  ${moment(this.$dateUtils.toLocalDatetime(data)).format('MMM D, YYYY')}
                </a>
                `;
              },
            }
            : null,
          {
            name: 'start_date_time_utc',
            searchable: false,
            className: 'text-right',
            render: (data, _type, row, meta) => {
              const rowIndex = meta.settings.json.data.indexOf(row);
              const id = meta.settings.json.metadata[rowIndex].id;
              const isCompleted = meta.settings.json.data[rowIndex][4];
              console.log('data', meta.settings.json.metadata[rowIndex]);
              const meetingType = meta.settings.json.metadata[rowIndex].meeting_type;
              if (meetingType === 'onsite') {
                return `
                  <h4
                  class="meeting-disabled"
                >  ${this.$dateUtils.toLocalTime(data)}
                </h4>
              `;
              }
              return `
                  <a
                  href="javascript:void(0)"
                  data-id="${id}"
                  data-is-completed="${isCompleted}"
                  class="meeting-edit-button"
                >  ${this.$dateUtils.toLocalTime(data)}
                </a>

          `;
            },
          },
          /*           {
            name: "planned_duration",
            searchable: false,
            render: (data, _type, row, meta) => {
              const rowIndex = meta.settings.json.data.indexOf(row);
              const id = meta.settings.json.metadata[rowIndex].id;
              const is_completed = meta.settings.json.data[rowIndex][4];
              return `
                  <a
                  href="javascript:void(0)"
                  data-id="${id}"
                  data-is-completed="${is_completed}"
                  class="meeting-edit-button"
                >  ${data} mins
                </a>

          `;
            },
          }, */
          {
            name: 'user_name',
            searchable: false,
            className: 'text-right',
            render: (data, _type, row, meta) => {
              const rowIndex = meta.settings.json.data.indexOf(row);
              const meetingType = meta.settings.json.metadata[rowIndex].meeting_type;
              if (meetingType === 'onsite') {
                return `
                  <h4
                  class="meeting-disabled"
                >  ${data}
                </h4>
              `;
              }
              return `
                  <h4
                >  ${data}
                </h4>

          `;
            },
          },
          {
            name: 'duration_minutes',
            className: 'text-right',
            render: (data, _type, row, meta) => {
              const rowIndex = meta.settings.json.data.indexOf(row);
              const id = meta.settings.json.metadata[rowIndex].id;
              const isCompleted = meta.settings.json.data[rowIndex][4];
              const meetingType = meta.settings.json.metadata[rowIndex].meeting_type;
              if (meetingType === 'onsite') {
                return `
                  <h4
                  class="meeting-disabled"
                >  ${data}
                </h4>
              `;
              }
              return `
                  <a
                  href="javascript:void(0)"
                  data-id="${id}"
                  data-is-completed="${isCompleted}"
                  class="meeting-edit-button"
                >  ${data}
                </a>
              `;
            },
          },
          {
            name: 'is_completed',
            className: 'completed_column text-left',
            searchable: false,
            render: (data, _type, row, meta) => {
              return data
                ? '<i class="fa fa-check-circle mr-2" style="color:#2B5ADC;"></i>'
                : '<i class="fa fa-check-circle mr-2" style="color:#C3C4CB;"></i>';
            },
          },
        ].filter(column => column !== null),
      })
      .on('draw.dt', () => {
        const self = this;
        const detailRows = [];
        $('.autoAppType')
          .closest('tr')
          .addClass('autoapp');

        $(this.$refs.wrapper)
          .find('.meeting-expand-row')
          .on('click', function onClickExpandRow(e) {
            e.preventDefault();
            const meetingId = parseInt($(this).attr('data-id'), 10);
            const tr = $(this).closest('tr');
            const div = $(this)[0];
            const row = self.dataTable.row(tr);
            const idx = $.inArray(tr.attr('data-id'), detailRows);

            if (row.child.isShown()) {
              tr.removeClass('details');
              div.innerHTML = '<a href="" class="meeting-notes-details">See more...</a>';
              row.child.hide();
              // Remove from the 'open' array
              detailRows.splice(idx, 1);
            } else {
              tr.addClass('details');
              div.innerHTML = '<a href="" class="meeting-notes-details">See less...</a>';
              row.child(self.format(row.data())).show();
              // Add to the 'open' array
              if (idx === -1) {
                detailRows.push(tr.attr('data-id'));
              }
            }
          });

        $(this.$refs.wrapper)
          .find('.meeting-notes-button')
          .on('click', function onClickNotesMeeting(e) {
            const meetingId = parseInt($(this).attr('data-id'), 10);
            self.$emit('openNote', meetingId);
          });

        $(this.$refs.wrapper)
          .find('.details-cancel-button')
          .on('click', (e) => {
            if (self.shouldAppointmentListBeRefreshed) {
              self.reloadDataTable();
            }
            eventBus.$emit('appointmentEditConcluded', {});
            clearInterval(self.timerInterval);
            self.timerInterval = null;
            this.appointmentListView = true;
          });
        $(this.$refs.wrapper)
          .find('.meeting-edit-button')
          .on('click', async (e) => {
            this.appointmentListView = false;

            const meetingId = parseInt($(e.currentTarget).attr('data-id'), 10);
            const isCompletedAttr = $(e.currentTarget).attr('data-is-completed');
            this.currentAppointmentId = meetingId;
            const oldResponseJson = JSON.parse(this.autoTableUpdater.oldResponseJson);
            const meta = oldResponseJson.metadata.find(entry => entry.id === meetingId);
            const index = oldResponseJson.metadata.findIndex(entry => entry.id === meetingId);
            const isCompleted = oldResponseJson.data[index][3];

            const currentDateTime = moment();
            const pastOrPresent = moment(
              this.$dateUtils.toLocalDatetime(meta.start_date_time_utc),
            ).isAfter(currentDateTime);

            this.start_date = this.$dateUtils.toLocalDate(meta.start_date_time_utc);
            this.start_time = this.$dateUtils.toLocalTime(meta.start_date_time_utc, 'HH:mm:ss');
            this.appointment = {
              id: meta.id,
              start_date: this.start_date,
              start_time: this.start_time,
              planned_duration: meta.planned_duration,
              is_completed: isCompleted,
              is_past_or_present: pastOrPresent,
              client: {
                id: meta.client_id,
              },
            };

            this.open(meetingId);
          });

        $(this.$refs.wrapper)
          .find('.meeting-status-ended')
          .on('click', () => {
            self.$refs.afterMeetingEddedDialog.show();
          });
      });
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    clearInterval(this.timerInterval);
    this.timerInterval = null;
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  destroyed() {
    if (this.autoTableUpdater.intervalHandler) {
      clearInterval(this.autoTableUpdater.intervalHandler);
    }
  },

  components: {
    NewAppointmentDialog,
    AppointmentNotesDialog,
    DeleteAppointmentDialog,
    DateSelector,
    InputField,
    Loader,
    CKEditor: CKEditor.component,
    AllAppointmentsNotesDialog,
  },

  props: {
    client: {
      type: Object,
    },
    devEnv: {
      type: Object,
    },
  },
};
</script>
