<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessage" title="Report download">
      <template v-slot:modal-body>
        <div>
          <label style="font-weight: normal">
            Do you want to also publish this report?
          </label>

          <div style="margin-top: 2em">
            <InputField
              name="PublishReportDialog::skipEmptyReports"
              id="PublishReportDialog::skipEmptyReports"
              type="checkbox"
              v-model="skipEmpty"
              label="Skip empty reports"
            />
          </div>
        </div>

      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="downloadOnly"
        >
          Download only
        </button>

        <button
          type="button"
          class="h-btn h-btn-accent h-margin-left-s"
          @click="downloadAndPublish"
        >
          Publish
        </button>

        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<style lang="stylus" scoped>
.modal-dialog {
  max-width: 1200px;
}
</style>


<script>
import InputField from '@/components/InputField';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      skipEmpty: true,
    };
  },

  methods: {
    show() {
      this.skipEmpty = true;
      this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
    },

    async downloadOnly() {
      this.close();
      this.$emit('downloadOnly', { skipEmpty: this.skipEmpty });
    },

    async downloadAndPublish() {
      this.close();
      this.$emit('downloadAndPublish', { skipEmpty: this.skipEmpty });
    },
  },

  components: {
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>
