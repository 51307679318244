export default {
  getKey(mealPrefix, nutrientKey, keyType) {
    return `${mealPrefix}_${nutrientKey}_${keyType}`;
  },

  roundValue(value, nutrientName) {
    // if (nutrientName !== 'calorie') {
    //   return Math.round(value * 10, 10) / 10;
    // }
    return Math.round(value, 10);
  },

  calcAndSetTarget(mealPrefix, rangeValues, nutrientName) {
    const floorKey = this.getKey(mealPrefix, nutrientName, 'floor');
    const capKey = this.getKey(mealPrefix, nutrientName, 'cap');
    const targetKey = this.getKey(mealPrefix, nutrientName, 'target');
    if (nutrientName === 'calorie') {
      rangeValues[targetKey] = (
        (rangeValues[capKey] - rangeValues[floorKey]) / 2 + rangeValues[floorKey]
      );
      rangeValues[targetKey] = this.roundValue(rangeValues[targetKey], nutrientName);
    } else {
      const multiplier = nutrientName === 'fat' ? 9 : 4;
      const calorieCapKey = this.getKey(mealPrefix, 'calorie', 'cap');
      rangeValues[targetKey] = this.roundValue(
        rangeValues[capKey] * multiplier / rangeValues[calorieCapKey] * 100, nutrientName,
      );
    }
  },

  calcNutrientCountFromCalorie(nutrientName, calorieCount) {
    const multiplier = nutrientName === 'fat' ? 9 : 4;
    return calorieCount / multiplier;
  },

  setTargetValues(mealPrefix, rangeValues) {
    this.calcAndSetTarget(mealPrefix, rangeValues, 'calorie');
    this.calcAndSetTarget(mealPrefix, rangeValues, 'carbohydrate');
    this.calcAndSetTarget(mealPrefix, rangeValues, 'protein');
    this.calcAndSetTarget(mealPrefix, rangeValues, 'fat');
  },

  setNutrientValue(mealPrefix, rangeValues, nutrientName, keyType, value, percent) {
    const key = this.getKey(mealPrefix, nutrientName, keyType);
    rangeValues[key] = parseFloat(value);

    if (!percent) return;

    const floorKey = this.getKey(mealPrefix, nutrientName, 'floor');
    const capKey = this.getKey(mealPrefix, nutrientName, 'cap');
    if (nutrientName === 'calorie') {
      if (['floor', 'cap'].indexOf(keyType) !== -1) {
        this.calcAndSetTarget(mealPrefix, rangeValues, nutrientName);
      } else {
        rangeValues[floorKey] = parseInt(0.9 * rangeValues[key], 10);
        rangeValues[capKey] = parseInt(1.1 * rangeValues[key], 10);
      }
    } else if (keyType === 'target') {
      rangeValues[capKey] = this.calcNutrientCountFromCalorie(
        nutrientName, rangeValues[this.getKey(mealPrefix, 'calorie', 'cap')],
      ) * rangeValues[key] / 100;

      rangeValues[floorKey] = 0;
      rangeValues[capKey] = this.roundValue(rangeValues[capKey], nutrientName);
    } else if (keyType === 'cap') {
      this.calcAndSetTarget(mealPrefix, rangeValues, nutrientName);
    }

    if (nutrientName === 'calorie') {
      ['carbohydrate', 'protein', 'fat'].forEach((affectedNutrientName) => {
        const affectedKey = this.getKey(mealPrefix, affectedNutrientName, 'target');
        this.setNutrientValue(
          mealPrefix, rangeValues, affectedNutrientName, 'target',
          rangeValues[affectedKey], percent,
        );
      });
    }
  },

  getErrors(rangeValues) {

  },
};
