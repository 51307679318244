>
  <!-- <SlottedMessage
    ref="slottedMessage"
    modalSize="xl"
    :title="recipe ? `Recipe: ${recipe.title}` : 'Recipe'"
    @closed="emitClosed"
    bodyBackgroundColor="white"
  > -->
<template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="h-recipe-drawer-max500">
      <div class="h-drawer-card">
        <div class="h-drawer-card-body">
          <MealContainer
            viewer="dialog"
            :recipeId="recipeId"
            @recipeSaved="recipeSaved"
            @scrollTop="scrollTop"
          />
        </div>
      </div>
    </div>
  </Drawer>
</template>
  <!-- </SlottedMessage> -->


<script>
import MealContainer from './components/MealContainer';

export default {
  props: {
    recipeId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      recipe: null,
    };
  },

  methods: {
    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      // this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      // this.$refs.slottedMessage.close();
    },

    emitClosed() {
      this.$emit('closed');
    },

    recipeSaved(recipe, ingredients) {
      this.$emit('recipeSaved', recipe, ingredients);
    },

    scrollTop() {
      this.$refs.slottedMessage.scrollTop(0);
    },
  },
  mounted() {},
  components: {
    MealContainer,
  },
};
</script>
