<template>
  <div>
    <h4 v-if="!cpMode">Health Status</h4>
    <div class="h-text-right h-padding-right-l">
      <button
        type="button"
        class="h-btn h-btn-accent"
        @click="$refs.healthStatusListEditDialog.show()"
      >
        Edit
      </button>
    </div>
    <div class="h1-table">
      <table class="table dataTable">
        <thead class="thead-light">
          <tr>
            <th width="5%">Item</th>
            <th width="5%" class="text-center">Target</th>
            <th width="5%" class="text-center" v-if="!cpMode">Latest</th>
            <th width="5%" class="text-center" v-if="!cpMode">Original</th>
            <th width="5%" class="text-center" v-if="!cpMode">Recorded On</th>
            <th width="5%" class="text-center">Is mandatory</th>
            <th width="5%" class="text-center" v-if="cpMode">Is on</th>
            <th width="5%" class="text-center" v-if="!cpMode">Trend</th>
          </tr>
        </thead>
        <tbody v-if="getWellnessItems(true).length !== 0">
          <tr
            v-for="item of getWellnessItems(true)"
            :key="item.id"
            :class="{ 'h-d-none': shouldHide(item.title) }"
          >
            <td class="text-nowrap min-width">
              {{ item.title }}
            </td>

            <td class="text-center">
              <div v-if="item.title !== 'Foot Temperature'">
                {{ item.target.value_1 !== undefined ? itemTargetToStr(item) : '&lt;MISSING&gt;' }}
              </div>
            </td>

            <td class="text-center" v-if="!cpMode">
              <div v-if="item.title !== 'Foot Temperature'">
                {{ getRecordValue(item, item.last_record) }}
              </div>
            </td>

            <td class="text-center" v-if="!cpMode">
              <div v-if="item.title !== 'Foot Temperature'">
                {{ getRecordValue(item, item.first_record) }}
              </div>
            </td>

            <td class="text-center" v-if="!cpMode">
              {{
                item.last_record
                  ? moment(item.last_record.created_date_time_utc).format('YYYY-MM-DD')
                  : '-'
              }}
            </td>

            <td
              class="text-center"
              :class="{
                'text-success': item.is_mandatory,
                'text-danger': !item.is_mandatory
              }"
            >
              {{ item.is_mandatory ? 'Yes' : 'No' }}
            </td>

            <td
              v-if="cpMode"
              class="text-center"
              :class="{
                'text-success': item.is_active,
                'text-danger': !item.is_active
              }"
            >
              {{ item.is_active ? 'Yes' : 'No' }}
            </td>

            <td class="w-1 text-center" v-if="!cpMode">
              <div v-if="item.title !== 'Foot Temperature'">
                <SparklineChart v-if="item.trend.length !== 0" :options="getTrendOptions(item)" />
                <span v-else>-</span>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="1000" class="text-center">No records</td>
          </tr>
        </tbody>
      </table>
    </div>

    <HealthStatusListEditDialog
      ref="healthStatusListEditDialog"
      :items="getWellnessItems()"
      :holderId="holder.id"
      :cpMode="cpMode"
      @itemsUpdated="itemsUpdated"
    />
  </div>
</template>

<style scoped lang="stylus">
.checklist {
  .status {
    padding-left: 26px;
  }

  .min-width {
    width: 1%;
  }
}

.edit-button {
  min-width: 10em;
}
</style>

<script>
import moment from 'moment';
import SparklineChart from '@/components/charts/SparklineChart';
import HealthStatusListEditDialog from './HealthStatusListEditDialog';
import settings from '@/settings.js';

export default {
  props: {
    holder: {
      type: Object,
    },
    cpMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      moment,
    };
  },

  methods: {
    shouldHide(title) {
      const hideTitles = [
        'How many cigarettes',
        'How many alcoholic',
        'Foot Temperature',
        'Total water intake',
        'Breathing Difficulty',
        'Nutrition',
        'Wheals',
      ];
      return hideTitles.some(substring => title.includes(substring));
    },
    getRecordValue(item, record) {
      if (!record) return '-';

      if (item.data_type === 'bool') {
        return record.record.value ? 'Yes' : 'No';
      }

      if (!item.unit || item.unit === 'yes' || item.unit === 'no') {
        return record.record.value;
      }
      return `${record.record.value} ${item.unit}`;
    },

    getWellnessItems(isActive) {
      return this.holder.checklist.filter((item) => {
        if (this.cpMode) return true;
        return isActive === undefined || item.is_active === isActive;
      });
    },

    itemTargetToStr(item) {
      if (settings.twoMetricValues.indexOf(item.title) !== -1) {
        if (
          item.target.hasOwnProperty('value_1') &&
          item.target.value_1 !== null &&
          item.target.hasOwnProperty('value_2') &&
          item.target.value_2 !== null
        ) {
          return `${item.target.value_1}/${item.target.value_2} ${item.unit}`;
        }
        return null;
      }
      if (item.target.hasOwnProperty('value_1') && item.target.value_1 !== null) {
        return `${item.target.value_1} ${item.unit}`;
      }
      return null;
    },

    itemsUpdated(items) {
      this.holder.checklist = items;
      this.$emit('itemsUpdated', items);
      this.$forceUpdate();
    },

    getTrendOptions(item) {
      const result = {
        chart: {
          width: 150,
        },
        series: [
          {
            data: item.trend.map((entry) => {
              return entry.avg;
            }),
            name: item.title,
            color: '#7777FF',
            connectNulls: true,
          },
        ],
        xAxis: {
          categories: item.trend.map((entry) => {
            return moment(entry.date).format('MMM Do');
          }),
        },
      };
      return result;
    },
  },

  components: {
    SparklineChart,
    HealthStatusListEditDialog,
  },
};
</script>
