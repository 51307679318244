import { render, staticRenderFns } from "./EditAlertDialog.vue?vue&type=template&id=4b81bd54&scoped=true&"
import script from "./EditAlertDialog.vue?vue&type=script&lang=js&"
export * from "./EditAlertDialog.vue?vue&type=script&lang=js&"
import style0 from "./EditAlertDialog.vue?vue&type=style&index=0&id=4b81bd54&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b81bd54",
  null
  
)

export default component.exports