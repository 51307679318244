<template>
  <div>
    <button
      type="button"
      class="h-btn h-btn-accent h-btn-height2"
      @click="prev"
      :disabled="disabled"
    >
      &lt;
    </button>

    <span class="date-display h-h3 h-primary_shade_1" :class="{ 'text-muted': disabled }">
      {{ $dateUtils.formatDate(value) | formatDate }}
    </span>

    <button
      type="button"
      class="h-btn h-btn-accent h-btn-height2"
      @click="next"
      :disabled="disabled"
    >
      &gt;
    </button>
  </div>
</template>

<style lang="stylus" scoped>
button {
  font-size: 10rem;
  min-width: 2em;
}

.h-btn-height2 {
  height: 20rem !important;
  padding: 5rem !important;
}

.date-display {
  font-size: 10rem;
  line-height: 12rem;
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  vertical-align: middle;
  display: inline-block;
  width: 7em;
  text-align: center;
}
</style>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    value: {
      type: Object,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY');
    },
  },
  methods: {
    next() {
      this.$emit('input', moment(this.value).add(this.step, 'days'));
    },

    prev() {
      this.$emit('input', moment(this.value).subtract(this.step, 'days'));
    },
  },

  mounted() {
    if (!this.value) {
      this.$emit('input', moment());
    }
  },
};
</script>
