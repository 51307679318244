<template>
  <div
    class="w-100 chatDisplay-wrapper"
    :class="{ 'h-100': messages && room && !userHasNoPublicKey }"
  >
    <div v-if="room && messages === null" class="text-center">
      <Loader :message="`Loading messages for: ${room.name}`" />

      <button
        ref="hideButton"
        type="button"
        class="h-btn h-btn-accent"
        v-if="mode === 'small'"
        @click="hideChatDisplay"
      >
        Back to users
      </button>
    </div>

    <div v-else class="h-100 w-100">
      <div v-if="!room">
        <div class="h-h5 text-center text-muted h-margin-top-l">
          Room not selected

          <br />
          <button
            ref="hideButton"
            type="button"
            class="h-btn h-btn-accent"
            v-if="mode === 'small'"
            @click="hideChatDisplay"
          >
            Back to users
          </button>
        </div>
      </div>

      <div v-if="room && userHasNoPublicKey">
        <div class="h-h5 text-center text-muted  h-margin-top-l">
          User has not setup his or her account yet.
          <br />
          <button
            ref="hideButton"
            type="button"
            class="h-btn h-btn-accent"
            v-if="mode === 'small'"
            @click="hideChatDisplay"
          >
            Back to users
          </button>
        </div>
      </div>

      <div v-if="room && !userHasNoPublicKey" class="display-table w-100 h-100">
        <!-- messages -->
        <div class="display-table-row-1 w-100">
          <div class="w-100 h-100 valign-top position-relative">
            <div ref="messagesWrapper" class="messagesWrapper h-100">
              <!-- empty chat -->
              <div v-if="messages.length === 0">
                <p class="no-messages">No messages yet.</p>
              </div>

              <!-- chat messages -->
              <div v-else>
                <div
                  v-for="message of messages"
                  :key="message.id"
                  :class="getMessageUserClass(message) + '-messageHolder messageHolder max-w-50'"
                >
                  <div
                    class="content h-primary_shade_1"
                    v-html="prepareMessageContent(message)"
                  ></div>
                  <div class="header">
                    <span class="from">{{ message.from_user.name }} - </span>
                    <span class="h-secondary_shade_1 timestamp">
                      {{ toLocalTimezone(message.created_at) | formatDate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="display-table-row-2 w-100">
          <div class="display-table-cell messageInput-cell valign-top">
            <textarea
              class="messageInput form-control"
              v-model="message"
              @blur="handleBlur"
              @input="updateDraft"
            ></textarea>
            <div class="text-right messageInput-buttons">
              <button
                id="hidebtn"
                ref="hideButton"
                type="button"
                class="h-btn h-btn-accent h-btn-back"
                v-if="mode === 'small'"
                @click="hideChatDisplay"
              >
                Hide
              </button>
              <div class ="h-d-vitals-items">
                <button type="button" class="h-btn h-btn-accent h-btn-sent" @click="sendMessage">
                  Send
                </button>
                <button type="button" class="h-btn h-btn-safe h-btn-sent" @click="sendSMS">
                  Send sms
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped></style>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import virgilHelper from '@/utils/virgil_helper';

export default {
  props: ['room', 'mode'],

  data() {
    return {
      loadMessage: 'Please wait',
      lastRoom: null,

      socket: null,
      messages: [],
      message: '',

      userHasNoPublicKey: false,
    };
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY h:mm a');
    },
  },
  watch: {
    message() {
      this.updateDraft();
    },
    // eslint-disable-next-line func-names
    'room.id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadDraftMessage();
      }
    },
  },

  methods: {
    emitDraftRooms() {
      const draftKeys = Object.keys(localStorage).filter(key => key.startsWith('draftMessage_'));
      const draftRoomIds = draftKeys.map(key => key.replace('draftMessage_', ''));
      this.$emit('draftRoomsUpdate', draftRoomIds);
    },
    loadDraftMessage() {
      if (this.room) {
        const draft = localStorage.getItem(`draftMessage_${this.room.id}`);
        this.message = draft || '';
      }
    },
    handleBlur() {
      if (this.room && this.message.length > 3) {
        localStorage.setItem(`draftMessage_${this.room.id}`, this.message);
        this.emitDraftRooms();
      } else if (this.room && this.message.length <= 3) {
        localStorage.removeItem(`draftMessage_${this.room.id}`);
        this.emitDraftRooms();
      }
    },
    updateDraft() {
      if (this.room && this.message) {
        localStorage.setItem(`draftMessage_${this.room.id}`, this.message);
      }
    },
    hideChatDisplay() {
      this.$emit('hideChatDisplay');
    },

    setSocket(socket) {
      this.socket = socket;
      this.socket.on('room_selected', (data) => {
        virgilHelper
          .preloadUserPublicKeys([settings.currentSession.user, data.user])
          .then((publicKeysLists) => {
            this.userHasNoPublicKey = !!publicKeysLists.find((publicKeys) => {
              return publicKeys.length === 0;
            });
            this.messages = data.messages;
            this.messages.forEach((message) => {
              message.decoded = virgilHelper.decodeMessage(message);
            });
            this.scrollToBottom();
          });
      });

      this.socket.on('room_message', (data) => {
        data.message.decoded = virgilHelper.decodeMessage(data.message);
        this.$emit('onRoomMessage', data);

        if (!this.room || data.room.id !== this.room.id) {
          return;
        }
        this.messages.push(data.message);
        this.$forceUpdate();
        this.scrollToBottom();

        this.socket.emit('room_messages_seen', {
          room: data.room,
        });
      });
      // this.sendReminder();
    },

    async sendReminder() {
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/v2/twilio/get_client_in_app_msg`,
        {
          client_id: this.room.client_id,
        },
      );
      if (!response.message) return;
      this.message = response.message;
      this.sendMessage();
    },

    async sendSMS() {
      if (!this.room) return;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/v2/twilio/send_sms`,
        {
          client_id: this.room.client_id,
          message: this.message,
        },
      );
      console.log(response);
      if (response.success) {
        this.message = '';
        $.notify('SMS sent successfully!', {
          position: 'top center',
          className: 'success',
        });
      } else {
        $.notify('Failed to send SMS!', {
          position: 'top center',
          className: 'error',
        });
      }
    },

    async sendMessage() {
      if (!this.message) return;
      this.socket.emit('room_message', {
        room: this.room,
        receiver_content: virgilHelper.encodeMessageForUser(this.room.user, this.message),
        sender_content: virgilHelper.encodeMessageForUser(
          settings.currentSession.user,
          this.message,
        ),
      });
      this.message = '';
      if (this.room) {
        localStorage.removeItem(`draftMessage_${this.room.id}`); // Remove the draft
        this.emitDraftRooms(); // Update the list of rooms with drafts
      }
    },

    getMessageUserClass(message) {
      if (message.from_user.id === settings.currentSession.user.id) {
        return 'localUser';
      }
      return 'remoteUser';
    },

    toLocalTimezone(timestamp) {
      return this.$dateUtils.toLocalDatetime(timestamp);
    },

    validURL(str) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      return !!pattern.test(str);
    },

    prepareMessageContent(message) {
      if (message.decoded.status === 'error') {
        return `<span class="text-danger">${message.decoded.content}</span>`;
      }
      if (
        this.validURL(message.decoded.content) &&
        message.decoded.content.includes('https://res.cloudinary.com')
      ) {
        return `<img src=${message.decoded.content} />`;
      }
      const that = this;
      const result = that.$strUtils.escapeHtml(message.decoded.content);
      return result.replace(/\n/g, '<br />');
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const el = $(this.$refs.messagesWrapper);
        if (el.length !== 0) {
          el.scrollTop(el[0].scrollHeight);
        }
      });
    },
  },

  updated() {
    const roomId = this.room ? this.room.id : null;
    const lastRoomId = this.lastRoom ? this.lastRoom.id : null;
    this.lastRoom = this.room;

    if (roomId && roomId !== lastRoomId) {
      this.messages = null;

      this.socket.emit('room_selected', {
        room: this.room,
      });
      this.socket.emit('room_messages_seen', {
        room: this.room,
      });
    } else if (!roomId) {
      this.messages = null;
    }
  },

  async mounted() {
    await virgilHelper.init();
    this.loadDraftMessage();
    this.emitDraftRooms();
  },

  components: {
    Loader,
  },
};
</script>
