<template>
  <div class="h-bg-w h-margin-top-l h-padding-top-l h-padding-bottom-l h-padding-left-l">
    <div class="h-align-items-center h-space-between h-padding-right-l">
      <h3 class="h-h3 h-primary_shade_1">Registration form data</h3>
      <h3 class="h-h3 h-primary_shade_1 h-margin-bottom-xs align-baseline">
        <i
          style="font-size: 14rem;"
          class="idToClipboard ToClipboard healenticons-path-4039 h-padding-left-xs"
          @click="copyToClipboard(prettyFormData)"
          data-tooltip="Copy to clipboard"
        ></i>
      </h3>
    </div>
    <div v-if="hasFormData" class="h-text-json  h-padding-top-m">
      <pre>{{ prettyFormData }}</pre>
    </div>
    <div v-else v-html="formattedNoDataMessage" class="h-text-empty-json  h-padding-top-m"></div>
  </div>
</template>

<script>
export default {
  props: ['client'],
  computed: {
    hasFormData() {
      return (
        this.client && Array.isArray(this.client.form_data) && this.client.form_data.length > 0
      );
    },
    prettyFormData() {
      if (this.hasFormData) {
        return JSON.stringify(this.client.form_data, null, 2);
      }
      return null;
    },
    formattedNoDataMessage() {
      if (!this.hasFormData) {
        return "<span class='h-h4 h-secondary_shade_1'>No form data available</span>";
      }
      return '';
    },
  },

  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          $.notify('Copied to clipboard', {
            position: 'top center',
            className: 'success',
          });
        },
        () => {
          $.notify('Error copied to clipboard', {
            position: 'top center',
            className: 'error',
          });
        },
      );
    },
  },
};
</script>
