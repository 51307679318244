<template>
  <div class="h-drawer-max79">
    <Drawer ref="Settings" :direction="'right'" :exist="true">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Notifications ({{ notifications.length }})</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img class="m-auto" src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-0">
          <div v-if="!loading">
            <p
              v-show="notifications.length === 0"
              class="h-margin-top-l h-text-center h-secondary_shade_1 h-h5"
            >
              No notifications
            </p>

            <div v-show="notifications.length !== 0">
              <a
                v-for="notification of notifications"
                :key="notification.uuid"
                @click="e => handleNotification(e, notification)"
                class="h-notification-item"
                :data-tooltip="notification.title"
              >
                <!-- notification icon -->
                <div class="h-d-flex">
                  <i
                    class="h-notification-icons"
                    :data-tooltip="notification.title"
                    :class="getIconClass(notification.note_type)"
                  ></i>
                  <div :data-tooltip="notification.title">
                    <h4
                      :data-tooltip="notification.title"
                      class="h-h4 h-primary_shade_1 h-padding-bottom-xs"
                    >
                      {{ notification.title }}
                    </h4>
                    <p
                      :data-tooltip="notification.description"
                      v-if="notification.description"
                      class="h-h6 h-secondary_shade_1"
                    >
                      {{ notification.description }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-dangerous " @click="close" :disabled="loading">
            Close
          </button>
        </div>
      </div>
    </Drawer>
    <SimpleMessage ref="simpleMessage" />
    <PatientProfileInDrawer
      ref="patientProfileDrawer"
      v-if="showPatientProfileInDrawer"
      @profileClosed="showPatientProfileInDrawer = false"
      :key="client.id"
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import SimpleMessage from '@/components/dialogs/SimpleMessage';
import settings from '@/settings.js';
import PatientProfileInDrawer from '../../../views/surgery_navigator/all_patients/components/PatientProfileInDrawer';
import eventBus from '../../../event-bus';

const SOCKET_EVENT_NAME = 'general_notification';

export default {
  data() {
    return {
      notifications: [],
      notificationsInterval: null,
      pendingNotifications: [],
      client: {},
      loading: false,
      timeout: null,
      showPatientProfileInDrawer: !!this.$route.query.patientID,
    };
  },
  watch: {
    notifications(newNotifications) {
      this.$emit('updateCount', newNotifications.length);
    },
  },
  methods: {
    show() {
      this.loading = false;
      this.$refs.Settings.open();
    },

    close(force) {
      this.$emit('close');
      this.$refs.Settings.close();
    },
    getIconClass(noteType) {
      switch (noteType) {
        case 'communication':
          return 'healenticons-chat';
        case 'alert':
          return 'healenticons-bell';
        case 'pending_progress_reports':
          return 'healenticons-reports';
        default:
          return 'healenticons-with-alerts';
      }
    },
    findCommunicationNoteIndex(haystack, data) {
      const existingNote = haystack.find((note) => {
        if (note.note_type !== 'communication') {
          return false;
        }
        return note.room.id === data.room.id;
      });
      if (existingNote) {
        return haystack.indexOf(existingNote);
      }
      return -1;
    },

    generalNotification(data) {
      if (!data.uuid) {
        data.uuid = this.$uuid.v4();
      }

      switch (data.note_type) {
        case 'pending_progress_reports':
          // eslint-disable-next-line no-case-declarations
          const n = this.notifications.find(e => e.note_type === 'pending_progress_reports');
          if (n) {
            if (!data.has_reports) {
              const index = this.notifications.indexOf(n);
              this.notifications.splice(index, 1);
            }
          } else if (data.has_reports) {
            this.notifications.unshift({
              note_type: 'pending_progress_reports',
              default_icon: true,
              uuid: data.uuid,
              level: 'info',
              title: 'You have new progress reports',
              description: 'You have new progress reports to download',
            });
          }
          return;
        case 'communication':
          if (data.code === 'message_seen') {
            let index = this.findCommunicationNoteIndex(this.pendingNotifications, data);
            if (index !== -1) {
              this.pendingNotifications.splice(index, 1);
            }
            index = this.findCommunicationNoteIndex(this.notifications, data);
            if (index !== -1) {
              this.notifications.splice(index, 1);
            }
          } else {
            const indexInPending = this.findCommunicationNoteIndex(this.pendingNotifications, data);
            if (indexInPending !== -1) {
              this.pendingNotifications.splice(indexInPending, 1);
            }

            const indexInNotifications = this.findCommunicationNoteIndex(this.notifications, data);
            if (indexInNotifications !== -1) {
              this.notifications.splice(indexInNotifications, 1);
            }

            this.pendingNotifications.push(data);
            this.timeout = setTimeout(() => {
              const i = this.findCommunicationNoteIndex(this.pendingNotifications, data);
              if (i !== -1) {
                this.notifications.unshift(this.pendingNotifications.splice(i, 1)[0]);
              }
            }, 250);
          }
          return;

        case 'alert':
          this.notifications.unshift({
            note_type: 'alert',
            default_icon: true,
            uuid: data.uuid,
            level: 'danger',
            title: data.title,
            text: data.text,
            description: data.description,
            actionButton: {
              text: 'Go to patient profile',
              btnType: 'secondary',
              onClick: () => {
                this.$router
                  .push({
                    name: 'PatientProfile',
                    params: {
                      id: data.clientId,
                    },
                  })
                  .catch((error) => {
                    console.error(error);
                    // pass
                  });
              },
            },
          });
          return;
      }
      this.notifications.unshift(data);
    },

    async handleNotification(e, notification) {
      e.preventDefault();
      const clearNotification = () => {
        const index = this.notifications.indexOf(notification);
        if (index !== -1) {
          this.notifications.splice(index, 1);
        }
      };
      switch (notification.note_type) {
        case 'pending_progress_reports':
          $('#reportSelector').trigger('setReportType', 'progress-reports');
          this.$router
            .push({
              name: 'Reports/section',
              params: {
                section: 'progress-reports',
              },
            })
            .catch(() => {
              // pass
            });
          break;
        case 'communication':
          clearNotification();
          $(`[room-id="${notification.room.id}"]`).click();
          this.$router
            .push({
              name: 'Chat/userId',
              params: {
                userId: notification.room.id,
              },
            })
            .catch((erro) => {
              console.error(erro);
              // pass
            });
          this.close();
          break;
        case 'alert':
          this.$refs.simpleMessage.show(
            notification.title,
            notification.text || notification.description,
            undefined,
            false,
            notification.actionButton,
          );
          break;
        default:
          clearNotification();
          this.$refs.simpleMessage.show(
            notification.title,
            notification.text || notification.description,
          );
      }
    },

    async checkPendingReports() {
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/client/get-pending-progress-reports`,
        { 'check-only': true },
      );
      this.generalNotification({
        note_type: 'pending_progress_reports',
        has_reports: response.has_reports,
      });
    },

    async checkAlertsForBell() {
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/checklist/alerts-for-bell`,
      );
      response.alerts.forEach((alert) => {
        alert.note_type = 'alert';
        this.generalNotification(alert);
      });
    },

    async updateBellNotifications() {
      return
      const response = await this.$api.getChatData();
      response.rooms.forEach((room) => {
        if (!room.hasUnseenMessages) return;
        this.generalNotification({
          note_type: 'communication',
          title: room.short_name,
          description: 'You have new message.',
          room,
        });
      });
    },
  },
  async mounted() {
    this.$notificationBar.setComponent(this);

    this.$globalCon.subscribe(SOCKET_EVENT_NAME, this.generalNotification);

    this.updateBellNotifications();
    this.notificationsInterval = setInterval(this.updateBellNotifications, 60000);
  },

  beforeDestroy() {
    clearInterval(this.notificationsInterval);
    this.$globalCon.unsubscribe(SOCKET_EVENT_NAME, this.generalNotification);
    if (this.timeout) clearTimeout(this.timeout);
  },
  components: {
    Loader,
    SimpleMessage,
    PatientProfileInDrawer,
  },
};
</script>
