<template>
  <div style="height: 100%">
    <div
      class="td"
      :class="{ contentCentered: !(this.mealPlan && this.mealPlan.content) }"
      @click="showEditor"
    >
      <div v-if="this.mealPlan && this.mealPlan.content">
        {{ this.mealPlan.content }}
      </div>
      <span v-else class="text-muted">&lt;empty&gt;</span>

      <!-- FreeMealEditor -->
    </div>
    <FreeMealEditor
      v-if="showDialog"
      ref="freeMealEditor"
      @closed="showRecipeDialog = false"
      @freeMealPlanUpdated="
        (posted, recieved) => $emit('freeMealPlanUpdated', posted, recieved)
      "
    />
  </div>
</template>


<style lang="stylus" scoped>
.td {
  cursor: pointer;
  padding: 0;
  height: 100%;
}

.td.contentCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.td > div:first-child {
  padding: 10px;
  white-space: pre-wrap;
}

.td:hover {
  background-color: #f5f5f5;
}
</style>


<script>
import FreeMealEditor from './FreeMealEditor';

let lastId = 0;

export default {
  data() {
    lastId += 1;

    return {
      id: lastId,
      showDialog: false,
    };
  },

  methods: {
    showEditor() {
      this.showDialog = true;
      this.$nextTick(() => {
        this.$refs.freeMealEditor.show(
          this.mealPlan || {
            diet_id: this.diet.id,
            date: this.date,
            meal_type: this.mealType,
            content: '',
          },
        );
      });
    },
  },

  props: {
    mealPlan: {
      type: Object,
    },
    date: {
      type: String,
    },
    mealType: {
      type: String,
    },
    diet: {
      type: Object,
    },
  },

  components: {
    FreeMealEditor,
  },
};
</script>
