<template>
  <div
    class="factorItem h-d-flex h-align-item-center h-cursor-pointer"
    @click="() => viewDetails(item)"
  >
    <div class="h-d-flex h-w50 ">
      <div class="h-fs-icon" :data-tooltip="`${itemAttributes.hint}`">
        <i
          :class="`healenticons-${itemAttributes.class}`"
          :data-tooltip="`${itemAttributes.hint}`"
        ></i>
      </div>
      <div class="align-left flex-column h-align-center h-d-flex">
        <h4
          :class="[
            'h-font20',
            'h-margin-bottom-xxs',
            'h-text-left',
            isNull ? 'h-secondary_shade_1' : 'h-primary_shade_1'
          ]"
          :data-tooltip="`${itemAttributes.hint}`"
        >
          {{ itemAttributes.title }}
        </h4>

        <h6
          :data-tooltip="
            `${
              isNull ? 'No data found in the specified range' : 'Last value provided by the patient'
            }`
          "
          class="h-h4 h-text-left h-secondary_shade_1"
        >
          {{ isNull ? 'No data' : `${formatNumber(item.actual)} ${itemAttributes.unit}` }}
        </h6>
      </div>
    </div>
    <div class="h-w50 h-d-flex justify-content-end align-items-end">
      <h6
        class="h-h4 h-progress-value"
        :style="`color:${itemAttributes.trendColor} ;`"
        :data-tooltip="
          `${
            notEnoughData
              ? 'No enough data'
              : isNull
              ? 'No data found in the specified range'
              : this.isFullJourney
              ? 'Trend (Change factor of the entire journey)'
              : 'Trend (Comparing the averages of the last 30 days with the 30 days before' +
                ' to determine the change factor)'
          }`
        "
      >
        <span class="h-secondary_shade_1 h-margin-right-xs" v-if="isNull || notEnoughData">
          ?
        </span>
        <span
          v-else-if="item.trend === 0"
          class="h-margin-right-xs"
          :style="`color:${itemAttributes.trendColor}`"
          >--</span
        >
        <i
          v-else-if="item.trend > 0"
          class="healenticons-path-3321-1"
          :style="`color:${itemAttributes.trendColor} ;`"
        ></i>
        <i
          v-else
          class="healenticons-path-3322"
          :style="`color:${itemAttributes.trendColor} ;`"
        ></i>
        {{ notEnoughData ? '--' : isNull ? '--' : formatNumber(item.trend) }}{{ '%' }}
      </h6>
      <Progress
        :color="itemAttributes.color"
        :value="progressValue"
        :min="item.min"
        :max="item.max"
        :isNull="isNull"
        :notEnoughData="notEnoughData"
      ></Progress>
    </div>
  </div>
</template>

<script>
import Progress from '@/components/Progress';

export default {
  methods: {
    viewDetails(item) {
      this.$emit('viewDetails', item);
    },
    formatNumber(num) {
      if (num === null || num === undefined) return '--';
      num = Math.abs(num);
      if (Math.floor(num) === num) {
        return num.toString();
      }
      return num.toFixed(2);
    },
  },
  computed: {
    progressValue() {
      return this.item.actual != null ? this.item.actual : 0;
    },
    notEnoughData() {
      const { trend, max, min } = this.item;
      if (trend === null) return true;
      if ((max === 0 && min === 0) || (max === null && min === null)) return true;

      return false;
    },
    isNull() {
      const { current_id: currentId } = this.item;
      if (currentId === null) return true;
      return false;
    },
    itemAttributes() {
      let title = this.item.title;
      let unit = this.item.unit;
      let className = 'line-chart';
      let trendColor = '#A1A3B3';
      let color = '#A1A3B3';
      let hint = '';
      if (this.item.trend > 0) {
        trendColor = '#2B5ADC';
      } else if (this.item.trend < 0) {
        trendColor = '#EE4266 ';
      } else if (this.item.trend === 0 && this.notEnoughData) {
        trendColor = '#A1A3B3';
      } else if (this.item.trend === 0 && !this.isNull) {
        trendColor = '#2B5ADC';
      } else {
        trendColor = '#A1A3B3';
      }

      switch (title) {
        case 'GAD':
          title = 'Anxiety';
          className = 'path-2186';
          hint = 'All assigned assessments that measure the anxiety level of the patient';
          if (this.item.actual > this.item.mean) {
            color = '#2B5ADC';
          } else {
            color = '#EE4266 ';
          }
          break;
        case 'ODI':
          title = 'Disability';
          className = 'path-3321';
          hint = 'All assigned assessments that measure the disability level of the patient';
          if (this.item.actual > this.item.mean) {
            color = '#2B5ADC';
          } else {
            color = '#EE4266 ';
          }
          break;
        case 'PHQ9':
          title = 'Depression';
          className = 'path-3320';
          hint = 'All assigned assessments that measure the depression level of the patient';
          if (this.item.actual > this.item.mean) {
            color = '#2B5ADC';
          } else {
            color = '#EE4266 ';
          }
          break;
        case 'Quality of sleep':
          title = 'Quality of sleep';
          className = 'emojisleep';
          hint = 'Quality of sleep';
          if (this.item.actual >= 3) {
            color = '#2B5ADC';
          } else if (this.item.actual < 3) {
            color = '#EE4266 ';
          } else {
            color = '#A1A3B3';
          }
          break;
        case 'BMI':
          className = 'weightscale-1';
          unit = 'Kg/m2';
          hint = 'Body Mass Index';
          if (this.item.actual <= 24.9) {
            color = '#2B5ADC';
          } else if (this.item.actual >= 25) {
            color = '#EE4266 ';
          } else {
            color = '#A1A3B3';
          }
          break;
        case 'Pain Score':
          className = 'path-2516';
          hint = 'Pain Score';
          if (this.item.actual > 3) {
            color = '#2B5ADC';
          } else {
            color = '#EE4266 ';
          }
          break;
        case 'Activity':
          className = 'path-2184';
          hint = 'Physical activity';
          if (this.item.actual > this.item.mean) {
            color = '#2B5ADC';
          } else {
            color = '#EE4266 ';
          }
          break;
        case 'Blood Sugar':
          className = 'path-2187';
          hint = 'Blood Sugar';
          if (this.item.actual >= 140 || this.item.actual <= 70) {
            color = '#EE4266 ';
          } else {
            color = '#2B5ADC';
          }
          break;
        case 'Blood Pressure':
          className = 'path-2188';
          hint = 'Blood Pressure';
          break;
        case 'SpO2':
          className = 'path-2515';
          hint = 'SpO2';
          if (this.item.actual < 93) {
            color = '#EE4266 ';
          } else {
            color = '#2B5ADC';
          }
          break;
        case 'Pulse Rate':
          className = 'heartbeat';
          hint = 'Pulse Rate';
          if (this.item.actual < 60) {
            color = '#EE4266 ';
          } else {
            color = '#2B5ADC';
          }
          break;
        case 'Hours of sleep':
          className = 'local-hotel';
          hint = 'Hours of sleep';
          break;
        case 'Physical Therapy':
          className = 'exercise-1';
          hint = 'Physical Therapy';
          break;
        case 'Steps Count':
          className = 'path-2183';
          hint = 'Steps Count';
          break;
        case 'TENS Therapy':
          className = 'path-3432';
          hint = 'TENS Therapy';
          break;
      }

      return {
        title,
        class: className,
        color,
        trendColor,
        hint,
        unit,
      };
    },
  },
  components: {
    Progress,
  },

  props: {
    item: {
      type: Object,
    },
    isFullJourney: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
