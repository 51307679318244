<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessageInDrawer" :title="windowTitle">
      <template v-slot:modal-body>
        <div class="widgetListWrapper">
          <div>
            <InputField
              v-for="option of allOptions"
              :key="getOptionKey(option)"
              :id="`optionSelector-${id}-${getOptionKey(option)}`"
              type="toggle"
              :disabled="shouldOptionBeDisabled(option)"
              :value="isOptionSelected(option)"
              :label="getOptionText(option)"
              @change="toggleOption(option)"
            />
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-dangerous" @click="close">
          Close
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<script>
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

let lastId = 0;

export default {
  data() {
    lastId += 1;
    return {
      id: lastId,
    };
  },

  computed: {
    selectedOptionKeys() {
      return this.selectedOptions.map((option) => {
        return this.getOptionKey(option);
      });
    },
  },

  methods: {
    show() {
      this.$refs.slottedMessageInDrawer.show();
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    close() {
      this.$refs.slottedMessageInDrawer.close();
    },

    isOptionSelected(option) {
      return this.selectedOptionKeys.indexOf(this.getOptionKey(option)) !== -1;
    },
    shouldOptionBeDisabled(option) {
      return option.text.toUpperCase() === 'ID';
    },
    toggleOption(option) {
      const optionKey = this.getOptionKey(option);
      const optionIndex = this.selectedOptionKeys.indexOf(optionKey);

      this.$emit(
        'toggleOption',
        option,
        this.selectedOptionKeys.indexOf(optionKey) === -1,
      );
    },
  },

  props: {
    allOptions: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: true,
    },
    getOptionKey: {
      type: Function,
      required: true,
    },
    getOptionText: {
      type: Function,
      required: true,
    },
    windowTitle: {
      type: String,
      default: 'Select options',
    },
    listTitle: {
      type: String,
      default: 'Select options',
    },
  },

  components: {
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>
