<template>
  <div class="h-padding-top-l h-padding-left-l h-padding-right-l">
    <div
      class="h-AllPatients-filter h-margin-bottom-s"
      @mousedown="startDrag"
      @mouseleave="stopDrag"
      @mouseup="stopDrag"
      @mousemove="doDrag"
      :class="allPatientsFilterClass"
    >
      <div class="h-AllPatients-filter-card new-filter 1">
        <div class="h-filter-item-sub h-horizontal ">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'misconfiguredPatients' }"
            @click="toggleActive('misconfiguredPatients', $event)"
          >
            <h4 class="h-filter-text" data-tooltip="Patients are misconfigured">
              <span class="h-dangerous" data-tooltip="Patients are misconfigured">
                {{ filtered.misconfiguredPatients || 0 }}
              </span>
              Patients are misconfigured
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'missing3d' }"
            @click="toggleActive('missing3d', $event)"
          >
            <h4 class="h-filter-text" data-tooltip="Patients with missing data for 3 days">
              <span class="h-dangerous" data-tooltip="Patients with missing data for 3 days">
                {{ filtered.missing3d || 0 }}
              </span>
              Patients are not compliant since 3 days
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'missing7d' }"
            @click="toggleActive('missing7d', $event)"
          >
            <h4 class="h-filter-text" data-tooltip="Patients with missing data for 7 days">
              <span class="h-dangerous" data-tooltip="Patients with missing data for 7 days">
                {{ filtered.missing7d || 0 }}
              </span>
              Patients are not compliant since 7 days
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'allAlert' }"
            @click="toggleActive('allAlert', $event)"
          >
            <h4 class="h-filter-text" data-tooltip="Patients have unresolved alerts">
              <span class="h-dangerous" data-tooltip="Patients have unresolved alerts">
                {{ alertsCount || 0 }}
              </span>
              Patients have unresolved alerts
            </h4>
          </div>
        </div>
      </div>
      <!--       <div class="h-AllPatients-filter-card">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'patientContacts' }"
          @click="toggleActive('patientContacts', $event)"
        >
          <h2
            class="h-filter-number"
            data-tooltip="Patients who sent me messages, or I have scheduled a call today"
          >
            {{ filtered.patientContacts }}
          </h2>
          <h4
            class="h-filter-text"
            data-tooltip="Patients who sent me messages, or I have scheduled a call today"
          >
            Patient Contacts
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'scheduledCalls' }"
            @click="toggleActive('scheduledCalls', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with scheduled calls today">
              {{ filtered.scheduledCalls || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with scheduled calls today">Calls</h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'unReadMessages' }"
            @click="toggleActive('unReadMessages', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients who sent me new unread messages">
              {{ filtered.unReadMessages || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients who sent me new unread messages">
              Messages
            </h4>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card">
        <div class="FilterItem h-filter-item-head">
          <h2 class="h-filter-number" data-tooltip="Patients with alerts">{{ alertsCount }}</h2>
          <h4 class="h-filter-text" data-tooltip="Patients with alerts">Alerts</h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'rpmAlert' }"
            @click="toggleActive('rpmAlert', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with alerts who are in the RPM monitoring program"
            >
              {{ filtered.rpmAlert || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with alerts who are in the RPM monitoring program"
            >
              RPM
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'rtmAlert' }"
            @click="toggleActive('rtmAlert', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with alerts who are in the RTM monitoring program"
            >
              {{ filtered.rtmAlert || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with alerts who are in the RTM monitoring program"
            >
              RTM
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'otherAlert' }"
            @click="toggleActive('otherAlert', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with alerts who are in other monitoring programs"
            >
              {{ filtered.otherAlert || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with alerts who are in other monitoring programs"
            >
              Other
            </h4>
          </div>
        </div>
      </div>

      <div class="h-AllPatients-filter-card">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'behindInTime' }"
          @click="toggleActive('behindInTime', $event)"
        >
          <h2 class="h-filter-number" data-tooltip="Patients with less than 20min spent this month">
            {{ behindInTime }}
          </h2>
          <h4 class="h-filter-text" data-tooltip="Patients with less than 20min spent this month">
            Time this month
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'zeroMin' }"
            @click="toggleActive('zeroMin', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with no time spent this month">
              {{ filtered.zeroMin || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with no time spent this month">
              0min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'between1and10' }"
            @click="toggleActive('between1and10', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with 1-10min spent this month">
              {{ filtered.between1and10 || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with 1-10min spent this month">
              1-10min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'between11and19' }"
            @click="toggleActive('between11and19', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with 11-19min spent this month">
              {{ filtered.between11and19 || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with 11-19min spent this month">
              11-19min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'moreThan20' }"
            @click="toggleActive('moreThan20', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with more than 20min spent this month"
            >
              {{ filtered.moreThan20 || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with more than 20min spent this month">
              20min +
            </h4>
          </div>
        </div>
      </div> -->
      <!--     <div class="h-AllPatients-filter-card h-filter-missing-flex flex-1">
        <div class="h-filter-missing-left">
          <div
            class="FilterItem h-filter-item-head"
            :class="{ active: activeFilter === 'missingDataCount' }"
            @click="toggleActive('missingDataCount', $event)"
          >
            <h2 class="h-filter-number" data-tooltip="Patients with missing data">
              {{ missingDataCount }}
            </h2>
            <h4 class="h-filter-text" data-tooltip="Patients with missing data">Missing data</h4>
          </div>
          <div class="h-filter-item-sub">
            <div
              class="FilterItem"
              :class="{ active: activeFilter === 'missing1d' }"
              @click="toggleActive('missing1d', $event)"
            >
              <h3 class="h-filter-number" data-tooltip="Patients with missing data for 1 day">
                {{ filtered.missing1d || 0 }}
              </h3>
              <h4 class="h-filter-text" data-tooltip="Patients with missing data for 1 day">1d</h4>
            </div>
            <div
              class="FilterItem"
              :class="{ active: activeFilter === 'missing3d' }"
              @click="toggleActive('missing3d', $event)"
            >
              <h3 class="h-filter-number" data-tooltip="Patients with missing data for 3 days">
                {{ filtered.missing3d || 0 }}
              </h3>
              <h4 class="h-filter-text" data-tooltip="Patients with missing data for 3 days">3d</h4>
            </div>
            <div
              class="FilterItem"
              :class="{ active: activeFilter === 'missing7d' }"
              @click="toggleActive('missing7d', $event)"
            >
              <h3 class="h-filter-number" data-tooltip="Patients with missing data for 7 days">
                {{ filtered.missing7d || 0 }}
              </h3>
              <h4 class="h-filter-text" data-tooltip="Patients with missing data for 7 days">7d</h4>
            </div>
          </div>
        </div>
        <div class="h-filter-missing-right h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'techIssue' }"
            @click="toggleActive('techIssue', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with missing data because of technical issue"
            >
              {{ filtered.techIssue || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with missing data because of technical issue"
            >
              Tech issue
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'laziness' }"
            @click="toggleActive('laziness', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with missing data because of other reasons"
            >
              {{ filtered.laziness || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with missing data because of other reasons"
            >
              Other
            </h4>
          </div>
        </div>
        <div class="FilterItem h-i-div">
          <div id="lottie-container" class="h-i-icon"></div>
          <span class="h-filter-hint">
            <h3 class="h-h3 h-primary_shade_1 h-margin-bottom-xs">
              {{ filtered.patientsReminded }}
            </h3>
            <h5 class="h-h5 h-primary_shade_1">
              Patients received reminders in the last 30 Days
            </h5>

            <h5 class="h-h5 h-bold h-primary_shade_1 h-margin-top-m">{{ resolved }}%</h5>
            <div class="progress-container">
              <div class="progress-bar blue" :style="{ width: `${resolved}%` }"></div>
            </div>
            <h5 class="h-h5 h-bold h-primary_shade_1 h-margin-top-s h-margin-bottom-xs">
              {{ ignored }}%
            </h5>
            <div class="progress-container">
              <div class="progress-bar red" :style="{ width: `${ignored}%` }"></div>
            </div>
            <h5 class="h-h5 h-bold h-primary_shade_1 h-margin-top-s h-margin-bottom-xs">
              {{ opened }}%
            </h5>
            <div class="progress-container">
              <div class="progress-bar gray" :style="{ width: `${opened}%` }"></div>
            </div>
            <div class="h-dote-container h-margin-top-m">
              <h5 class="h-dote blue">Resolved</h5>
              <h5 class="h-dote red">Ignored</h5>
              <h5 class="h-dote gray">Open</h5>
            </div>
          </span>
        </div>
      </div> -->
      <div class="h-AllPatients-filter-card flex-1">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'patientContacts' }"
          @click="toggleActive('patientContacts', $event)"
        >
          <h2
            class="h-filter-number"
            data-tooltip="Patients who sent me messages, or I have scheduled a call today"
          >
            {{ filtered.patientContacts }}
          </h2>
          <h4
            class="h-filter-text"
            data-tooltip="Patients who sent me messages, or I have scheduled a call today"
          >
            Patient Contacts
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'scheduledCalls' }"
            @click="toggleActive('scheduledCalls', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with scheduled calls today">
              {{ filtered.scheduledCalls || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with scheduled calls today">Calls</h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'unReadMessages' }"
            @click="toggleActive('unReadMessages', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients who sent me new unread messages">
              {{ filtered.unReadMessages || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients who sent me new unread messages">
              Messages
            </h4>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card flex-1">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'behindInTime' }"
          @click="toggleActive('behindInTime', $event)"
        >
          <h2 class="h-filter-number" data-tooltip="Patients with less than 20min spent this month">
            {{ behindInTime }}
          </h2>
          <h4 class="h-filter-text" data-tooltip="Patients with less than 20min spent this month">
            Time this month
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'zeroMin' }"
            @click="toggleActive('zeroMin', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with no time spent this month">
              {{ filtered.zeroMin || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with no time spent this month">
              0min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'between1and10' }"
            @click="toggleActive('between1and10', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with 1-10min spent this month">
              {{ filtered.between1and10 || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with 1-10min spent this month">
              1-10min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'between11and19' }"
            @click="toggleActive('between11and19', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients with 11-19min spent this month">
              {{ filtered.between11and19 || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with 11-19min spent this month">
              11-19min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'moreThan20' }"
            @click="toggleActive('moreThan20', $event)"
          >
            <h3
              class="h-filter-number"
              data-tooltip="Patients with more than 20min spent this month"
            >
              {{ filtered.moreThan20 || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients with more than 20min spent this month">
              20min +
            </h4>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card flex-1">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'allPatients' }"
          @click="toggleActive('allPatients', $event)"
        >
          <h2 class="h-filter-number" data-tooltip="All patients count">{{ allPatients }}</h2>
          <h4 class="h-filter-text" data-tooltip="All patients count">Patients</h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'newPatientsThisMonth' }"
            @click="toggleActive('newPatientsThisMonth', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="New patients this month">
              {{ filtered.newPatientsThisMonth || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="New patients this month">New</h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'activePatients' }"
            @click="toggleActive('activePatients', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Active patients">
              {{ filtered.activePatients || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Active patients">Active</h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'inActivePatients' }"
            @click="toggleActive('inActivePatients', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients who are inactive">
              {{ filtered.inActivePatients || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients who are inactive">Inactive</h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'pendingPatients' }"
            @click="toggleActive('pendingPatients', $event)"
          >
            <h3 class="h-filter-number" data-tooltip="Patients who are awaiting activation">
              {{ filtered.pendingPatients || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients who are awaiting activation">
              Pending
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="h-bg-w h-padding-top-l h-margin-bottom-l">
      <div class="h-card-body" :class="cardBodyClass">
        <div class="h-d-flex h-space-between h-margin-right-l h-margin-left-l">
          <div>
            <div class="h-d-flex h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1 h-align-items-center">
                Patients
                <span class="h-h5 h-primary_shade_1 h-padding-left-xs">({{ tooltipContent }})</span>
              </h3>
              <InputField
                id="fullscreen"
                type="toggle"
                label="Full view"
                class="h-margin-left-s h-toggle-no-mb "
                @change="toggleFullscreen"
              />
            </div>
            <div class="filter-summary h-d-flex h-padding-top-xs h-padding-right-s">
              <template
                v-if="
                  selectedHospital > 0 ||
                    selectedNurse > 0 ||
                    selectedProvider > 0 ||
                    selectedCp > 0 ||
                    selectedProgram > 0
                "
              >
                <h5 class="h-h5 h-secondary_shade_1">
                  Filtered by:
                  <template v-if="selectedHospital > 0">
                    <span>{{ getHospitalName(selectedHospital) }}</span>
                  </template>
                  <template v-if="selectedNurse > 0">
                    <span v-if="selectedHospital > 0">, </span>
                    <span>{{ getNurseName(selectedNurse) }}</span>
                  </template>
                  <template v-if="selectedProvider > 0">
                    <span v-if="selectedHospital > 0 || selectedNurse > 0">, </span>
                    <span>{{ getProviderName(selectedProvider) }}</span>
                  </template>
                  <template v-if="selectedCp > 0">
                    <span v-if="selectedHospital > 0 || selectedNurse > 0 || selectedProvider > 0"
                      >,
                    </span>
                    <span>{{ getCareProtocolName(selectedCp) }}</span>
                  </template>
                  <template v-if="selectedProgram > 0">
                    <span
                      v-if="
                        selectedHospital > 0 ||
                          selectedNurse > 0 ||
                          selectedProvider > 0 ||
                          selectedCp > 0
                      "
                      >,
                    </span>
                    <span>{{ getProgramName(selectedProgram) }}</span>
                  </template>
                  <template
                    v-if="
                      selectedHospital === 0 &&
                        selectedNurse === 0 &&
                        selectedProvider === 0 &&
                        selectedCp === 0 &&
                        selectedProgram === 0
                    "
                  >
                    <span>None</span>
                  </template>
                  <i
                    v-if="
                      selectedHospital > 0 ||
                        selectedNurse > 0 ||
                        selectedProvider > 0 ||
                        selectedCp > 0 ||
                        selectedProgram > 0
                    "
                    class="h-cursor-pointer h-dangerous healenticons-close-circle h-padding-left-s"
                    @click="resetFilters"
                  ></i>
                </h5>
              </template>
            </div>
          </div>
          <div class="h-d-flex h-align-items-center align-items-center w-300">
            <input
              type="search"
              placeholder="Search"
              class="h-input "
              v-model="textSearch"
              @input="handleInput()"
            />
            <button
              style="width: max-content;"
              class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
              @click="addPatient()"
            >
              <i
                style="line-height: 1; font-size: 10rem;"
                class=" h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"
              ></i
              >Add new patient
            </button>
            <div class="h-relative h-line-height-1 dropdown h-add-dropdown">
              <a
                class="dropdown-toggle arrow-none"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
                style="background: white"
              >
                <i class="h-h3  h-secondary_shade_1 healenticons-download h-margin-left-m"> </i>
              </a>
              <div ref="collectionDropdown" class="dropdown-menu assessment-dropdown"></div>
            </div>

            <i
              class="
              h-h3
              h-secondary_shade_1
              healenticons-gear-fill
              h-cursor-pointer h-margin-left-m"
              @click="addedAction"
            ></i>
          </div>
        </div>

        <div class="h-row">
          <div class="col-lg-12 mt-2">
            <div class="table-responsive h1-table">
              <table id="all-patients" class="table" ref="table">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%" class="AllPatients-tooltop h-text-left">
                      <span class="table-title-bold">ID</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Alerts</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Auto nudging</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Risk of dropping (%)</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Patient name</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField
                        class="hdr"
                        name="hospitalSelect"
                        type="select"
                        :choices="hospitalChoices"
                        @change="event => onChangeInput(event, 'hospital_id')"
                        v-model="selectedHospital"
                      />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Age</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Date of birth</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Mobile No</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Email</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">BMI</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Weight</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Height</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">MRN number</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Surgery Date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Discharge Date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">A1C</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField
                        class="hdr"
                        name="careProtocol"
                        type="select"
                        :choices="CareProtocolChoices"
                        @change="event => onChangeInput(event, 'care_protocol_id')"
                        v-model="selectedCp"
                      />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Location</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Last in-app activity</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Data this month (days)</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField
                        class="hdr"
                        type="select"
                        :choices="[
                          { value: 0, title: 'Monitoring program' },
                          { value: 1, title: 'CCM' },
                          { value: 2, title: 'PCM' },
                          { value: 3, title: 'RPM' },
                          { value: 4, title: 'RTM' },
                          { value: 5, title: 'Wellness' }
                        ]"
                        @change="event => onChangeInput(event, 'monitoring_program')"
                        v-model="selectedProgram"
                      />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Time this month (mins)</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField
                        class="hdr"
                        type="select"
                        :choices="nurseOptions"
                        @change="event => onChangeInput(event, 'assigned_nurse_id')"
                        v-model="selectedNurse"
                      />
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField
                        class="hdr"
                        type="select"
                        :choices="surgeonOptions"
                        @change="event => onChangeInput(event, 'surgeon_id')"
                        v-model="selectedProvider"
                      />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop" v-if="this.isAdmin">
                      <span class="table-title-bold">Messages sent</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop" v-if="this.isAdmin">
                      <span class="table-title-bold">Messages received</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop" v-if="this.isAdmin">
                      <span class="table-title-bold">Calls made</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop" v-if="this.isAdmin">
                      <span class="table-title-bold">Calls received</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Start date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Plan Start date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Plan End date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Appointments</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Last appoint.</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">HFI</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">HFI Delta</span>
                    </th>
                    <th v-if="isAdmin" style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Push ID</span>
                    </th>
                    <th v-if="isAdmin" style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Voip ID</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Risk Score</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Status Reason</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Comments</span>
                    </th>
                    <th style="width: 5%" class="text-right noTooltip">
                      <span class="table-title-bold">Action</span>
                    </th>
                  </tr>
                  <!--end tr-->
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--end card-body-->
    </div>
    <!--end card-->

    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      class="h-hide-id"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name;
        }
      "
      :getOptionText="
        option => {
          return option.text;
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
    <EditProfileInfoTabDrawer ref="EditProfileInfoTabDrawer" :client="client" :key="client.id" />
    <ResendActivationCodeDialog ref="ResendActivationCodeDialog" />
    <SimpleMessage ref="simpleMessage" :breakAll="true" />
    <PatientProfileInDrawer
      ref="patientProfileDrawer"
      v-if="showPatientProfileInDrawer"
      @profileClosed="showPatientProfileInDrawer = false"
      :key="client.id"
    />
  </div>
</template>

<style scoped lang="stylus"></style>

<script>
import moment from 'moment-timezone';
import lottie from 'lottie-web';
import settings from '@/settings.js';
import EditProfileInfoTabDrawer from '../edit_patient/EditProfileInfoTabDrawer';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';
import ResendActivationCodeDialog from './components/ResendActivationCodeDialog';
import PatientProfileInDrawer from './components/PatientProfileInDrawer';
import SimpleMessage from '@/components/dialogs/SimpleMessage';
import InputField from '@/components/InputField';
import eventBus from '../../../event-bus';
import eventKeeper from '../../../eventKeeper';
import animationData from '../../../../public/assets/images/Bot-Waiting-v2.json';

const TABLE_COLUMNS_KEY = 'AllPatients_columnKeys';

export default {
  data() {
    return {
      settings,
      client: {},
      dataTable: null,
      allTableColumns: [],
      visibleTableColumns: [],
      hospitals: [],
      careProtocolChoices: [],
      surgeons: [],
      nurses: [],

      intervalId: null,

      // row filters
      selectedHospital: 0,
      selectedNurse: 0,
      selectedProvider: 0,
      selectedCp: 0,
      selectedProgram: 0,

      filtered: {
        patientContacts: 0,
        scheduledCalls: 0,
        unReadMessages: 0,

        nudgeCount: 0,
        nudgeSms: 0,
        nudgeCall: 0,
        nudgeFailed: 0,
        allAlert: 0,
        rpmAlert: 0,
        rtmAlert: 0,
        otherAlert: 0,

        missing1d: 0,
        missing3d: 0,
        missing7d: 0,
        techIssue: 0,
        laziness: 0,

        patientsReminded: 0,
        totalReminders: 0,
        resolvedReminders: 0,
        ignoredReminders: 0,
        openedReminders: 0,

        zeroMin: 0,
        between1and10: 0,
        between11and19: 0,
        moreThan20: 0,

        countOfDaysDataProvided: 0,
        incompleteNotes: 0,
        noChartChecks15d: 0,
        noCalls21d: 0,

        activePatients: 0,
        inActivePatients: 0,
        pendingPatients: 0,
        misconfiguredPatients: 0,
        newPatientsThisMonth: 0,
      },

      activeFilter: 'activePatients',
      columnFilters: {
        hospital_id: 0,
        assigned_nurse_id: 0,
        surgeon_id: 0,
        care_protocol_id: 0,
        monitoring_program: '',
      },
      draw: 0,
      textSearch: '',
      tooltipContent: 'Active patients',
      isFullscreen: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      showPatientProfileInDrawer: !!this.$route.query.patientID,
      debouncedStateSave: this.debounce(this.customStateSave, 500),
    };
  },

  computed: {
    cardBodyClass() {
      return this.isFullscreen ? 'fullscreen' : '';
    },
    allPatientsFilterClass() {
      return this.isFullscreen ? 'h-d-none' : '';
    },
    isAdmin() {
      return settings.hasRole('system_administrator');
    },
    alertsCount() {
      return (
        (this.filtered.rpmAlert || 0) +
        (this.filtered.rtmAlert || 0) +
        (this.filtered.otherAlert || 0)
      );
    },
    missingDataCount() {
      return (
        (this.filtered.missing1d || 0) +
        (this.filtered.missing3d || 0) +
        (this.filtered.missing7d || 0)
      );
    },
    behindInTime() {
      return (
        (this.filtered.zeroMin || 0) +
        (this.filtered.between1and10 || 0) +
        (this.filtered.between11and19 || 0) +
        (this.filtered.moreThan20 || 0)
      );
    },
    ineligiblePatients() {
      return (
        (this.filtered.countOfDaysDataProvided || 0) +
        (this.filtered.incompleteNotes || 0) +
        (this.filtered.noChartChecks15d || 0) +
        (this.filtered.noCalls21d || 0)
      );
    },
    allPatients() {
      return (
        (this.filtered.activePatients || 0) +
        (this.filtered.inActivePatients || 0) +
        (this.filtered.pendingPatients || 0)
      );
    },

    resolved() {
      return this.filtered.totalReminders > 0
        ? Math.round((this.filtered.resolvedReminders * 100) / this.filtered.totalReminders)
        : 0;
    },
    ignored() {
      return this.filtered.totalReminders > 0
        ? Math.round((this.filtered.ignoredReminders * 100) / this.filtered.totalReminders)
        : 0;
    },
    opened() {
      return this.filtered.totalReminders > 0
        ? Math.round((this.filtered.openedReminders * 100) / this.filtered.totalReminders)
        : 0;
    },

    hospitalChoices() {
      const choices = this.hospitals.map(hospital => [hospital.id, hospital.name]);
      choices.unshift([0, 'Facility']);
      return choices;
    },

    surgeonOptions() {
      const choices = this.surgeons
        .map((surgeon) => {
          if (
            this.hospitalId &&
            this.hospitalId !== 'true' &&
            surgeon.hospital.id !== parseInt(this.hospitalId, 10)
          ) {
            return null;
          }
          return [surgeon.id, surgeon.name];
        })
        .filter(entry => entry !== null);
      choices.unshift([0, 'Physician']);
      return choices;
    },
    nurseOptions() {
      const choices = this.nurses
        .map((nurse) => {
          if (this.hospitalId && this.hospitalId !== 'true') {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              return null;
            }
          }
          return [nurse.id, nurse.name];
        })
        .filter(entry => entry !== null);
      choices.unshift([0, 'Care provider']);
      return choices;
    },
    CareProtocolChoices() {
      const choices = settings.careProtocols
        .map(protocol => [
          protocol.id,
          protocol.is_global ? protocol.name : `${protocol.name} [${protocol.hospital.name}]`,
        ])
        .filter(entry => entry !== null);
      choices.unshift([0, 'Care protocols']);
      return choices;
    },
  },

  methods: {
    debounce(func, wait) {
      let timeout;
      return function debounced(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    async getHospitalList() {
      try {
        this.hospitals = await this.$api.getHospitalsList();
      } catch (e) {
        this.hospitals = [];
      }
    },

    async searchSurgeon() {
      try {
        this.surgeons = await this.$api.searchSeargeon();
      } catch (e) {
        this.surgeons = [];
      }
    },

    async searchNurse() {
      try {
        this.nurses = await this.$api.searchNurse();
      } catch (e) {
        this.nurses = [];
      }
    },

    async getAllPatientsStats() {
      try {
        this.filtered = await this.$api.getAllPatientsStats();
      } catch (e) {
        this.filtered = {};
      }
    },
    customStateSave(customSettings, data) {
      data.selectedHospital = this.selectedHospital;
      data.selectedNurse = this.selectedNurse;
      data.selectedProvider = this.selectedProvider;
      data.selectedCp = this.selectedCp;
      data.selectedProgram = this.selectedProgram;
      data.columnFilters = this.columnFilters;
      localStorage.setItem('dtState', JSON.stringify(data));
    },

    customStateLoad(customSettings) {
      const data = JSON.parse(localStorage.getItem('dtState')) || {};
      this.selectedHospital = data.selectedHospital || 0;
      this.selectedNurse = data.selectedNurse || 0;
      this.selectedProvider = data.selectedProvider || 0;
      this.selectedCp = data.selectedCp || 0;
      this.selectedProgram = data.selectedProgram || 0;
      this.columnFilters = data.columnFilters || this.columnFilters;
      return data;
    },
    initializeTable() {
      const that = this;

      const allColumns = [
        {
          name: 'id',
          searchable: true,
          render: data => (data
            ? ` <div class="idToClipboard cursor-pointer" data-tooltip="Copy patient ID to clipboard">#${that.$strUtils.escapeHtml(
              data,
            )}</div>`
            : null),
          className: 'text-nowrap',
        },
        {
          name: 'alerts',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            let showAlerts = '<div class="h-alert-icons">';
            const noAlerts = `
                <div class="h-alert-icons">
                     <i class="h-secondary_shade_1 healenticons-no-issues"></i>
                 </div>
            `;
            if (data) {
              const clientAlerts = data
                .replace(/\s+/g, '')
                .split(',')
                .filter(i => i);
              if (clientAlerts.length > 0) {
                if (clientAlerts.includes('low_data')) {
                  showAlerts += `<i class="h-dangerous healenticons-low-data"
                                title = "Patient has not enough readings this month"></i>`;
                }
                if (clientAlerts.includes('empty_note')) {
                  showAlerts += `<i class="h-dangerous healenticons-uncompleted-notes"
                                    title="Patient has incomplete or empty notes this month"></i>`;
                }
                if (clientAlerts.includes('upcoming_appt')) {
                  showAlerts += `<i class="h-dangerous healenticons-calendar-fill"
                                    title="Patient has and upcoming appointment in the next 7 days"></i>`;
                }
                if (clientAlerts.includes('monthly_call')) {
                  showAlerts += `<i class="h-accent healenticons-no-issues"
                                    title="Patient has completed one or more calls for last 30 days"></i>`;
                }
                showAlerts += '</div>';
                return showAlerts;
              }
            }
            return noAlerts;
          },
        },
        {
          name: 'auto_nudging',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data
              ? `
              <div class="h-alert-icons">
                 <i class="h-accent healenticons-no-issues"></i>
               </div>
              `
              : `<div class="h-alert-icons">
                 <i class="h-secondary_shade_1 healenticons-no-issues"></i>
               </div>
              `;
          },
        },
        {
          name: 'risk_of_dropping_out',
          searchable: false,
          orderable: true,
          render: (data) => {
            if (data) {
              const rounded = Math.round(data * 100);
              return Math.min(rounded, 100);
            }
            return '-';
          },
        },
        {
          name: 'name',
          orderable: true,
          searchable: true,
          render: (data, type, row, meta) => {
            return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer"  data-id="${row[0]}">
              ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
          },
          className: 'text-nowrap',
        },
        {
          name: 'hospital__name',
          searchable: true,
          orderable: true,
        },
        {
          name: 'age',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
          className: 'text-nowrap',
        },
        {
          name: 'date_of_birth',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            const date = moment(data);
            return date.format('MMM D, YYYY');
          },
        },
        {
          name: 'phone',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          name: 'email',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          name: 'bmi_calculated',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          name: 'weight_lbs',
          searchable: false,
          orderable: true,
          render: data => (data ? `${data} lbs` : '-'),
        },
        {
          name: 'height',
          searchable: false,
          orderable: true,
          render: data => (data ? `${data} inches` : '-'),
        },
        {
          name: 'mrn',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          name: 'surgery_date',
          searchable: false,
          orderable: true,
          render: (data) => {
            if (data) {
              const date = moment(data);
              return date.format('MMM D, YYYY h:mm a');
            }
            return '-';
          },
        },
        {
          name: 'discharge_date',
          searchable: false,
          orderable: true,
          render: (data) => {
            if (data) {
              const date = moment(data);
              return date.format('MMM D, YYYY');
            }
            return '-';
          },
        },
        {
          name: 'A1C',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          name: 'care_protocol__name',

          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          name: 'location__name',

          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          name: 'user__last_login_activity',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY h:mm a') : '-';
          },
        },
        {
          name: 'mi_compliance__score',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return !isNaN(parseFloat(data)) ? Math.round(parseFloat(data)).toFixed(0) : 0;
          },
        },
        {
          name: 'monitoring_program',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          name: 'accumulated_time',
          searchable: false,
          orderable: true,
          render: data => (data ? `${data ?? 0} mins` : '-'),
        },
        {
          name: 'assigned_nurse__name',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          name: 'surgeon__name',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          name: 'messages_sent_this_month',
          __adminOnly: true,
          searchable: false,
          orderable: true,
          render: data => (data != null ? `${data}` : '-'),
        },
        {
          name: 'messages_received_this_month',
          __adminOnly: true,
          searchable: false,
          orderable: true,
          render: data => (data != null ? `${data}` : '-'),
        },
        {
          name: 'outbound_calls_this_month',
          __adminOnly: true,
          searchable: false,
          orderable: true,
          render: data => (data != null ? `${data}` : '-'),
        },
        {
          name: 'inbound_calls_this_month',
          __adminOnly: true,
          searchable: false,
          orderable: true,
          render: data => (data != null ? `${data}` : '-'),
        },
        {
          name: 'created_date_time_utc',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY') : '-';
          },
        },
        {
          name: 'plan_start_date',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY') : '-';
          },
        },
        {
          name: 'plan_end_date',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY') : '-';
          },
        },
        {
          name: 'meeting_count',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          name: 'last_meeting_datetime',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data
              ? moment(this.$dateUtils.toLocalDatetime(data)).format('MMM D, YYYY h:mm a')
              : '-';
          },
        },
        {
          name: 'hif_score_current',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data != null ? parseFloat(data, 10) : 'No data';
          },
        },
        {
          name: 'hif_score_delta',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data != null ? parseFloat(data, 10).toFixed(0) : '-';
          },
        },
        {
          name: 'registration_id',
          __adminOnly: true,
          render: (data, type, row, meta) => {
            if (!data) return '-';
            const value = data.length <= 10 ? data : `${data.substr(0, 5)}...${data.substr(-5)}`;
            return `<a
             href="javascript:void(0);"
             class="mr-2 show-dataTable-value"
             data-value="${data}"
           >
             ${value}
           </a>`;
          },
        },
        {
          name: 'voip_registration_id',
          __adminOnly: true,
          render: (data, type, row, meta) => {
            if (!data) return '-';
            const value = data.length <= 10 ? data : `${data.substr(0, 5)}...${data.substr(-5)}`;
            return `<a
             href="javascript:void(0);"
             class="mr-2 show-dataTable-value"
             data-value="${data}"
          >
            ${value}
          </a>`;
          },
        },
        {
          name: 'rapt_score',
          searchable: false,
          orderable: true,
          __adminOnly: false,
          render: (data, type, row, meta) => {
            return data ? parseFloat(data, 10).toFixed(0) : '-';
          },
        },
        {
          name: 'if_inactive_reason__reason_description',
          searchable: true,
          orderable: true,
          __adminOnly: false,
          render: data => data || '-',
        },
        {
          name: 'outstanding_issues_or_concerns',
          searchable: true,
          orderable: true,
          __adminOnly: false,
          render: data => data || '-',
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row, meta) => {
            const rowIndex = meta.settings.json.data.indexOf(row);
            const isConfirmedClass =
              meta.settings.json.metadata[rowIndex] &&
              meta.settings.json.metadata[rowIndex].is_confirmed
                ? 'd-none'
                : '';
            return `
            <div class="text-right action-td">
              <a
                href="javascript:void(0);"
                class="mr-2 resend-invite-letter ${isConfirmedClass}"
                data-id="${row[0]}"
              >
                <i class="fas fa-envelope-open-text text-info font-16"
                title="Resend Welcome SMS"></i>
              </a>
              <a href="javascript:void(0);" class="EditProfile" data-id="${row[0]}">
                <i class='fas fa-edit text-info font-16' title='Edit'></i>
              </a>

            </div>
          `;
          },
        },
      ];

      this.dataTable = $('#all-patients').DataTable({
        processing: true,
        serverSide: true,
        lengthMenu: [100, 200, 500, 1000, 5000],
        pageLength: 100,
        searching: true,
        stateSave: true,
        stateSaveCallback: this.debouncedStateSave,
        stateLoadCallback: this.customStateLoad,
        order: [[0, 'desc']],
        searchDelay: 500,
        cache: false,
        colReorder: true,
        ajax: (data, callback, tableSettings) => {
          data.draw = 0;
          this.$api
            .getClients({
              [this.activeFilter]: true,
              columnFilters: this.columnFilters,
              ...data,
            })
            .then((response) => {
              that.draw += 1;
              response.draw = that.draw;
              tableSettings.json = response;
              callback(response);
            });
        },
        dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: {
          buttons: ['copy', 'excel', 'csv', 'print'],
        },
        columns: allColumns.filter((column) => {
          if (this.isAdmin) return true;
          return !column.__adminOnly;
        }),
      });

      this.unfilteredData = this.dataTable.data().toArray();
      this.columnsIds = allColumns.map(column => column.name);
      this.$nextTick(() => {
        $(this.$refs.table).on('init.dt', () => {
          this.dataTable
            .buttons()
            .container()
            .appendTo(this.$refs.collectionDropdown);
        });
      });
      this.allTableColumns = this.dataTable.context[0].aoColumns.map((aoColumn, index) => {
        let text = $(this.dataTable.column(index).header()).text();
        if (text.includes('Care protocols')) {
          text = 'Care protocols';
        }
        if (text.includes('Monitoring program')) {
          text = 'Monitoring program';
        }
        return {
          text,
          name: aoColumn.name,
        };
      });
      this.visibleTableColumns = JSON.parse(localStorage.getItem(TABLE_COLUMNS_KEY) || '[]');
      if (this.visibleTableColumns.length === 0) {
        this.visibleTableColumns = this.allTableColumns.slice();
      } else {
        this.allTableColumns.forEach((columnInfo) => {
          const isVisible = this.visibleTableColumns.some(
            columnVis => columnVis.name === columnInfo.name,
          );
          const dtColumn = this.dataTable.column(`${columnInfo.name}:name`); // Using :name selector
          dtColumn.visible(isVisible);
        });
      }

      $(this.$refs.table).on('click', '.resend-invite-letter', async (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        const data = this.dataTable.data().toArray();
        const row = data.find(entry => entry[0] === parseInt(id, 10));
        const headers = $(this.$refs.table)
          .find('th')
          .map((index, el) => $(el)
            .text()
            .trim())
          .toArray();
        const resultData = {};
        headers.forEach((header, index) => {
          resultData[header] = row[index];
        });
        resultData.id = parseInt(id, 10);
        that.$refs.ResendActivationCodeDialog.show(resultData);
      });
      $(this.$refs.table).on('click', '.OpenProfileInDrawer', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        this.showPatientProfileInDrawer = true;
        this.$nextTick(() => {
          this.$refs.patientProfileDrawer.show(id);
        });
      });
      // Add a click event listener for the ID cell
      $(this.$refs.table).on('click', '.idToClipboard', (e) => {
        const idElement = $(e.target);
        const id = idElement.text();
        navigator.clipboard
          .writeText(id)
          .then(() => {
            $.notify(`Copied the patient ID ${id} to clipboard`, {
              position: 'top center',
              className: 'success',
            });
          })
          .catch((err) => {
            console.error('Unable to copy to clipboard', err);
          });
      });
      $(this.$refs.table).on('click', '.show-dataTable-value', (e) => {
        const value = $(e.target)
          .closest('a')
          .attr('data-value');
        this.$refs.simpleMessage.show('Message', value);
      });
      $(this.$refs.table).on('click', '.EditProfile', async (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        this.client = await this.$api.getClient(id);
        that.$refs.EditProfileInfoTabDrawer.show(this.client);
      });
      $('.AllPatients-tooltop').tooltip();
    },
    addedAction(e, dt, node, config) {
      this.dataTable = $('#all-patients').DataTable();
      const currentOrder = this.dataTable.colReorder.order();
      this.allTableColumns = currentOrder.map(index => this.allTableColumns[index]);
      this.$refs.toggleColumnsDialog.show();
    },
    async toggleActive(name, event) {
      if (event.target.hasAttribute('data-tooltip')) {
        this.tooltipContent = event.target.getAttribute('data-tooltip');
      }
      this.activeFilter = name;
      await this.$nextTick();
      this.dataTable.ajax.reload();
    },
    onChangeInput(data, fieldName) {
      // filter dtatable rows
      let value = data.selectedOption.value;
      if (fieldName === 'monitoring_program' && data.selectedOption.value !== 0) {
        value = data.selectedOption.title;
      }
      this.columnFilters[fieldName] = value;
      this.dataTable.ajax.reload();
    },

    reloadDataTable() {
      this.dataTable.ajax.reload();
    },

    handleClick(newTab) {
      this.currentTab = newTab;
    },

    addPatient() {
      this.$router.push({ name: 'AddPatient' });
    },

    getStatusBadgeClass(data) {
      return (
        {
          'Low Risk': 'success',
          'Medium Risk': 'warning',
          'High Risk': 'danger',
        }[this.getStatusText(data)] || 'info'
      );
    },

    getStatusText(data) {
      if (data < 6) return 'High Risk';
      if (data > 9) return 'Low Risk';
      return 'Medium Risk';
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.findIndex(e => e.name === column.name);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },

    stopInterval() {
      if (this.intervalId) clearInterval(this.intervalId);
    },
    getHospitalName(hospitalId) {
      const hospital = this.hospitals.find(h => String(h.id) === String(hospitalId));
      return hospital ? hospital.name : '';
    },
    getNurseName(nurseId) {
      const nurse = this.nurses.find(n => String(n.id) === String(nurseId));
      return nurse ? nurse.name : '';
    },
    getProviderName(providerId) {
      const provider = this.surgeons.find(s => String(s.id) === String(providerId));
      return provider ? provider.name : '';
    },
    getCareProtocolName(cpId) {
      const protocol = settings.careProtocols.find(p => String(p.id) === String(cpId));
      if (protocol) {
        if (protocol.is_global) {
          return protocol.name;
        }
        return `${protocol.name} [${protocol.hospital.name}]`;
      }
      return undefined;
    },
    getProgramName(programValue) {
      const programs = [
        { value: 0, title: 'Monitoring program' },
        { value: 1, title: 'CCM' },
        { value: 2, title: 'PCM' },
        { value: 3, title: 'RPM' },
        { value: 4, title: 'RTM' },
        { value: 5, title: 'Wellness' },
      ];
      const program = programs.find(p => String(p.value) === String(programValue));
      return program ? program.title : '';
    },
    resetFilters() {
      this.selectedHospital = 0;
      this.selectedNurse = 0;
      this.selectedProvider = 0;
      this.selectedCp = 0;
      this.selectedProgram = 0;
      this.columnFilters.hospital_id = 0;
      this.columnFilters.assigned_nurse_id = 0;
      this.columnFilters.surgeon_id = 0;
      this.columnFilters.care_protocol_id = 0;
      this.columnFilters.monitoring_program = '';
      this.$nextTick(() => {
        this.reloadDataTable();
      });
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
    },
    stopDrag() {
      this.isDragging = false;
    },
    doDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 3; // Adjust for sensitivity
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
  },

  async mounted() {
    const el = document.getElementById('lottie-container');
    lottie.loadAnimation({
      container: el,
      animationData,
    });
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>patients>all-patients');
    });

    this.initializeTable();
    this.$nextTick(() => {
      if (this.dataTable) {
        const state = this.dataTable.state.loaded();
        if (state && state.search) {
          this.textSearch = state.search.search;
        }
      } else {
        console.error('DataTable not initialized!');
      }
    });
    await this.getHospitalList();
    await this.searchSurgeon();
    await this.searchNurse();

    await this.getAllPatientsStats();
    eventBus.$on(
      'CloseEditProfileInfoTab',
      () => {
        this.reloadDataTable();
      },
      this,
    );
    eventBus.$on(
      'setBannerNoticeText',
      () => {
        this.reloadDataTable();
      },
      this,
    );
    eventBus.$on(
      'updatedNurseInfo',
      () => {
        this.getAllPatientsStats();
      },
      this,
    );
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    this.stopInterval();
    eventKeeper.$offOwner(this);
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    SelectorDialogInDrawer,
    ResendActivationCodeDialog,
    SimpleMessage,
    InputField,
    EditProfileInfoTabDrawer,
    PatientProfileInDrawer,
  },
};
</script>
