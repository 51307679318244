<template>
  <div class="">
    <div v-if="categories">
      <div v-if="client.diagnoses.length" class="h1-table h-overflow-in">
        <table class="table dataTable h-noborder-input h-ICD-table">
          <thead class="thead-light">
            <tr>
              <th width="2%" class="h-text-center">#</th>
              <th width="70%">ICD Code</th>
              <th width="10%">Type</th>
              <th width="5%" class="h-text-center"></th>
            </tr>
          </thead>

          <DiagnoseRow
            v-for="(clientDiagnose, index) of client.diagnoses"
            :key="clientDiagnose.uuid"
            :index="index"
            :categories="categories"
            :clientDiagnose="clientDiagnose"
            @remove="removeRow"
            @categoryChange="category => (clientDiagnose.category = category)"
            @diagnoseChange="diagnose => (clientDiagnose.diagnose = diagnose)"
            @diagnoseTypeChange="
              diagnoseType => {
                clientDiagnose.diagnose_type = diagnoseType
              }
            "
          />
        </table>
      </div>
      <div v-else class="h-text-center h-h5 h-secondary_shade_1 h-bold">
        No entries.
      </div>
      <div class="h-text-center h-margin-top-l">
        <button
          type="button"
          class="h-btn h-btn-accent  h-btn-with-icon"
          @click="newRow"
          :disabled="!this.categories || this.categories.length === 0"
        >
          <i
            style="line-height: 1; font-size: 10rem;"
            class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"
          ></i>
          Add diagnose
        </button>
      </div>
    </div>

    <div v-if="!categories">
      <Loader />
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import DiagnoseRow from './DiagnoseRow';

export default {
  data() {
    this.client.diagnoses.forEach(diagnose => ({ ...diagnose, uuid: this.$uuid.v4() }));
    return {
      categories: 'New',
    };
  },

  methods: {
    newRow() {
      this.client.diagnoses.push({
        uuid: this.$uuid.v4(),
        category: 'New',
        diagnose: null,
        diagnose_type: 'primary',
      });
      this.$forceUpdate();
    },

    removeRow(row) {
      const indexToBeDeleted = this.client.diagnoses.findIndex(
        d => d.diagnose.id === row.diagnose.id,
      );

      this.client.diagnoses.splice(indexToBeDeleted, 1);
      this.client.diagnoses.forEach(diagnose => ({ ...diagnose, uuid: this.$uuid.v4() }));

      this.$forceUpdate();
    },
  },

  async mounted() {
    this.categories = this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/diagnose-categories`);
  },

  components: {
    Loader,
    DiagnoseRow,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>
