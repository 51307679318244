<template>
  <div class="account-body">
    <div class="row">
      <div class="col-12 align-self-center">
        <div class="auth-page">
          <div class="card auth-card shadow-lg">
            <div class="card-body">
              <div class="px-3">
                <div class="text-center auth-logo-text">
                  <h4 class="mt-0 mb-3 mt-4">Password changed</h4>
                </div>

                <p class="text-center">
                  Your password was successfully changed.
                </p>

              </div><!--end /div-->
            </div><!--end card-body-->
          </div><!--end card-->
        </div><!--end auth-page-->
      </div><!--end col-->
    </div><!--end row-->
  </div>
</template>


<style lang="stylus" scoped>
.account-body
  background-color transparent
  padding-top 2em
  padding-bottom 4em
</style>


<script>
export default {
};
</script>
