<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer
      ref="slottedMessageInDrawer"
      title="Note editor"
      :closeDisabled="loading"
    >
      <template v-slot:modal-body>
        <div v-if="!loading" class="loadedModalBody mx-2">
          <label class="h-label"> Note ({{ noteData.date }}): </label>

          <InputField type="textarea" v-model="noteContent" :rows="8" />
        </div>

        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <div class="h-align-items-center h-space-between h-margin-right-xl">
          <div>
            <button
              type="button"
              class="h-btn h-btn-accent"
              @click="save"
              :disabled="loading"
            >
              <span v-if="noteData && noteData.note">Update</span>
              <span v-else>Create</span>
            </button>
            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-l"
              @click="close"
              :disabled="loading"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              v-if="noteData && noteData.note"
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-l"
              @click="remove"
              :disabled="loading"
              title="Remove note"
            >
              Remove
            </button>
          </div>
        </div>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<script>
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,
      noteData: null,
      noteContent: '',
    };
  },

  methods: {
    show(noteData) {
      this.loading = false;
      this.noteData = noteData;
      this.noteContent = noteData.note ? noteData.note.note : '';
      this.$refs.slottedMessageInDrawer.show();
    },

    close(force) {
      this.$refs.slottedMessageInDrawer.close(force);
    },

    async save() {
      this.loading = true;
      const response = await this.$api.saveDietDayNote({
        note: this.noteContent,
        date: this.noteData.date,
        clientId: this.noteData.clientId,
      });
      this.$emit('noteUpdated', this.noteData, response.note);
      this.close(true);
    },

    async remove() {
      this.noteContent = '';
      await this.save();
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>
