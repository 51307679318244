var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-row h-100 position-relative"},[_c('div',{staticClass:"col-lg-4 px-0",class:{
      'd-none': !_vm.roomListVisible
    }},[_c('div',{staticClass:"valign-top roomSelector",class:{
        'd-none': !_vm.roomListVisible,
        'force-w-100': _vm.roomListVisible && _vm.mode === 'small'
      }},[_c('RoomSelector',{key:_vm.draftRoomIds.join(','),ref:"roomSelector",attrs:{"rooms":_vm.rooms,"selectedRoom":_vm.selectedRoom,"draftRoomIds":_vm.draftRoomIds},on:{"roomSelected":_vm.roomSelected,"newRoom":_vm.newRoom}})],1)]),_c('div',{staticClass:"col-lg-8 px-0"},[_c('div',{staticClass:"h-100 w-100",class:{
        'd-none': !_vm.chatDisplayVisible
      }},[_c('ChatDisplay',{ref:"chatDisplay",attrs:{"room":_vm.selectedRoom,"mode":_vm.mode},on:{"onRoomMessage":_vm.onRoomMessage,"hideChatDisplay":_vm.hideChatDisplay,"draftRoomsUpdate":_vm.handleDraftRoomsUpdate}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }