<template>
  <div class="h-drawer-max79350 h-no-padding h-no-footer">
    <SlottedMessageInDrawer ref="slottedMessage">
      <template v-slot:modal-header>
        <div class="h-drawer-card-header">
          <div
            class="h-d-flex h-space-between align-items-center h-margin-bottom-m"
            :class="{ 'justify-content-end': isUndefined }"
          >
            <div v-if="!isUndefined" class="h-d-flex h-align-items-center">
              <svg
                :data-src="anId.icon"
                :class="svgClass"
                :key="anId.icon"
              ></svg>
              <!-- //End icon -->
              <div class="h-margin-left-s">
                <div class="h-margin-bottom-xs">
                  <h3 class="h-h3 h-primary_shade_1">
                    {{ anId ? anId.title : "" }}
                  </h3>
                </div>
                <div
                  data-tooltip="The mean of the values from the past 30 days."
                  :class="{
                    'h-secondary_shade_1':
                      anId.summeryValue === null || loading,
                  }"
                  class="h-align-items-center"
                >
                  <div v-if="anId.title === 'SpO2' && anId.summeryValue < 93">
                    <span
                      :class="{
                        'h-secondary_shade_1':
                          anId.summeryValue === null || loading,
                      }"
                      class="h-h3 h-dangerous h-margin-right-xs"
                      >{{ summeryValue }}</span
                    >
                    <span class="h-h4 h-dangerous h-margin-right-s">{{
                      anId.summeryValue !== null ? anId.unit : ""
                    }}</span>
                  </div>
                  <div
                    v-else-if="
                      anId.title === 'Pulse Rate' && anId.summeryValue < 60
                    "
                  >
                    <span
                      :class="{
                        'h-secondary_shade_1':
                          anId.summeryValue === null || loading,
                      }"
                      class="h-h3 h-dangerous h-margin-right-xs"
                      >{{ summeryValue }}
                    </span>
                    <span class="h-h4 h-dangerous h-margin-right-s">{{
                      anId.summeryValue !== null ? anId.unit : ""
                    }}</span>
                  </div>
                  <div
                    v-else-if="
                      anId.title === 'Pain Score' && anId.summeryValue > 3
                    "
                  >
                    <span
                      :class="{
                        'h-secondary_shade_1':
                          anId.summeryValue === null || loading,
                      }"
                      class="h-h3 h-dangerous h-margin-right-xs"
                      >{{ summeryValue }}</span
                    >
                    <span class="h-h4 h-dangerous h-margin-right-s">{{
                      anId.summeryValue !== null ? anId.unit : ""
                    }}</span>
                  </div>
                  <div v-else>
                    <div
                      :class="{
                        'h-secondary_shade_1':
                          anId.summeryValue === null || loading,
                      }"
                    >
                      <span
                        :class="{
                          'h-secondary_shade_1':
                            anId.summeryValue === null || loading,
                        }"
                        class="h-h3 h-accent h-margin-right-xs"
                        >{{ summeryValue }}</span
                      >
                      <span
                        :class="{
                          'h-secondary_shade_1':
                            anId.summeryValue === null || loading,
                        }"
                        class="h-h4 h-accent h-margin-right-s"
                        >{{ anId.summeryValue !== null ? anId.unit : "" }}</span
                      >
                    </div>
                  </div>

                  <div
                    data-tooltip="The factor of change between the last and
                     first value over the past 30 days."
                    class="h-primary_shade_1 h-bold h-h5 h-align-end"
                    v-if="anId.trending !== null && !loading"
                  >
                    <i
                      :class="{
                        'fas fa-long-arrow-alt-up': anId.trending > 0,
                        'fas fa-long-arrow-alt-down': anId.trending < 0,
                      }"
                    ></i>
                    {{ formattedTrending }}%
                  </div>

                  <div
                    data-tooltip="Not enough data to calculate trending"
                    class="h-secondary_shade_1"
                    v-if="anId.trending === null && !loading"
                  >
                    <i class="fas fa-arrows-alt-v"></i>
                    ?
                  </div>
                </div>
              </div>
            </div>
            <div
              class="h-item-dialog-topbar-right h-d-flex h-align-items-center align-items-end"
            >
              <input
                v-if="!isUndefined"
                type="search"
                placeholder="Search"
                class="h-input h-item-search"
                v-model="textSearch"
                @input="handleInput()"
              />
              <button
                type="button"
                class="close h-margin-left-l"
                aria-hidden="true"
                @click="close"
                :disabled="loading"
              >
                <img
                  src="../../../../../../public/assets/images/Collapse-drawer.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-body>
        <div class="h-monitoring h-margin-top-l">
          <div v-if="!loading && !isUndefined">
            <div>
              <div
                class="h-d-flex flex-1 h-padding-left-l h-padding-right-l h-padding-bottom-l"
              >
                <div class="h-col-desktop-2 h-col-mobile-2 h-border-right-0">
                  <label class="h-label">New entry</label>
                  <InputField type="date" v-model="newEntryDate" />
                </div>
                <div
                  class="h-col-desktop-2 h-col-mobile-2 h-border-left-0 h-border-right-0"
                >
                  <InputField
                    type="time"
                    v-model="newEntryTime"
                    class="h-margin-top-l"
                  />
                </div>
                <div
                  class="h-col-desktop-2 h-col-mobile-2 h-border-left-0 h-border-right-0"
                >
                  <input
                    :type="inputType"
                    :step="inputStep"
                    :min="inputMin"
                    :max="inputMax"
                    placeholder="New value"
                    v-model="newEntryValue"
                    class="h-input h-margin-top-l"
                    @input="validateInputValue"
                  />
                </div>
                <div class="h-col-desktop-5 h-col-mobile-5 h-border-left-0">
                  <textarea
                    type="text"
                    placeholder="Comment"
                    v-model="newEntryComment"
                    class="h-input textarea-indrawer h-margin-top-l h-border-radius-left-0"
                    style="resize: none"
                  />
                </div>
                <button
                  type="button"
                  class="h-btn h-btn-accent h-margin-top-l h-margin-left-l"
                  @click="addNewEntry"
                >
                  Add
                </button>
              </div>
              <div
                class="
                    h-d-flex
                    flex-1
                    h-padding-left-l
                    h-padding-right-l
                    h-space-between
                    h-align-item-center
                "
                ></div>
            </div>

            <div
              class="h1-table h-padding-right-l h-padding-left-l h-table-footer-padding"
            >
              <div class="h-text-center"></div>
              <table
                class="table dataTable h-table-d-inline h-no-footer"
                ref="table"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      v-for="(header, index) of tableData.headers"
                      :key="header"
                      :class="{
                        'text-nowrap': index === 0,
                        'text-right':
                          (index === 0 || index === 1) &&
                          tableData.headers.length > 1,

                        'w-100':
                          tableData.headers.length <= 3 &&
                          (tableData.headers.length <= 2 || index === 2),
                        'w-25': tableData.headers.length > 3,
                      }"
                    >
                      <span class="table-title-bold">{{ header }}</span>
                    </th>
                    <th class="w-auto">
                      <span class="table-title-bold">Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody v-if="tableData.rows.length">
                  <tr v-for="(row, index) of tableData.rows" :key="index">
                    <td
                      v-for="(value, index) of row.row"
                      :key="index"
                      class="text-nowrap"
                      :class="{
                        'text-nowrap': index === 0,
                        'text-right':
                          (index === 0 || index === 1) &&
                          tableData.headers.length > 1,
                        'no-weight-space': index === 2,
                      }"
                      v-html="combinedValue(index, value)"
                    ></td>

                    <td class="h-text-left">
                      <a href="#">
                        <i
                          class="fas fa-edit text-info font-16 mr-2"
                          data-tooltip="Edit"
                          @click="(e) => editValue(e, row)"
                        >
                        </i
                      ></a>
                      <a href="#">
                        <i
                          v-if="canDelete"
                          class="fas fa-trash-alt text-danger font-16 mr-2 cursor-pointer"
                          data-tooltip="Delete"
                          @click="(e) => deleteValue(e, row)"
                        ></i
                      ></a>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr>
                    <td colspan="1000" class="h-secondary_shade_1 text-center">
                      No data
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="isUndefined" class="h-text-center">
            <h4 class="h-h4 h-secondary_shade_1">This Item is Turned off</h4>
          </div>
          <Loader v-if="loading" />
        </div>
      </template>
    </SlottedMessageInDrawer>

    <EditRecordDialog ref="editRecordDialog" @shouldReload="reload()" />
    <DeleteRecordDialog ref="deleteRecordDialog" @shouldReload="reload()" />
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import EditRecordDialog from './EditRecordDialog';
import DeleteRecordDialog from './DeleteRecordDialog';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      itemID: null,
      newEntryDate: null,
      newEntryTime: '14:00',
      newEntryValue: null,
      newEntryComment: null,
      loading: true,
      tableData: null,
      dataTable: null,
      canDelete: true,
      textSearch: '',
      checklistItemsAlias: [
        {
          'Dashboard title': 'Physical activity',
          'Database title': 'Time spent on physical activity',
        },
      ],
      anId: {},
      isUndefined: false,
      timeout: null,
    };
  },
  computed: {
    formattedTrending() {
      if (typeof this.anId.trending === 'number') {
        return this.anId.trending.toFixed(1);
      }
      return null;
    },
    svgClass() {
      if (this.anId && this.anId.summeryValue !== null) {
        if (
          this.anId.title !== 'SpO2' &&
          this.anId.title !== 'Pulse Rate' &&
          this.anId.title !== 'Pain Score'
        ) {
          return 'h-monitoring-svg h-monitoring-svg-accent';
        }
        if (this.anId.title === 'SpO2' && this.anId.summeryValue < 93) {
          return 'h-monitoring-svg h-monitoring-svg-red';
        }
        if (this.anId.title === 'Pulse Rate' && this.anId.summeryValue < 60) {
          return 'h-monitoring-svg h-monitoring-svg-red';
        }
        if (this.anId.title === 'Pain Score' && this.anId.summeryValue > 3) {
          return 'h-monitoring-svg h-monitoring-svg-red';
        }
        return 'h-monitoring-svg h-monitoring-svg-accent';
      }
      if (this.anId.summeryValue === null) {
        return 'h-monitoring-svg h-monitoring-svg-gray';
      }
      return '';
    },
    showSvg() {
      return this.anId && this.anId.summeryValue !== null;
    },
    summeryValue() {
      if (this.loading) return 'Loading';
      if (this.anId && typeof this.anId.summeryValue === 'number') {
        return this.anId.summeryValue.toFixed(1);
      }
      return '--';
    },

    inputType() {
      return this.anId.title === 'Quality of sleep' ? 'number' : 'text';
    },
    inputStep() {
      return this.anId.title === 'Quality of sleep' ? '1' : 'any';
    },
    inputMin() {
      return this.anId.title === 'Quality of sleep' ? '1' : '0';
    },
    inputMax() {
      return this.anId.title === 'Quality of sleep' ? '5' : '';
    },
  },
  methods: {
    async reload() {
      await this.show(this.itemID);
    },
    combinedValue(index, value) {
      if (index === 2) {
        return this.processValue(value);
      }
      if (this.anId.title === 'Quality of sleep' && index === 1) {
        return this.translateSleepQuality(value);
      }
      return value !== null ? value : '-';
    },
    validateInputValue() {
      const painScore = parseInt(this.newEntryValue, 10);
      switch (this.anId.title) {
        case 'Quality of sleep':
          if (
            this.newEntryValue < 1 ||
            this.newEntryValue > 5 ||
            !Number.isInteger(parseFloat(this.newEntryValue))
          ) {
            this.newEntryValue = '';
            $.notify(
              'Please enter a valid value between 1 to 5 for Quality of sleep',
              {
                position: 'top center',
                className: 'error',
              },
            );
          }
          break;

        case 'Pain Score':
          if (painScore < 1 || painScore > 10 || isNaN(painScore)) {
            this.newEntryValue = '';
            $.notify(
              'Please enter a valid value between 1 and 10 for Pain Score',
              {
                position: 'top center',
                className: 'error',
              },
            );
          }
          break;
        default:
          if (
            !isFinite(this.newEntryValue) ||
            parseFloat(this.newEntryValue) < 0
          ) {
            this.newEntryValue = '';
            $.notify('Please enter a valid value', {
              position: 'top center',
              className: 'error',
            });
          }
      }
    },

    translateSleepQuality(value) {
      if (this.anId.title === 'Quality of sleep' && typeof value === 'number') {
        switch (value) {
          case 1:
            return 'Terrible';
          case 2:
            return 'Poor';
          case 3:
            return 'Fair';
          case 4:
            return 'Good';
          case 5:
            return 'Excellent';
          default:
            return value; // Return the original value if it doesn't match any of the cases
        }
      } else {
        return value; // Return the original value if it's not "Quality of sleep"
      }
    },

    processValue(value) {
      if (!value) return '-';

      const strValue = value.toString();

      if (
        strValue.startsWith('from-mobile') ||
        strValue.startsWith('[from-mobile')
      ) {
        // Replace both "from-mobile" and "[from-mobile" with an empty string
        value = strValue.replace(/from-mobile|\[from-mobile/g, '').trim();

        // Extract contents inside square brackets and wrap them with a span
        const re = /\[(.*?)\]/g;
        const newValue = value.replace(
          re,
          (_match, p1) => `<span class="noColor">${p1}</span>`,
        );

        return `<span class="comment">${newValue}</span>`;
      }

      return value; // If not starting with "from mobile" or "[from mobile", don't process
    },
    async addNewEntry() {
      if (!this.newEntryDate || !this.newEntryTime || !this.newEntryValue) {
        $.notify('Please input date, time and value for new entry', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      const itemId = parseInt(this.anId.id.split('::')[1], 10);
      try {
        await this.$api.addCheckListItemReadingFromDashboard({
          data: 1,
          clientId: this.client.id,
          client: this.client,
          checkListItemId: itemId,
          itemInfo: this.anId,
          newEntryDate: this.newEntryDate,
          newEntryTime: this.newEntryTime,
          newEntryValue: this.newEntryValue,
          newEntryComment: this.newEntryComment,
        });
        $.notify('New entry added successfully.', {
          position: 'top center',
          className: 'success',
        });

        // Fetch the updated data
        this.loading = true;
        await this.gettingData(itemId);
        // Reset the form fields
        this.newEntryDate = null;
        this.newEntryTime = '14:40';
        this.newEntryValue = null;
        this.newEntryComment = null;
      } catch (e) {
        $.notify('Failed to add new entry.', {
          position: 'top center',
          className: 'error',
        });
      }
      this.loading = false;
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
    /* eslint-disable camelcase */

    async show(current_id) {
      this.itemID = current_id;

      this.$refs.slottedMessage.show();
      this.loading = true;
      this.gettingData(current_id);
    },

    async gettingData(current_id) {
      const { data } = await this.$api.getRecordData({
        client_id: this.client.id,
        date_start: this.dateStart,
        date_end: this.dateEnd,
      });

      this.allIds = data.allIds;
      const response = this.allIds.find(
        i => i.id === `Wellness::${current_id}`,
      );
      if (response === undefined) {
        this.isUndefined = true;
        this.loading = false;
        return;
      }
      this.isUndefined = false;
      this.anId = {
        ...response,
        icon: `/assets/images/progress/${response.title}.svg`,
      };

      let tableResponse;

      if (this.anId.id !== 'HFCS::current') {
        this.canDelete = true;
        const id = parseInt(this.anId.id.split('::')[1], 10);
        tableResponse = await this.$api.post(
          `${settings.BACKEND_URL}/api/v-nurse/checklist/get-table-records/${id}`,
          null,
          {
            dateStart: null,
            dateEnd: null,
          },
        );
      } else {
        this.canDelete = false;
        tableResponse = await this.$api.post(
          `${settings.BACKEND_URL}/api/v-nurse/hfi/get-table-records/${this.client.id}`,
          null,
          {
            dateStart: null,
            dateEnd: null,
          },
        );
      }

      this.tableData = tableResponse.data;

      const newResponse = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/checklist/get-records`,
        {
          client_id: this.client.id,
          date_start: this.dateStart,
          date_end: this.dateEnd,
        },
      );

      let checklistItemAlias = '';
      this.checklistItemsAlias.forEach((alias) => {
        if (this.anId.title === alias['Dashboard title']) {
          checklistItemAlias = alias['Database title'];
        }
      });

      const newAllIdsData = newResponse.data.allIds;
      const newAnIdData = newAllIdsData.filter((element) => {
        return (
          element.title === this.anId.title ||
          element.title === checklistItemAlias
        );
      });
      this.anId.summeryValue = newAnIdData[0].summeryValue;

      this.loading = false;
      if (this.dataTable) {
        this.dataTable.destroy(true);
        this.dataTable = null;
      }
      if (this.tableData.rows.length) {
        this.timeout = setTimeout(() => {
          this.dataTable = $(this.$refs.table).DataTable({
            pageLength: 50,
            sDom: 'tpr',
            order: [0, 'desc'],
            lengthMenu: [50, 100, 200, 500, 1000, 5000],
            dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
          });
        }, 0);
      }
    },
    /* eslint-enable camelcase */

    close() {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
    },
    editValue(e, row) {
      this.$refs.editRecordDialog.show(e, row, this.dataTable, this.anId);
    },
    deleteValue(e, row) {
      this.$refs.deleteRecordDialog.show(e, row, this.dataTable);
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
    if (this.timeout) clearTimeout(this.timeout);
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
    EditRecordDialog,
    DeleteRecordDialog,
    InputField,
  },

  props: ['dateStart', 'dateEnd', 'client'],
};
</script>
