<template>
  <SlottedMessageInDrawer ref="slottedMessage" title="Edit activity" :closeDisabled="loading">
    <template v-slot:modal-body>
      <div v-if="!loading && data">
        <table class="h-notification notInDrawer" style="overflow: visible; display: table">
          <tbody>
            <tr>
              <th>Patient name:</th>
              <td>{{ data['Patient Name'] }}</td>
            </tr>
            <tr>
              <th>Topic:</th>
              <td>{{ data['Topic'] }}</td>
            </tr>
            <tr>
              <th>Date:</th>
              <td>{{ data['Date'] | formatDate }}</td>
            </tr>
          </tbody>
        </table>
        <div class="h-margin-top-l">
          <label class="h-label">Alert status</label>
          <InputField
            type="select"
            class="h-select-in-th"
            :choices="[
              ['', 'None'],
              ['active', 'Open'],
              ['resolved', 'Resolved'],
              ['wont_fix', 'Ignore']
            ]"
            v-model="data['Alert status']"
          />
        </div>
        <div class="h-margin-top-l">
          <label class="h-label">Intervention</label>
          <InputField type="textarea" v-model="data['Intervention']" />
        </div>
        <div class="h-margin-top-l">
          <label class="h-label">Patient action</label>
          <InputField type="textarea" v-model="data['Patient action']" />
        </div>
        <div class="h-margin-top-l h-margin-bottom-l">
          <label class="h-label">Nurse action</label>
          <InputField type="textarea" v-model="data['Nurse action']" />
        </div>
      </div>

      <Loader v-if="loading" />
    </template>

    <template v-slot:modal-footer>
      <div class="row">
        <button type="button" class="h-btn h-btn-accent" @click="confirm" :disabled="loading">
          Confirm
        </button>

        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Close
        </button>
      </div>
    </template>
  </SlottedMessageInDrawer>
</template>

<style lang="stylus" scoped>
table {
  th {
    padding-right: 5rem;
    text-align: left;
  }
}
</style>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import eventBus from '../../../../../../event-bus';

export default {
  props: [],

  data() {
    return {
      data: null,
      loading: false,
    };
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY h:mm a');
    },
  },
  methods: {
    async show(data) {
      this.loading = false;
      this.data = JSON.parse(JSON.stringify(data));
      const payload = {
        id: this.data.metadata.id,
        alert_type: this.data.metadata.entryType,
      };
      const clientAlertData = await this.$api.getClientAlert(payload);
      if (clientAlertData && clientAlertData.status === 'ok') {
        this.data = { ...this.data, ...clientAlertData.alert };
      }
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },

    async confirm() {
      this.loading = true;
      const payload = {
        id: this.data.metadata.id,
        alert_type: this.data.metadata.entryType,
        alert_status: this.data['Alert status'],
        intervention: this.data.Intervention,
        patient_action: this.data['Patient action'],
        nurse_action: this.data['Nurse action'],
      };
      await this.$api.updateClientAlert(payload);
      this.close(true);
      this.$emit('updated');
      eventBus.$emit('alertStatusModified');
    },
  },

  components: {
    SlottedMessageInDrawer,
    Loader,
    InputField,
  },
};
</script>
